// VueABTest.js
import Vue from 'vue'
import Cookies from 'js-cookie'

const COOKIE_TEST_PREFIX = '_bungalow_test_'
const TEST_EXCLUSION_VALUE = 'excluded_from_test'
const EXPERIMENT_VIEWED_EVENT_NAME = 'Experiment Viewed'
const DEFAULT_TEST_PERCENTAGE = 100
const DEFAULT_TEST_EXPIRY = 365
const DEFAULT_POSSIBLE_VARIANTS = [true, false]
// Enabled active tests
const ACTIVE_TESTS = []
/**
 * Welcome to the in house ab testing plugin,
 * To use, simply call $getTestVariant with the test key.
 *
 * First add your experimentId to the ACTIVE_TESTS array. Without this you will get an error
 * that it must be added.
 *
 * You can customize:
 * - possible variants
 * - test traffic inclusion
 * - test expiry
 *
 * For testing, you can easily hardcode an option to be returned by specifying a
 * testValue ex. $getTestVariant('testingSomething', undefined, undefined, true)
 */
const ABTesting = {
  install(Vue) {
    // Add a new method to Vue prototype
    Vue.prototype.$getTestVariant = function (
      experimentId,
      possibleVariants = DEFAULT_POSSIBLE_VARIANTS,
      testPercentage = DEFAULT_TEST_PERCENTAGE,
      testValue,
      testExpiry = DEFAULT_TEST_EXPIRY
    ) {
      try {
        // Validate the experimentId
        if (!experimentId) {
          throw new Error('experimentId is required')
        }
        if (experimentId.includes(' ')) {
          throw new Error('experimentId must not include spaces, replace with _')
        }
        if (!ACTIVE_TESTS.includes(experimentId)) {
          throw new Error('experimentId must be added to ACTIVE_TESTS array for tracking')
        }
        if (!Array.isArray(possibleVariants) || possibleVariants.length === 0) {
          throw new Error('possibleVariants must be a non-empty array')
        }
        // Validate testPercentage
        if (typeof testPercentage !== 'number' || testPercentage < 0 || testPercentage > 100) {
          throw new Error('testPercentage must be a number between 0 and 100')
        }
        // enable using query parameters to trigger test variant
        const queryExperimentValue = this.$route.query[experimentId]
        if (queryExperimentValue) {
          let parsedQueryValue
          try {
            parsedQueryValue = JSON.parse(queryExperimentValue)
          } catch {
            parsedQueryValue = queryExperimentValue // Fallback to raw value if parsing fails
          }
          if (possibleVariants.includes(parsedQueryValue)) {
            return parsedQueryValue
          }
        }
        // Return a fixed value for the variationName if provided
        if (![undefined, null].includes(testValue)) {
          return testValue
        }
        // Check if the environment variable for cookie domain is set
        const domain = process.env.VUE_APP_COOKIE_DOMAIN
        if ([undefined, null].includes(domain)) {
          throw new Error('VUE_APP_COOKIE_DOMAIN is not defined')
        }
        // Retrieve the variant from a cookie
        let variationName = Cookies.get(`${COOKIE_TEST_PREFIX}${experimentId}`, {
          domain,
        })
        // Try to parse the variant value as JSON
        try {
          variationName = JSON.parse(variationName)
        } catch {
          // Ignore if the value wasn't JSON
        }
        // If no variant was previously set, generate one
        if ([undefined, null].includes(variationName)) {
          // Include the visitor in the experiment based on the random number
          if (Math.random() < testPercentage / 100.0) {
            variationName = possibleVariants[Math.floor(Math.random() * possibleVariants.length)]
            Cookies.set(`${COOKIE_TEST_PREFIX}${experimentId}`, JSON.stringify(variationName), {
              domain,
              expires: testExpiry,
            })
            // Track the user viewing the test
            if (this.$segment && typeof this.$segment.track === 'function') {
              this.$segment.track(EXPERIMENT_VIEWED_EVENT_NAME, {
                experimentId,
                variationName,
              })
            }
          } else {
            // Exclude the visitor from the experiment
            variationName = TEST_EXCLUSION_VALUE
            Cookies.set(`${COOKIE_TEST_PREFIX}${experimentId}`, JSON.stringify(variationName), {
              domain,
              expires: testExpiry,
            })
            return false
          }
        } else if (variationName === TEST_EXCLUSION_VALUE) {
          // Exclude the visitor from the experiment if the cookie was previously set to the exclusion value
          return false
        }
        // Return the variant
        return variationName
      } catch (e) {
        // eslint-disable-next-line
        if (process.env.VUE_APP_TARGET !== 'production') console.log(e)
        try {
          this.$sentry.captureException(e)
        } catch {}
        // if something fails hard, return false
        return false
      }
    }
  },
}

// Install the ABTesting plugin
Vue.use(ABTesting)
