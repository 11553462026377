<template>
  <div class="grid-enabled large-modal">
    <div class="modal-close-button" @click="$root.$emit('closeModal')">
      <inline-svg :src="require(`@/assets/icons/fnd/close.svg`)" />
    </div>

    <div class="modalHeader">
      <h1 class="header-lg mb-4 text-center font-black">Bungalow Support</h1>
    </div>

    <div class="modalContent mx-auto w-100">
      <iframe
        src="https://bungalowliving.formstack.com/forms/bungalow_login_help"
        title="Bungalow Renewal Form"
        id="login-help-formstack-frame"
        :class="{ formSubmitted }"
        width="100%"
        height="1150px"
        frameborder="0"
      />
    </div>
    <div v-if="formSubmitted" class="modalFooter mt-3">
      <b-btn variant="dark" @click="$root.$emit('closeModal')" block>Close</b-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LoginIssueHelpModal',
  data() {
    return {
      formSubmitted: false,
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  mounted() {
    window.addEventListener('message', this.formstackSubmissionHandler)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.formstackSubmissionHandler)
  },
  methods: {
    formstackSubmissionHandler(event) {
      try {
        if (event?.origin !== 'https://bungalowliving.formstack.com') return
        this.formSubmitted = event.data === 'fs-form-submit'
        if (this.isMobile) {
          // scroll modal to the top
          document.querySelector('.modalContainer').scrollTop = 0
        }
      } catch {}
    },
  },
}
</script>
<style lang="scss" scoped>
#login-help-formstack-frame {
  height: 1400px;
  @include mobile {
    height: 1550px;
  }
  &.formSubmitted {
    @include tabletAndUp {
      height: 400px;
    }
  }
}
</style>
