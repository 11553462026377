import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  issue: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  issue: ({ issue }) => issue,
}

const actions = {
  fetchIssue({ commit }, issueId) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(apiPaths.maintenance.issue(issueId))
      .then(({ data }) => commit('setIssue', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  resetIssue({ commit }) {
    commit('resetState')
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setIssue(state, payload) {
    state.issue = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
