export default [
  {
    name: 'articles',
    path: '/articles',
    component: () => import(/* webpackChunkName: "articles" */ './containers/ArticleContainer.vue'),
    children: [
      {
        name: 'article',
        path: ':articleSlug',
        component: () => import(/* webpackChunkName: "articles" */ './ArticleContent.vue'),
      },
    ],
  },
]
