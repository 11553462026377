const happinessGuaranteeRedirectRoutes = [
  '/30-day-trial-terms',
  '/happiness-guarantee-terms',
  '/2-week-trial-terms',
]

export default [
  {
    path: '/resident',
    redirect: '/resident/billing',
  },
  {
    path: '/help',
    redirect: { name: 'supportClassificationTree' },
  },
  {
    path: '/terms-of-use',
    redirect: '/terms-of-service',
  },
  {
    path: '/why-bungalow',
    redirect: '/renters-homes',
  },
  {
    path: '/renters',
    redirect: '/renters-homes',
  },
  {
    path: '/welcome-home',
    redirect: '/my-tours-login',
  },
  // Find A Home –> Market Selector
  {
    path: '/find-a-home*',
    redirect: (from) => {
      const query = Object.assign({}, from.query)
      query['find-a-home'] = true
      return {
        path: '/',
        query: query,
      }
    },
  },
  // Batch redirects
  ...happinessGuaranteeRedirectRoutes.map((path) => ({
    path,
    redirect: '/love-where-you-live-trial-terms',
  })),
  // Rent Reports
  {
    path: '/q1-2022-US-rooms-rent-report',
    beforeEnter() {
      return (window.location.href = `https://downloads.bungalow.com/Bungalow+Q1+2022+U.S.+Room+Rent+Report.pdf`)
    },
  },
  {
    path: '/q2-2022-US-rooms-rent-report',
    beforeEnter() {
      return (window.location.href = `https://downloads.bungalow.com/Bungalow+Q2+2022+U.S.+Room+Rent+Report.pdf`)
    },
  },
  // Marketing Programs - TODO: Confirm removal of these
  {
    path: '/mail',
    beforeEnter() {
      return (window.location.href = `https://bungalow.com/homeowners?lob=true`)
    },
  },
  {
    path: '/home',
    beforeEnter() {
      return (window.location.href = `https://bungalow.com/homeowners?lob=true?utm_campaign=postcard`)
    },
  },
  {
    path: '/owners',
    beforeEnter() {
      return (window.location.href = `https://bungalow.com/homeowners?lob=true?utm_campaign=property-management`)
    },
  },
  {
    path: '/home100',
    beforeEnter() {
      return (window.location.href = `https://bungalow.com/homeowners?lob=true?utm_campaign=postcard-2a`)
    },
  },
  {
    path: '/homepm',
    beforeEnter() {
      return (window.location.href = `https://bungalow.com/homeowners?lob=true?utm_campaign=postcard-2b`)
    },
  },
]
