/**
 * Controler vuex state for the navigation ( market slug & homme type )
 *
 * The values we have are dynamically stored to local storage, allowing us to retain
 * the users target market with ease
 */

const LOCAL_STORAGE_MARKET_SLUG_KEY = 'nav_market_slug'
const LOCAL_STORAGE_HOME_TYPE_KEY = 'nav_home_type'
import { homeTypes } from '@/constants/nav.js'

const getDefaultState = () => ({
  homeType: localStorage.getItem(LOCAL_STORAGE_HOME_TYPE_KEY),
  marketSlug: localStorage.getItem(LOCAL_STORAGE_MARKET_SLUG_KEY),
})

const state = getDefaultState()

const getters = {
  homeType: ({ homeType }) => homeType,
  homeTypeDisplayName: ({ homeType }) => homeTypes.find(({ key }) => key === homeType)?.title,
  marketSlug: ({ marketSlug }) => marketSlug,
  marketDisplayName: ({ marketSlug }, getters, rootState, rootGetters) =>
    rootGetters['markets/markets'].find(({ slug }) => slug === marketSlug)?.displayName,
}

const actions = {
  saveHomeType({ commit }, homeType) {
    commit('setHomeType', homeType)
  },
  saveMarketSlug({ commit }, marketSlug) {
    commit('setMarketSlug', marketSlug)
  },
  clearHomeType({ commit }) {
    commit('resetState')
  },
}

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  clearHomeType: (state) => {
    state.homeType = null
  },
  setHomeType(state, payload) {
    state.homeType = payload
    localStorage.setItem(LOCAL_STORAGE_HOME_TYPE_KEY, payload)
  },
  setMarketSlug(state, payload) {
    state.marketSlug = payload
    localStorage.setItem(LOCAL_STORAGE_MARKET_SLUG_KEY, payload)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
