import { startCase } from 'lodash-es'
import { MARKET_SWATCHES } from '@/constants/markets'
/**
 *
 * @param {Object} market
 * @returns market
 *
 * This function converts the camel case object keys in marketInfo
 * and neighborhoods `info` field to start case
 *
 * Our http package by default converts all object keys to camel case
 * so this for now does what we need
 */
export const annotateMarketDetails = (market) => {
  const newMarketInfo = {}
  for (let [key, value] of Object.entries(market.marketInfo)) {
    newMarketInfo[startCase(key)] = value
  }
  const newNeighborhoods = market.neighborhoods.map((neighborhood) => {
    if (neighborhood.info) {
      const newNeighborhoodInfo = {}
      for (let [key, value] of Object.entries(neighborhood.info)) {
        newNeighborhoodInfo[startCase(key)] = value
      }
      neighborhood.info = newNeighborhoodInfo
    }
    return neighborhood
  })
  market.marketInfo = newMarketInfo
  market.neighborhoods = newNeighborhoods
  return market
}

export const annotizeMarket = (market = {}) => {
  const s3BucketUrl = process.env.VUE_APP_S3_STATIC_ASSETS_URL
  const marketSwatch = MARKET_SWATCHES[market.slug] || {}

  return {
    ...market,
    ...marketSwatch,
    selectCityImage: `${s3BucketUrl}markets/images/${market.slug}_portrait.jpg`,
    bgImage: `${s3BucketUrl}markets/images/${market.slug}_landscape.jpg`,
    icon: {
      sm: `${s3BucketUrl}markets/icons/${market.slug}_sm.svg`,
      lg: `${s3BucketUrl}markets/icons/${market.slug}_lg.svg`,
      xl: `${s3BucketUrl}markets/icons/${market.slug}_xl.svg`,
    },
  }
}

export const getRandomMarket = (markets) => {
  return markets[Math.floor(Math.random() * markets.length)].displayName
}
