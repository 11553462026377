var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[(_vm.layout === 'list' || _vm.showImage)?_c('div',{staticClass:"home-type-image mr-3 d-flex justify-content-center align-items-center",style:({
      width: '60px',
      height: '60px',
      borderRadius: '8px',
      backgroundColor: '#F6F2ED',
      border: '1px solid #E6E6E6',
      ...(_vm.image
        ? {
            backgroundImage: `url(${_vm.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }
        : null),
    })},[(!_vm.image)?_c('inline-svg',{attrs:{"src":require('@/assets/icons/fnd/place.svg')}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"text-left"},[_c('div',{staticClass:"paragraph-2 font-weight-bold",domProps:{"textContent":_vm._s(_vm.title)}}),_c('span',{staticClass:"paragraph-2 font-weight-normal charcoal-40--txt",domProps:{"textContent":_vm._s(_vm.subtitle)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }