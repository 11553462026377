import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  review: [],
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  review: ({ review }) => review,
}

const actions = {
  createResponse({ commit, state }, payload) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .post(apiPaths.reviews.responses, payload)
      .then(({ data }) => commit('setReview', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setReview(state, payload) {
    state.review = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
