<template>
  <div id="app" :class="[routeClasses, { 'hide-nav': hideNav, 'hide-footer': hideFooter }]">
    <!-- Navigation -->
    <div :class="{ 'd-none': hideNav }">
      <site-header-mobile />
      <site-header />
    </div>

    <!-- Route View -->
    <div :class="['viewport', { 'hide-footer': $route.meta.hideFooter }]">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>

    <!-- Footer -->
    <site-footer key="footer" v-if="!hideFooter" />

    <!-- Global Elements -->
    <modal />
    <chat-widgets v-if="activeUser" />
    <notifications />
    <mirage-client-server v-if="activeUserIsStaff" />
  </div>
</template>

<script>
import pkg from '../package.json'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { setUTMCookies, trackGoogleClicks } from '@/utils'
import { unRegisterServiceWorkers } from '@/utils/misc.js'
import Modal from '@/components/Shared/Modal.vue'
import Notifications from '@/components/Shared/Notifications.vue'
import SiteHeader from '@/components/Header/HeaderDesktop.vue'
import SiteHeaderMobile from '@/components/Header/HeaderMobile.vue'
import SiteFooter from '@/components/Layout/Footer.vue'

const ChatWidgets = () =>
  import(/* webpackChunkName: "user-property-chats" */ '@/components/Chats/ChatWidget.vue')
const MirageClientServer = () =>
  import(
    /* webpackChunkName: "mirage-client-server" */ '@/components/Shared/MirageClientServer.vue'
  )

export default {
  name: 'App',
  components: {
    Notifications,
    ChatWidgets,
    SiteHeader,
    SiteHeaderMobile,
    SiteFooter,
    Modal,
    MirageClientServer,
  },
  metaInfo() {
    return {
      htmlAttrs: {
        lang: 'en',
      },
      title: 'Bungalow | Best Room & Home Rentals Experience',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            'Beautiful homes, incredible locations, and pricing that makes sense. We make renting seamless––from finding the perfect place, to making it a home.',
        },
        {
          property: 'version',
          content: pkg.version,
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          name: 'og:type',
          content: 'website',
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          name: 'og:title',
          content: 'Bungalow | Best Room & Home Rentals Experience',
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          name: 'og:description',
          content:
            'Beautiful homes, incredible locations, and pricing that makes sense. We make renting seamless––from finding the perfect place, to making it a home.',
        },
        {
          vmid: 'og:site_name',
          property: 'og:site_name',
          name: 'og:site_name',
          content: 'Bungalow',
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          name: 'og:url',
          content: this.currentUrl,
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          name: 'og:image',
          content:
            'https://assets.bungalow.com/home-page/header/header-hero-min.png?h=630&w=1200&auto=format',
        },
        {
          vmid: 'fb:app_id',
          property: 'fb:app_id',
          name: 'fb:app_id',
          content: process.env.VUE_APP_FACEBOOK_APP_ID,
        },
      ],
    }
  },
  data() {
    return {
      currentUrl: '',
    }
  },
  beforeCreate() {
    unRegisterServiceWorkers()
  },
  created() {
    this.fetchMarkets()
    this.fetchFeatureFlags()
  },
  mounted() {
    // Global DOM Listeners:
    this.setupGlobalEventListeners()

    // Update URL for meta/SEO:
    this.updateCurrentUrl()

    // Set UTM params as cookies for later reference:
    setUTMCookies()

    // Track Google click(s)
    trackGoogleClicks()
  },
  destroyed() {
    this.removeGlobalEventListeners()
  },
  watch: {
    $route: {
      handler(goingTo = {}, comingFrom = {}) {
        this.updateCurrentUrl()
      },
      immediate: true,
    },
    activeUser: {
      // clear chats and chat user if user manually logs out of the app
      handler(val, oldVal) {
        if (!val && oldVal) {
          this.resetChatUser()
          this.resetChats()
        }
      },
    },
    fetchMarketsError(error) {
      if (error) this.showOfflineMessage()
    },
    isLoggedIn(isLoggedIn) {
      if (!isLoggedIn && this.routeRequiresAuth)
        this.$router.push({ path: '/', query: this.$route.query }).catch(() => {})
    },
  },
  computed: {
    ...mapGetters(['isTablet']),
    ...mapGetters('user', ['activeUser', 'isLoggedIn', 'activeUserIsStaff']),
    ...mapGetters('markets', ['fetchMarketsError']),
    routeRequiresAuth() {
      return this.$route.matched.some((route) => route.meta.requiresAuth)
    },
    hideNav() {
      // Guard against pre-computed state(s):
      if (!this.$route.name) return true

      return !!(this.$route.meta.hideNav || this.$route.query.is_app)
    },
    hideFooter() {
      return (
        this.$route.query.is_app ||
        this.$route.name === 'Listings' ||
        this.$route.meta.hideFooter ||
        this.$route.query.preview_mode
      )
    },
    routeClasses() {
      return this.$route.meta.routeClasses
    },
  },
  methods: {
    ...mapActions(['setBreakpoints', 'setScrolled']),
    ...mapActions('markets', ['fetchMarkets']),
    ...mapActions('flags', ['fetchFeatureFlags']),
    ...mapMutations({
      resetChatUser: 'chatUser/resetState',
      resetChats: 'chats/resetState',
    }),
    setupGlobalEventListeners() {
      // Scroll watcher:
      this.setScrolled()
      window.addEventListener('scroll', this.setScrolled)
      // Responsive breakpoints:
      this.setBreakpoints()
      window.addEventListener('resize', this.setBreakpoints)
    },
    removeGlobalEventListeners() {
      window.removeEventListener('scroll', this.setScrolled)
      window.removeEventListener('resize', this.setBreakpoints)
    },
    showOfflineMessage() {
      this.$root.$emit('showNotification', {
        type: 'primary',
        text: 'The server appears to be offline, please refresh or try again in a few minutes',
        duration: 10000,
      })
    },
    updateCurrentUrl() {
      this.currentUrl = window.location.href
    },
  },
}
</script>

<style lang="scss">
// Global CSS imports (order matters for hierarchy)
@import '@/styles/main.scss';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap-vue/dist/bootstrap-vue.css';

#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // NOTE: Don't add this CSS for headless browsers:
  // Ensures the footer is hidden while site is loading:
  > div.viewport {
    min-height: calc(100vh);

    &.hide-footer {
      min-height: calc(100vh - #{$default-navbar-height}) !important;
    }
  }

  // Provides buffer for navbar
  padding-top: $default-navbar-height;

  @include tablet {
    padding-top: $mobile-navbar-height;
  }

  @include mobile {
    > div.viewport {
      min-height: calc(100vh) !important;
    }
    padding-top: $mobile-navbar-height;
  }
  // Fills the page during route transitions:
  .viewport {
    flex: 1;
  }
  &.hide-nav {
    padding-top: 0;
  }
  #site-header-mobile-container {
    display: none !important;
  }
  @include tabletAndDown {
    .site-header-container {
      display: none !important;
    }
    #site-header-mobile-container {
      display: block !important;
    }
  }
}
</style>
