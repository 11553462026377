export const AMENITY_ICONS = {
  Dishwasher: 'dishwasher.svg',
  AC: 'ac.svg',
  Washer: 'laundry.svg',
  Dryer: 'dryer.svg',
  Backyard: 'outdoors.svg',
  Patio: 'outdoors.svg',
  Pool: 'pool.svg',
  'Common spaces furnished': 'sofa.svg',
  'Monthly cleaning service': 'cleaning.svg',
  '55" smart TV': 'tv.svg',
  'Community events': 'events.svg',
  'High speed Wi-Fi': 'wifi.svg',
  'Pet Friendly': 'pets.svg',
  'Furnishing options available': 'sofa.svg',
  'Bedrooms furnished': 'bed.svg',
  'Cleaning options available': 'cleaning.svg',
}
