<template>
  <div class="d-flex align-items-center">
    <div
      v-if="layout === 'list' || showImage"
      class="home-type-image mr-3 d-flex justify-content-center align-items-center"
      :style="{
        width: '60px',
        height: '60px',
        borderRadius: '8px',
        backgroundColor: '#F6F2ED',
        border: '1px solid #E6E6E6',
        ...(image
          ? {
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }
          : null),
      }"
    >
      <inline-svg v-if="!image" :src="require('@/assets/icons/fnd/place.svg')" />
    </div>
    <div class="text-left">
      <div class="paragraph-2 font-weight-bold" v-text="title" />
      <span class="paragraph-2 font-weight-normal charcoal-40--txt" v-text="subtitle" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarketTypeLayout',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    showImage: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: '',
    },
    layout: {
      type: String,
      default: 'list',
      validator: (type) => {
        return type === 'list' || type === 'grid'
      },
    },
  },
}
</script>
