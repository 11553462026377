<template>
  <span class="friendly-date">
    <span class="date-string" :id="uniqueID" ref="dateSpan">{{ dateStringFriendly }}</span>
    <b-tooltip
      :target="targetElement"
      :title="dateStringRegular"
      placement="top"
      delay="500"
      class="date-tooltip"
      style="background: white"
    >
    </b-tooltip>
  </span>
</template>

<script>
import moment from 'moment'

export default {
  name: 'friendly-date',
  props: ['date'],
  methods: {
    friendlyDate: function (value) {
      if (value === null) {
        return 'today'
      }
      // Get some friendly integers
      var difDay = Math.round(moment().diff(value, 'days', true) * -1)
      var difWeek = Math.round(moment().diff(value, 'weeks', true) * -1)
      var difMonth = Math.round(moment().diff(value, 'months', true) * -1)

      // Set up thresholds
      var maxToday = 0
      var maxTomorrow = 1
      var maxDay = 14
      var maxWeek = 5
      var maxNextMonth = 1

      // Strings, Prefix and Suffixes
      var sToday = 'today'
      var sTomorrow = 'tomorrow'
      var sDaySuffix = 'days'
      var sWeekSuffix = 'weeks'
      var sNextMonth = '1 month'
      var sMonthSuffix = 'months'
      var sPrefix = 'in'

      var nbsp = ' ' // unicode no-break space, &nbsp;

      if (difDay <= maxToday) {
        return sToday
      } else if (maxTomorrow === difDay) {
        return sTomorrow
      } else if (maxDay > difDay) {
        return sPrefix + nbsp + difDay + nbsp + sDaySuffix
      } else if (maxWeek > difWeek) {
        return sPrefix + nbsp + difWeek + nbsp + sWeekSuffix
      } else if (difMonth === maxNextMonth) {
        return sPrefix + nbsp + sNextMonth
      } else if (difMonth > maxNextMonth) {
        return sPrefix + nbsp + difMonth + nbsp + sMonthSuffix
      } else {
        return 'sometime soon' // Something is wrong
      }
    },
    regularDate(value) {
      return moment(value).format('LL')
    },
  },
  created() {
    // Make our friendly strings
    this.dateStringFriendly = this.friendlyDate(this.date)
    this.dateStringRegular = this.regularDate(this.date)

    // Generate Unique ID for tooltip
    var now = moment().format('unix')
    var random = Math.floor(Math.random() * 1000)
    this.uniqueID = 'id' + now + random
    this.targetElement = this.uniqueID
  },
  data() {
    return {
      dateStringFriendly: '',
      dateStringRegular: '',
      uniqueID: 'id-12345',
      targetElement: this.uniqueID,
    }
  },
}
</script>

<style lang="scss"></style>
