import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  classifications: [],
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  classifications: ({ classifications }) => classifications,
}

const actions = {
  fetchClassifications({ commit }, query) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(apiPaths.support.classifications(query))
      .then(({ data }) => commit('setClassifications', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setClassifications(state, payload) {
    state.classifications = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
