import {
  addHours,
  addMinutes,
  addSeconds,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  formatDistance,
  formatDistanceToNow,
  parseISO,
} from 'date-fns'

export const getTimeBetweenDates = (endDate) => {
  const endsAt = typeof endDate === 'string' ? parseISO(endDate) : endDate
  let timeDiff = new Date()
  // Calculate hours remaining, add them to the duration
  const hours = Math.max(0, differenceInHours(endsAt, timeDiff))
  timeDiff = addHours(timeDiff, hours)
  // Now calculate the remainder of minutes left
  const minutes = Math.max(0, differenceInMinutes(endsAt, timeDiff))
  timeDiff = addMinutes(timeDiff, minutes)
  // For the final minute, also calculate seconds left
  const seconds = Math.max(0, differenceInSeconds(endsAt, timeDiff))
  timeDiff = addSeconds(timeDiff, seconds)
  // Flag if time has expired
  const hasExpired = hours === 0 && minutes === 0 && seconds === 0
  // formatted time remaining
  const formattedTimeRemaining = hasExpired
    ? null
    : `${hours ? `${hours}hr${hours !== 1 ? 's' : ''}` : ''} ${
        minutes ? `${minutes}min${minutes !== 1 ? 's' : ''}` : ''
      }`
  // Save computed time left into store
  const timeLeft = { hours, minutes, seconds, hasExpired, formattedTimeRemaining }
  return timeLeft
}

export const formatDateToMMMdyyyy = (dateStr) => format(parseISO(dateStr), 'MMM d, yyyy')

export const formatDateToMMMd = (dateStr) => format(parseISO(dateStr), 'MMM d')

export const formatDateToEEEELLLd = (dateStr) => format(parseISO(dateStr), 'EEEE, LLL d')

/**
 * Format a date string as yyyy-MM-dd
 * @param {string} dateStr
 * @returns string
 */
export const formatDateToyyyyMMdd = (dateStr) => format(parseISO(dateStr), 'yyyy-MM-dd')

export const formatDateasMMMdoyyyy = (dateStr) => format(parseISO(dateStr), 'MMM do yyyy')

export const formatDateasMMMdo = (dateStr) => format(parseISO(dateStr), 'MMM do')

export const timeAgo = (dateStr) =>
  formatDistance(parseISO(dateStr), new Date(), {
    addSuffix: true,
    includeSeconds: true,
  })

export const timeUntil = (dateStr) =>
  formatDistanceToNow(parseISO(dateStr), {
    includeSeconds: true,
  })

export const getFormattedDateFromStrDate = (dateStr, dateFormat = 'yyyy-MM-dd') => {
  // JS dates can be one day off depending on string format, using slashes helps:
  try {
    // replaces '-' with '/'
    const dateStringWithSlashes = dateStr?.replace(/-/g, '/').replace(/T.+/, '')
    // Update the value before sending back.
    const dateObj = new Date(dateStringWithSlashes);
    return format(dateObj, dateFormat);
  } catch (dateParsingError) {
    console.error('Date Field Error:', dateParsingError)
    return null
  }
}
