import Vue from 'vue'
import { marketSelectorLocations } from '@/constants/nav.js'
import MarketSelectionModal from '@/components/Modals/MarketSelect/MarketSelectionModal.vue'

const globalMethods = {
  methods: {
    $showHomeFinder() {
      document.documentElement.clientWidth > 600
        ? this.$root.$emit('showMarketSelect', { location: marketSelectorLocations.HEADER })
        : this.$root.$emit('openModal', {
            modalComponent: MarketSelectionModal,
            fullscreen: true,
          })
    },
  },
}

const Website = {
  install(vm) {
    // Globally install mixin(s):
    vm.mixin(globalMethods)
  },
}

Vue.use(Website)
