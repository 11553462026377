import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

// @TODO REMOVE HARDCODED TRUE BEFORE MERGE
const getDefaultState = () => ({
  ...stateHelpers,
  mirageConfig: {
    active: false,
    userConfig: {
      isResident: false,
      isHomeowner: false,
    },
    chatConfig: {
      enabled: false,
      includeArchivedChat: false,
    },
    funnelConfig: {
      enabled: false,
      mockPropertyType: null,
    },
    resident: {
      // initial seed data config
      balance: {
        total_prepaid: '0.00',
        total_charges_owed: '0.00',
        total_charges_owed_immediately: '0.00',
      },
      paymentMethods: [],
      statementLines: [],
      futureCharges: [],
      productSettings: {
        auto_pay_enabled: false,
      },
      invitation: null,
      useCreditCard: false,
      throwPaymentError: false,
      fullOccupancy: [],
    },
    homeowner: {
      // initial seed data config
      identity: {
        is_verified: false,
        requirements: [],
      },
      payoutMethods: [],
      statementLines: [],
    },
  },
})

const initialState = getDefaultState()

const getters = {
  ...getterHelpers,
  mirageConfig: ({ mirageConfig }) => mirageConfig,
  mirageActive: ({ mirageConfig: { active } }) => active,
  mirageChatActive: ({
    mirageConfig: {
      active,
      chatConfig: { enabled: chatEnabled },
    },
  }) => active && chatEnabled,
}

const actions = {
  updateMirageConfig: ({ commit }, payload) => {
    commit('setMirageConfig', payload)
  },
  resetMirage: ({ commit }) => {
    commit('resetState')
  },
}

const mutations = {
  ...mutationHelpers,
  setMirageConfig(state, updatedConfig) {
    state.mirageConfig = updatedConfig
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

export { getDefaultState as generateInitialMirageState }

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}
