<template>
  <div
    v-if="marketsFetched"
    :class="['d-flex', 'align-items-center', 'justify-content-center', 'market-selector-combobox']"
    :data-cy="`${location}-market-selector`"
  >
    <Combobox
      id="market-selector-combobox"
      v-model="marketValue"
      name="markets"
      iconName="search"
      ref="marketsCombobox"
      valueKey="displayName"
      label="Markets Selector"
      :class="['market-selector-combobox__markets mr-3', inputClasses]"
      :options="markets"
      popupType="grid"
      :visibleOptionsRows="5"
      :selectedValue="marketDisplayName"
      :emptyOptionLabel="marketTypeLabel"
      :selectOptionHandler="selectMarketHandler"
      @onToggle="(isOpen) => handleToggleEvent('market', isOpen)"
      @update:parent="isMarketsOpen = $event"
      @onChange="(value) => (marketValue = value)"
      :data-cy="`${location}__market-select-input`"
    >
      <template #prepend-option-item="{ layout }">
        <li
          class="option d-flex align-items-center px-2 py-2 w-100 align-items-center"
          @click="autoSelectMarketBasedOffLocation"
          role="option"
        >
          <MarketTypeLayout title="Near me" :layout="layout" showImage />
        </li>
      </template>
      <template #combobox-options-layout="{ option, layout }">
        <MarketTypeLayout
          :title="option.displayName"
          :subtitle="option.regionCode"
          :layout="layout"
          :image="option.imgMd"
          showImage
        />
      </template>
    </Combobox>
    <Combobox
      readOnly
      valueKey="title"
      name="home-types"
      ref="homeTypesCombobox"
      iconName="chevron-down"
      label="Home Type Selector"
      :class="['market-selector-combobox mr-3', inputClasses]"
      style="width: 200px"
      emptyOptionLabel="What type of home?"
      @onToggle="(isOpen) => handleToggleEvent('home-types', isOpen)"
      :selectedValue="homeTypeDisplayName"
      :selectOptionHandler="selectHomeTypeHandler"
      :options="homeTypes"
      :data-cy="`${location}__home-type-input`"
    >
      <template #combobox-options-layout="{ option }">
        <HomeTypeLayout :homeType="option" />
      </template>
    </Combobox>
    <div :style="{ flex: '1 0 0' }" class="market-selector-search-btn">
      <slot name="market-selector-search-btn" v-bind="{ viewFilteredListing }"> </slot>
    </div>
  </div>
</template>

<script>
import { delay } from '@/utils'
import { homeTypes } from '@/constants/nav.js'
import { getRandomMarket } from '@/utils/markets.js'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Combobox from '@/components/Shared/Combobox/Combobox.vue'
import HomeTypeLayout from './HomeTypeLayout.vue'
import MarketTypeLayout from './MarketTypeLayout.vue'
import GeoLocationService from '@/services/geolocationService'

export default {
  name: 'MarketSelector',
  components: {
    Combobox,
    HomeTypeLayout,
    MarketTypeLayout,
  },
  props: {
    location: {
      type: String,
      required: true,
    },
    inputClasses: {
      type: String,
    },
  },
  data() {
    return {
      isMarketsOpen: false,
      marketValue: '',
      homeTypes,
    }
  },
  watch: {
    marketsFetched: {
      handler(v) {
        if (v) {
          this.marketValue = this.marketDisplayName
          this.homeTypeValue = this.homeTypeDisplayName
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$root.$on('showMarketSelect', this.handleShowMarketSelect)
  },
  beforeDestroy() {
    this.$root.$off('showMarketSelect', this.handleShowMarketSelect)
  },
  computed: {
    ...mapGetters('markets', ['markets', 'marketsFetched']),
    ...mapGetters('nav', ['homeTypeDisplayName', 'marketDisplayName']),
    ...mapGetters({
      selectedHomeType: 'nav/homeType',
      selectedMarketSlug: 'nav/marketSlug',
    }),
    marketTypeLabel() {
      return this.isMarketsOpen ? `Try "${getRandomMarket(this.markets)}"` : 'Where are you moving?'
    },
  },
  methods: {
    ...mapActions('nav', ['saveHomeType', 'saveMarketSlug']),
    ...mapMutations({
      clearHomeType: 'nav/clearHomeType',
    }),
    handleToggleEvent(optionType, isOpen) {
      try {
        if (optionType === 'market') {
          this.isMarketsOpen = isOpen
          if (isOpen) {
            this.$segment.track('Market Selector Activated', {
              location: this.location,
              option: optionType,
              market: this.marketDisplayName,
              propertyType: this.homeTypeDisplayName,
              page: this.$route.name,
            })
            // ensure home types are hidden when markets are being selected
            this.$refs.homeTypesCombobox.isExpanded = false
          }
        } else if (optionType === 'home-types') {
          // ensure markets are hidden when home types are being selected
          if (isOpen) this.$refs.marketsCombobox.isExpanded = false
        }
      } catch (e) {}
    },
    async autoSelectMarketBasedOffLocation() {
      try {
        this.$segment.track('Auto select location triggered', {
          location: this.location,
          page: this.$route.name,
        })
        const { latitude, longitude } = await GeoLocationService.get(true)
        const [{ displayName, slug: marketSlug }] = GeoLocationService.sortObjectsByNearestLatLong(
          this.markets,
          latitude,
          longitude
        )
        this.marketValue = displayName
        this.saveMarketSlug(marketSlug)
        this.$refs.marketsCombobox.displayValue = displayName
        document.activeElement?.blur?.()
        this.$refs?.marketsCombobox?.hideOptions()
        this.$refs?.homeTypesCombobox?.toggleOptions()
        this.$segment.track('Auto select location success', {
          location: this.location,
          market: displayName,
          page: this.$route.name,
        })
      } catch (e) {
        this.$root.$emit('showNotification', {
          type: 'error',
          title: `Woops, we were unable to find your nearest market.`,
          duration: 5000,
        })
        this.$segment.track('Auto select location failure', {
          location: this.location,
          page: this.$route.name,
          error: e.message,
        })
      }
    },
    async selectMarketHandler(selectedMarket, filterText) {
      try {
        const marketSlug = this.markets.find((market) => market.displayName === selectedMarket).slug

        this.saveMarketSlug(marketSlug)

        this.$segment.track('Market Option Selected', {
          location: this.location,
          market: this.marketDisplayName,
          propertyType: this.homeTypeDisplayName,
          page: this.$route.name,
          filter: filterText,
        })
      } catch (e) {}
    },
    async selectHomeTypeHandler(homeType) {
      let selectedHomeTypeKey = homeType.key
        ? homeType.key
        : homeTypes.find((home) => home.title === homeType).key

      this.saveHomeType(selectedHomeTypeKey)

      this.$segment.track('Property Type Selected', {
        location: this.location,
        market: this.marketDisplayName,
        propertyType: this.homeTypeDisplayName,
        page: this.$route.name,
      })
    },
    async handleShowMarketSelect(options = {}) {
      // Update home type to whatever was passed (if anything)
      if ('homeType' in options) {
        if (options.homeType === undefined) this.clearHomeType()
        else this.selectHomeTypeHandler({ key: options.homeType })
      }

      // If we already have both options, try to launch to MLP
      // TODO: Figure out scenario where we may want to force market search
      // if (!options.force && this.marketDisplayName && this.homeTypeDisplayName)
      // return this.viewFilteredListing()

      // Show the market selection option:
      if (options.location === this.location) {
        await delay(50)
        this.$refs?.marketsCombobox?.toggleOptions()
      }
    },
    async handleShowHomeTypeSelect(options = {}) {
      if (options.location === this.location) {
        await delay(50)
        this.$refs?.homeTypesCombobox?.toggleOptions()
      }
    },

    async viewFilteredListing() {
      // Validation, check for input fields from leftmost
      if (!this.marketValue || this.marketValue !== this.marketDisplayName) {
        this.handleShowMarketSelect({ location: this.location })
      } else if (!this.homeTypeDisplayName) {
        this.handleShowHomeTypeSelect({ location: this.location })
      } else {
        // If market and home type exists navigate to the property page
        this.$segment.track('Market Search Initiated', {
          location: this.location,
          market: this.marketDisplayName,
          propertyType: this.homeTypeDisplayName,
          page: this.$route.name,
        })

        const offering = homeTypes.find(({ key }) => key === this.selectedHomeType).route

        await this.$router
          .push({
            name: 'Listings',
            params: { market: this.selectedMarketSlug, offering },
            query: this.$route.query,
          })
          .catch(() => {})
      }
    },
  },
}
</script>

<style lang="scss">
#market-selector-combobox {
  .options-list-wrapper {
    max-width: none;
    padding-right: 1.5rem !important;
    ul#markets-combobox-options-list {
      max-width: none;
      width: 700px;
      display: grid !important;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: minmax(auto, auto); /* adjust row height as needed */
      grid-gap: 10px; /* adjust gap size as needed */
      list-style: none;
      padding: 0 0 20px 0;
      margin: 0;
      @include mobile {
        grid-template-columns: repeat(1, 1fr);
      }
      li {
        height: 100%;
        border-radius: 8px;
        display: flex;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }
}
.market-selector-combobox {
  &__markets {
    input {
      min-width: 165px;
    }
  }
  .market-selector--search-cta {
    height: 48px;
    padding: 0 1.5rem;
    font-size: 16px;
    border-radius: 4px;
  }
}
</style>
