import { getField, updateField } from 'vuex-map-fields'
import apiPaths from '@/constants/apiPaths'
import { stateHelpers, getterHelpers, mutationHelpers } from '@/store/helpers'

const getDefaultState = () => ({
  ...stateHelpers,
  form: {
    // dynamic field stamped from url query parameter
    sfdc_lead_id: '',
    // Basic Info
    // these fields are stamped in the webflow pages,
    // use the values or default to empty string
    first_name: localStorage.getItem('first_name') || '',
    last_name: localStorage.getItem('last_name') || '',
    email: localStorage.getItem('email') || '',
    phone: localStorage.getItem('phone') || '',
    // Property Information
    property_address: '',
    unit: '',
    number_of_beds: '',
    number_of_baths: '',
    asking_rent_min: '',
    asking_rent_max: '',
    property_type: '',
    build_date_last_remodel: '',
    contact_role: '',
    // ~ Hidden fields start
    street_name: '',
    street_number: '',
    city: '',
    state: '',
    neighborhood: '',
    region: '',
    country: '',
    latitude: '',
    longitude: '',
    // ~ hidden fields end
    // Property Amenities
    has_washer_dryer: false,
    washer_dryer_location: '',
    has_dishwasher: false,
    has_microwave: false,
    has_ac: false,
    has_patio_balcony: false,
    has_backyard: false,
    backyard_details: '',
    has_pool: false,
    has_parking: false,
    num_parking_spaces: '',
    has_additional_space: false,
    // Property material and condition
    common_flooring_hardwood: false,
    bedroom_flooring_hardwood: false,
    kitchen_flooring_hardwood: false,

    common_flooring_carpet: false,
    bedroom_flooring_carpet: false,
    kitchen_flooring_carpet: false,

    common_flooring_linoleum: false,
    bedroom_flooring_linoleum: false,
    kitchen_flooring_linoleum: false,

    common_flooring_ceramic_porcelain_tile: false,
    bedroom_flooring_ceramic_porcelain_tile: false,
    kitchen_flooring_ceramic_porcelain_tile: false,

    common_flooring_other: false,
    bedroom_flooring_other: false,
    kitchen_flooring_other: false,

    general_flooring: '',
    kitchen_appliances: '',
    kitchen_countertops: '',
    // Property sizes
    overall_sqft: '',
    living_room_size: '',
    dining_room_size: '',
    // Bonus questions
    rent_control: '',
    has_hoa: '',
    under_construction: '',
    construction_end_date: '',
    has_tenants: '',
    tenant_move_out_date: '',
    listing_link: '',
    additional_details: '',
    'How did you hear about us': '',
  },
  // array of room detail keys
  // this is spread into the post payload upon submission
  roomDetails: [
    /**
     * bedroom_1_size
     * bedroom_1_amenity_tv
     * bedroom_1_amenity_bath
     * bedroom_1_amenity_closet
     * bedroom_1_amenity_balcony
     * bedroom_1_amenity_fireplace
     * Bedroom_1_amenity_private_entrance
     *
     */
  ],
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  getField,
  submitPayload: ({ form, roomDetails }) => {
    let payload = { ...form }
    // add all room keys to the payload
    roomDetails.forEach(
      (room) =>
        (payload = {
          ...payload,
          ...room,
        })
    )
    // convert all keys to strings
    Object.keys(payload).forEach((objectKey) => {
      payload[objectKey] = String(payload[objectKey])
    })
    return payload
  },
}

const actions = {
  async submitSupplyViewingForm({ commit, getters }) {
    commit('setLoading', true)
    commit('setError', false)
    try {
      // use fetch for initial launch since our http packages always adds the slash
      fetch(process.env.VUE_APP_INTERNAL_BACKEND_URL + apiPaths.supply.intakeCreate, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: 'vsvf',
          data: getters.submitPayload,
        }),
      })
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
}

const mutations = {
  ...mutationHelpers,
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
