import apiPaths from '@/constants/apiPaths.js'

const state = {
  questions: [],
  responses: [],
  questionsFetched: false,
  responsesFetched: false,
}

const getters = {
  questions: ({ questions }) => questions,
  responses: ({ responses }) => responses,
  questionsFetched: ({ questionsFetched }) => questionsFetched,
  responsesFetched: ({ responsesFetched }) => responsesFetched,
  preferencesFetched: ({ questionsFetched, responsesFetched }) =>
    !!(questionsFetched && responsesFetched),
}

const actions = {
  fetchPreferenceQuestions({ commit }) {
    return this._vm.$http
      .get(apiPaths.livingPreferences.questions)
      .then(({ data: { results } }) => {
        commit('setQuestions', results)
        commit('setQuestionsFetched', true)
      })
      .catch(this._vm.$sentry.captureException)
  },
  fetchPreferenceResponses({ commit }) {
    return this._vm.$http
      .get(apiPaths.livingPreferences.responses)
      .then(({ data: { results } }) => {
        commit('setResponses', results)
        commit('setResponsesFetched', true)
      })
      .catch(this._vm.$sentry.captureException)
  },
  updatePreferenceResponses(store, payload) {
    return this._vm.$http
      .post(apiPaths.livingPreferences.responses, payload)
      .catch(this._vm.$sentry.captureException)
  },
}

const mutations = {
  setQuestions: (state, payload) => (state.questions = payload),
  setResponses: (state, payload) => (state.responses = payload),
  setQuestionsFetched: (state, payload) => (state.questionsFetched = payload),
  setResponsesFetched: (state, payload) => (state.responsesFetched = payload),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
