<template>
  <div class="form-toggle">
    <div class="form-toggle__inner">
      <input
        ref="formToggleInput"
        :id="`toggle-${componentId}`"
        type="checkbox"
        name="toggle"
        class="switch"
        @change="onToggle"
        :bind="$attrs"
        :checked="$attrs.value"
      />
      <label :for="`toggle-${componentId}`" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormToggle',
  computed: {
    componentId() {
      return this._uid
    },
  },
  methods: {
    onToggle(event) {
      this.$emit('input', event.target.checked)
    },
  },
}
</script>

<style lang="scss">
.form-toggle {
  overflow: hidden;
  padding: 6px 2px 6px 16px;

  &__inner {
    display: block;
    height: 22px;
    width: 40px;
    padding-top: 2px;

    input:empty {
      margin-left: -9999px;
    }

    input:empty ~ label {
      position: relative;
      float: left;
      width: 150px;
      cursor: pointer;
      user-select: none;
    }

    input:empty ~ label:before,
    input:empty ~ label:after {
      position: absolute;
      display: block;
      content: ' ';
      transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    input:empty ~ label:before {
      top: 2px;
      left: 0px;
      width: 34px;
      height: 14px;
      border-radius: 12px;
      background-color: $charcoal-20;
    }

    input.switch:empty ~ label:after {
      top: -1px;
      left: -9px;
      width: 1.4em;
      height: 8px;
      bottom: 0.1em;
      margin-left: 0.1em;
      background-color: #fff;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: solid 2px;
      border-color: #fff;
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
        0 1px 8px 0 rgba(0, 0, 0, 0.12);
    }

    input:checked ~ label:before {
      background-color: $blue-40;
    }

    input:checked ~ label:after {
      left: 15px !important;
      background-color: $blue-60 !important;
      border-color: $blue-60 !important;
    }
  }
}
</style>
