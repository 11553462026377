import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  form: null,
  formSubmission: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  form: (state) => state.form,
  formSubmission: (state) => state.formSubmission,
}

const actions = {
  saveFormSubmission({ commit }, payload) {
    return this._vm.$http
      .post(apiPaths.forms.submission, payload)
      .then(({ data }) => commit('setFormSubmission', data))
      .catch((error) => this._vm.$sentry.captureException(error))
  },
  saveFormFile({ commit }, payload) {
    return this._vm.$http
      .post(apiPaths.forms.files, payload)
      .then(({ data }) => {
        commit('setFormFiles', data)
        return data
      })
      .catch((error) => this._vm.$sentry.captureException(error))
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setFormSubmission(state, data) {
    state.formSubmission = data
  },
  setFormFiles(state, data) {},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
