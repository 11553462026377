import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  chatUser: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  chatUser: ({ chatUser }) => chatUser,
}

const actions = {
  async fetchChatUser({ commit }) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const { data } = await this._vm.$http.get(apiPaths.chats.chatUser)
      commit('setChatUser', data)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setChatUser(state, payload) {
    state.chatUser = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
