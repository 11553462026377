import Vue from 'vue'

Vue.directive('intersection', {
  bind: function (element, binding, vnode = {}) {
    try {
      // if browser does not support IntersectionObserver
      // check to see if there's a callback function and fire exists
      if (!window.IntersectionObserver) {
        if (typeof binding.value === 'function') binding.value()
        return
      }

      const vm = vnode.context

      const io = new IntersectionObserver(([event], observer) => {
        if (event.isIntersecting) {
          // If a bound value is a function, call it when the event fires:
          if (typeof binding.value === 'function') binding.value()

          // Track the page element being viewed (if possible):
          if (vm && vm.$segment) vm.$segment.viewed(element.id)

          // Remove the observer:
          observer.unobserve(event.target)
        }
      })
      // Start observing an element
      io.observe(element)
    } catch (e) {
      console.error(e)
    }
  },
})

Vue.directive('visibilityChange', {
  bind: function (element, binding, vnode = {}) {
    try {
      // if browser does not support IntersectionObserver
      // check to see if there's a callback function and fire exists
      if (!window.IntersectionObserver || !!navigator.webdriver) {
        if (typeof binding.value === 'function') binding.value(true)
        return
      }
      const io = new IntersectionObserver(
        ([event], observer) => {
          if (typeof binding.value === 'function') binding.value(event.isIntersecting)
        },
        {
          rootMargin: '-137px 0px -137px 0px',
        }
      )
      // Start observing an element
      io.observe(element)
    } catch (e) {
      console.error(e)
    }
  },
})

Vue.directive('lazy-src', {
  bind(el, binding) {
    if (!window.IntersectionObserver || !!navigator.webdriver) return (el.src = binding.value)

    const io = new IntersectionObserver(
      ([event], observer) => {
        if (event.isIntersecting) {
          el.src = binding.value
          observer.unobserve(event.target)
        }
      },
      {
        rootMargin: '50px 0px 50px 0px',
      }
    )
    io.observe(el)
  },
})

Vue.directive('lazy-bg-src', {
  bind(el, binding) {
    if (!window.IntersectionObserver || !!navigator.webdriver)
      return (el.style.backgroundImage = `url(${binding.value})`)

    const io = new IntersectionObserver(
      ([event], observer) => {
        if (event.isIntersecting) {
          el.style.backgroundImage = `url(${binding.value})`
          observer.unobserve(event.target)
        }
      },
      {
        rootMargin: '50px 0px 50px 0px',
      }
    )
    io.observe(el)
  },
})

Vue.directive('limit', {
  bind(el, binding) {
    el.style.maxWidth = `${binding.value}px`
  },
})
