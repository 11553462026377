<template>
  <div id="header-badge" :class="{ 'has-icon': !isLoggedIn }">
    <div
      v-if="chatCount"
      :class="[
        'my-messages-badge pointer br-4 no-wrap ',
        isMobile ? 'mr-3 p-1' : 'mr-1 p-2',
        { 'unread-chats': unreadChats },
      ]"
      @click="showChatsOnClickHandler"
    >
      <img :src="require(`@/assets/icons/${unreadChats ? 'bell' : 'messages'}.svg`)" />
      <div v-if="unreadChats" class="unread-chat-message-indicator" />
    </div>
    <b-nav-item-dropdown right class="nav-avatar ml-1">
      <template slot="button-content">
        <div class="badge-head-html">
          <loading-spinner class="avatar-loader" v-if="loadingUser" />
          <img
            v-else-if="isLoggedIn"
            class="avatar"
            @error="useFallbackImage = true"
            :src="profileImageUrl"
            :alt="userFullName"
          />

          <inline-svg v-else :src="require('@/assets/icons/fnd/account.svg')" />
        </div>
      </template>

      <!-- Login -->
      <template v-if="!isLoggedIn">
        <b-dropdown-header>Account</b-dropdown-header>

        <b-dropdown-item :to="loginRoute">Login</b-dropdown-item>
      </template>

      <template v-if="isLoggedIn">
        <b-dropdown-header>Account</b-dropdown-header>

        <b-dropdown-item to="/profile">Profile</b-dropdown-item>
        <b-dropdown-item v-if="showToursOption" to="/my-tours">Tours</b-dropdown-item>
        <b-dropdown-item :disabled="!chatCount" @click="showChatsOnClickHandler">
          <span :class="!chatCount && 'charcoal-30--txt'"> Chats </span>
        </b-dropdown-item>
        <b-dropdown-item :disabled="!applications.length" @click="goToApplications">
          <span :class="!applications.length && 'charcoal-30--txt'"> Applications </span>
        </b-dropdown-item>
      </template>

      <!-- Staff -->
      <template v-if="activeUserIsStaff">
        <b-dropdown-divider />

        <b-dropdown-header>Staff</b-dropdown-header>

        <b-dropdown-item @click="openMirageConfigModalHandler"> UI Testing </b-dropdown-item>

        <b-dropdown-item @click="clearCache"> Clear cache </b-dropdown-item>
      </template>

      <!-- Applicants -->

      <!-- Residents -->
      <template v-if="activeUserIsResident">
        <b-dropdown-divider />

        <b-dropdown-header>Residents</b-dropdown-header>

        <b-dropdown-item to="/resident/billing"> Payments and Billing </b-dropdown-item>

        <b-dropdown-item to="/resident/billing/documents"> Lease and Documents </b-dropdown-item>

        <b-dropdown-item to="/resident/support"> Support and Maintenance </b-dropdown-item>

        <b-dropdown-item to="/resident/insurance"> Renters Insurance </b-dropdown-item>

        <b-dropdown-item v-if="isTransferEligible" to="/transfer">
          Transfer Request
        </b-dropdown-item>
      </template>

      <!-- Homeowners -->
      <template v-if="activeUserIsHomeowner">
        <b-dropdown-divider />

        <b-dropdown-header>Homeowners</b-dropdown-header>

        <b-dropdown-item to="/homeowner">Contact Us</b-dropdown-item>
      </template>

      <!-- Logout -->
      <template v-if="isLoggedIn">
        <b-dropdown-divider />

        <b-dropdown-item @click="logout">Logout</b-dropdown-item>
      </template>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { clearLocalStorageData } from '@/utils'
import { AUTOMATED_TOURS } from '@/constants/featureFlags'
import MirageEntitySelectionModal from '../Modals/Mirage/MirageEntitySelectionModal.vue'
import MirageIntroModal from '../Modals/Mirage/MirageIntroModal.vue'

export default {
  name: 'header-badge',
  data() {
    return {
      useFallbackImage: false,
      fallbackImage: 'https://assets.bungalow.com/avatars/pineapple-avatar-2.png',
    }
  },
  mounted() {
    this.fetchResidentOccupancy()
  },
  computed: {
    ...mapGetters(['isMobile']),
    ...mapGetters('flags', ['featureFlags']),
    ...mapGetters('applications', ['applications']),
    ...mapGetters('resident', ['residentOccupancy']),
    ...mapGetters('user', [
      'loadingUser',
      'isLoggedIn',
      'activeUser',
      'activeUserIsHomeowner',
      'activeUserIsResident',
      'activeUserIsStaff',
      'userProfileImage',
    ]),
    ...mapGetters('chats', ['chatCount', 'unreadChats']),
    showToursOption() {
      return this.featureFlags.includes(AUTOMATED_TOURS)
    },
    loginRoute() {
      const { fullPath } = this.$route
      return {
        name: 'Login',
        query: {
          next: fullPath,
          from: fullPath,
        },
      }
    },
    profileImageUrl() {
      return this.useFallbackImage ? this.fallbackImage : this.userProfileImage
    },
    userFullName() {
      const firstName = this.activeUser.firstName || ''
      const lastName = this.activeUser.lastName || ''

      if (!firstName && !lastName) return 'Profile'

      return `${firstName} ${lastName}`
    },
    isTransferEligible() {
      return this.residentOccupancy?.isAllowedTransfer
    },
  },
  methods: {
    ...mapActions('user', ['logout']),
    ...mapActions('resident', ['fetchResidentOccupancy']),
    clearCache() {
      clearLocalStorageData()

      window.location.reload()

      this.$root.$emit('showNotification', {
        type: 'primary',
        title: 'Cache cleared:',
        text: 'Your local storage data has been cleared!',
      })
    },
    showChatsOnClickHandler() {
      this.$root.$emit('showChats')
    },
    goToApplications() {
      window.location = `${process.env.VUE_APP_APPLY_URL}applications/`
    },
    openMirageConfigModalHandler() {
      this.$segment.track('Opened Mirage Server Config')

      if (localStorage.getItem('mirageIntroShown')) {
        this.$root.$emit('openModal', { modalComponent: MirageEntitySelectionModal })
      } else {
        localStorage.setItem('mirageIntroShown', true)
        this.$root.$emit('openModal', { modalComponent: MirageIntroModal })
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#header-badge {
  display: flex;
  align-items: center;

  .my-messages-badge {
    position: relative;

    &:focus,
    &:hover {
      background-color: $charcoal-5;
    }

    img {
      height: 24px;
      width: 24px;
    }
    &.unread-chats {
      img {
        height: 20px;
        width: 20px;
      }
    }
    .unread-chat-message-indicator {
      position: absolute;
      width: 8px;
      height: 8px;
      background: #ff6f4f;
      border-radius: 100%;
      top: 10px;
      right: 8px;
      @include mobile {
        top: 6px;
        right: 8px;
      }
    }
  }

  .nav-avatar {
    padding-left: 0 !important;
    .nav-link {
      display: grid;
      place-items: center;
      padding: 0;
      height: 48px;
      width: 48px;
      max-width: 48px;

      @include tabletAndDown {
        margin-right: 0.5rem;
      }
    }
  }

  &.has-icon {
    .dropdown-toggle {
      transition: background 0.25s ease;
      border-radius: 100%;
      &:hover {
        background: $charcoal-5;
      }

      &:active {
        background: $charcoal-10;
      }
    }
  }

  .dropdown-menu {
    @include card;
    margin-top: 0.5rem;

    @include mobile {
      width: 100vw;
      margin-top: 7px;
      border: 0;
    }

    .dropdown-item {
      color: $charcoal-60;
      &.active {
        background: white;
      }
    }
  }

  .badge-head-html {
    .avatar-loader,
    .avatar-loader .spinner-border {
      width: 44px;
      height: 44px;
      @include mobile {
        width: 40px;
        height: 40px;
      }
      .spinner-border {
        border-width: 2px;
      }
    }
    .avatar {
      width: 44px;
      height: 44px;
      object-fit: cover;
      border: 2px solid white;
      border-radius: 100%;
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));

      @include mobile {
        width: 40px;
        height: 40px;
      }
    }

    svg {
      color: $charcoal-60;
      zoom: 1.25;
    }
  }
}
</style>
