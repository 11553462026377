<template>
  <div id="funnel-2-how-it-works-modal" class="grid-enabled small-modal">
    <p class="modalHeader header-md text-center pb-3">Fresh Updates Available! 🎉</p>
    <section class="modalContent pt-2">
      <p class="font-weight-bold mb-1 text-center">
        Good news! We've been busy improving your experience and have just released some updates. 🚀
      </p>
    </section>
    <footer class="modalFooter mt-4">
      <b-button block @click.once="refresh" variant="gradient" class="reset-max-width">
        <LoadingSpinnerVue v-if="showLoader" white small />
        <b v-else>Refresh Now</b>
      </b-button>
    </footer>
  </div>
</template>

<script>
import LoadingSpinnerVue from '../../Shared/LoadingSpinner.vue'

export default {
  name: 'WebpackChunkErrorModal',
  components: {
    LoadingSpinnerVue,
  },
  data() {
    return {
      showLoader: false,
    }
  },
  mounted() {
    this.$segment.track('Viewed modal', {
      type: 'webpack_chunk_error',
    })
  },
  methods: {
    refresh() {
      this.showLoader = true
      this.$segment.track('CTA Clicked', {
        CTA: 'Reload',
        type: 'webpack_chunk_error',
      })
      setTimeout(() => {
        window.location.reload()
      }, 500)
    },
  },
}
</script>
<style lang="scss"></style>
