import { getDaysInMonth, getDate, format, getMonth, getYear } from 'date-fns'

export const getGroupLivingRentCreditExpiry = () => {
  const _today = new Date()
  // Retrieve all relevant information about todays date
  const lastDayOfMonth = getDaysInMonth(_today)
  const currentDateOfMonth = getDate(_today)
  const currentMonth = getMonth(_today)
  const currentYear = getYear(_today)
  // if the current date is less than the 15, the promo ends on the 15th
  // if it is currently the 15th or past that date, we return the last day of the month
  const promoEndDayOfMonth = currentDateOfMonth < 15 ? 15 : lastDayOfMonth
  // construct the full date re-using the current year, month and calculated day
  const endDate = new Date(currentYear, currentMonth, promoEndDayOfMonth)
  // format - Jun 15th / Jun 31st
  return format(endDate, 'MMM do')
}

// https://support.matterport.com/s/article/URL-Parameters
export const matterportAutoPlayTourParams = [
  '&play=1', // autoplay
  '&vr=0', // hide VR button
  '&ts=.5', // secs until guided tour
].join('')

// https://support.matterport.com/s/article/URL-Parameters
export const matterportQueryParams = [
  '&play=1', // autoplay
  '&vr=0', // hide VR button
  '&ts=-1', // secs until guided tour (-1 disables)
].join('')

// https://support.matterport.com/s/article/URL-Parameters
export const matterportPreviewParams = [
  matterportQueryParams, // include these and...
  '&brand=0', // remove branding
  '&mls=2', // remove MLS features
  '&qs=1', // disables doll-house fly-in (instantly ready)
  '&mt=0', // disable mattertags
  '&nozoom=1', // don't zoom in
  '&pin=0', // hide 360 views (dollhouse)
  '&portal=0', // hide 360 views (inside)
  '&hl=2', // collapse (hide) highlight reel
  '&hr=0', // no highlight reel in showcase
  '&dh=0', // hide dollhouse
  '&gt=0', // hide guided tour button(s)
  '&f=0', // disable floor switching
  '&fp', // no floorplan button
  '&wh=0', // disable scroll wheel
  '&help=0', // hide help
  '&search=0', // hide magnifying glass
].join('')

export const PROMO_BANNER = {
  co_living: {
    title: 'Lock in incredible savings on rent',
    description: `Our prices are the lowest they’ve been!`,
  },
  group_living: {
    title: 'Lock in incredible savings on rent',
    description: `Our prices are the lowest they’ve been!`,
  },
}

export const DEFAULT_INSTANT_LEASE_SUMMARY =
  'Any Verified Bungalow Member is eligible to move into this home.'

export const DEFAULT_HOUSE_PROFILE_SUMMARY =
  'Get to know the roommates in this home when you kick off a chat with them.'

export const DEFAULT_HOUSE_PROFILE = {
  esaDog: true,
  esaCat: true,
  minAge: null,
  maxAge: null,
  description: DEFAULT_HOUSE_PROFILE_SUMMARY,
  genderPreferences: [],
  tags: [],
}

export const PRONOUN_MAP = {
  he: ['male'],
  she: ['female'],
  they: ['non-binary'],
  he_they: ['male', 'non-binary'],
  she_they: ['female', 'non-binary'],
  other: ['non-binary'],
}

export const HOUSE_MATCH_KEY = '_isMatch'

export const DEFAULT_GROUPS_OCCUPANCY = 2
