<template>
  <div class="d-flex align-items-center justify-content-between" style="height: 92px">
    <div class="d-flex align-items-center">
      <img
        alt=""
        :src="homeType.img"
        class="home-type-image mr-3"
        :style="{
          width: '82px',
          height: '82px',
          borderRadius: '100%',
        }"
      />
      <div class="flex-grow-1" v-limit="215">
        <div v-text="homeType.title" class="paragraph-2 font-weight-bold" />
        <span class="paragraph-2 font-weight-normal charcoal-40--txt" v-text="homeType.subtitle" />
      </div>
    </div>
    <div v-if="isMobile">
      <inline-svg :src="require('@/assets/icons/fnd/arrow-right.svg')" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HomeTypeLayout',
  props: {
    homeType: {
      type: Object,
      validator: (value) => {
        const { title, subtitle, img } = value
        return typeof title === 'string' && typeof subtitle === 'string' && typeof img === 'string'
      },
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>
