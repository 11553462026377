import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  issueCommentRead: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  issueCommentRead: ({ issueCommentRead }) => issueCommentRead,
}

const actions = {
  async createIssueCommentRead({ commit }, payload) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const { data } = await this._vm.$http.post(apiPaths.maintenance.issueCommentsRead, payload)
      return data
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setIssueCommentRead(state, payload) {
    state.issueCommentRead = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
