import { sortBy } from 'lodash-es'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'

import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  application: null,
  applications: [],
  roomSelections: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  application: ({ application }) => application,
  applications: ({ applications }) => applications,
  currentApplicationUrl: ({ application, applications }) => {
    if (application) {
      return `${process.env.VUE_APP_APPLY_URL}application/${application.source}`
    } else if (applications.length === 1) {
      return `${process.env.VUE_APP_APPLY_URL}application/${applications[0].source}`
    }
    return null
  },
  roomSelections: ({ roomSelections: { results } }) => results,
  getPropertyRoomSelection:
    ({ roomSelections: { results } }) =>
    (propertyId) =>
      results.find(({ roomInfo: { property } }) => String(property) === String(propertyId)),
  getPropertyDemandLeasesEndDate:
    ({ application }) =>
    (propertyId) => {
      return application?.availableProperties?.find(({ property }) => property.id === propertyId)
        ?.property?.demandLeasesEndDate
    },
  getPropertyAvailableRoomsWithoutLocks:
    ({ application }) =>
    (propertyId) => {
      const selectedProperty = application?.availableProperties?.find(
        ({ property }) => property.id === propertyId
      )
      return selectedProperty
        ? selectedProperty.property.rooms.filter(
            ({ isAvailable, hasActiveRoomSelection }) => isAvailable && !hasActiveRoomSelection
          )
        : []
    },
  getPropertyAvailableRooms:
    ({ application }) =>
    (propertyId) => {
      const selectedProperty = application?.availableProperties?.find(
        ({ property }) => property.id === propertyId
      )
      return selectedProperty
        ? sortBy(
            selectedProperty.property.rooms.filter(({ isAvailable }) => isAvailable),
            'hasActiveRoomSelection',
            'desc'
          )
        : []
    },
  getApplicationPropertyMainImage:
    ({ application }) =>
    (propertyId) =>
      application?.availableProperties?.find(({ property }) => property.id === propertyId)?.property
        .images?.[0]?.mdUrl || 'https://assets.bungalow.com/images/property-placeholder.jpg',
  getApplicationPropertyMarketSlug:
    ({ application }) =>
    (propertyId) =>
      application?.availableProperties?.find(({ property }) => property.id === propertyId)?.property
        .marketSlug,
}

const actions = {
  fetchFunnelApplication({ commit }) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(apiPaths.applications.list)
      .then(({ data }) => {
        commit(
          'setApplications',
          // still render all funnel 2.0 application
          data?.results
            .filter(({ status }) => status !== 'rejected')
            .filter(({ displayState: { isFunnel20 } }) => isFunnel20)
        )
        commit(
          'setApplication',
          data?.results
            .filter(({ status }) => status !== 'rejected')
            .find(
              ({ status, displayState: { isFunnel20 } }) =>
                isFunnel20 && ['pre_meet_and_greet', 'open'].includes(status)
            )
        )
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  fetchApplicationRoomSelections({ commit, state }) {
    if (!state.application) return
    const query = { application: state.application.id }

    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(apiPaths.applications.roomSelections(query))
      .then(({ data }) => commit('setRoomSelections', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  /**
   *  Posting to this api will automatically transition the application to open
   *  and create a soft room lock for the specified room
   * @param {
   *  application: *id of application*
   *  meeting: meeting id
   *  room: room id
   *  move_in_date: Date
   *  lease_end_date: Date
   * } payload
   */
  saveApplicationRoomSelection({ commit }, payload) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .post(apiPaths.chats.createRoomSelectionFromMeeting, payload)
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setApplication(state, payload) {
    state.application = payload
  },
  setApplications(state, payload) {
    state.applications = payload
  },
  setRoomSelections(state, payload) {
    state.roomSelections = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
