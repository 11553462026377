export const homePricingOverrides = [
  // {
  //   slug: 'example-property-slug',
  //   base_rent: 3000,
  //   lastAvailableLeaseLength: 12,
  //   firstAvailableLeaseLength: 6,
  //   prices: {
  //     6: 2000,
  //     7: 2000,
  //     17: 2500,
  //     18: 2500,
  //   },
  // },
  {
    slug: 'professionally-managed-1-bedroom-1-bathroom-luxury-condo-logan-circle',
    firstAvailableLeaseLength: 6,
  },
]
export const roomPricingOverrides = [
  // {
  //   slug: 'example-property-slug',
  //   lastAvailableLeaseLength: 6,
  //   maximumLeaseLength: 6,
  //   rooms: {
  //     123: { // Room ID
  //       6: 999, // Lease Length: Price
  //     },
  //   },
  // },
  {
    slug: 'beautiful-alamo-square-apartment-with-backyard',
    firstAvailableLeaseLength: 12,
  },
  {
    slug: 'gorgeous-fairfax-home-with-beautiful',
    demandLeasesEndDate: '2024-06-30', // No leases past Aug 2024 (accounts for move-in-days)
  },
  // https://bungalowliving.slack.com/archives/C047N0ZJAQK/p1707238237641769
  {
    slug: 'beautiful-downtown-san-jose-home-with',
    // set demand leases end date to April 30, 2025
    demandLeasesEndDate: '2025-04-30',
  },
]
