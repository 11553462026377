import Vue from 'vue'
import * as ContentfulSDK from 'contentful'

const Contentful = {
  install(Vue, options = {}) {
    const contentfulClient = ContentfulSDK.createClient({
      space: options.space,
      accessToken: options.accessToken,
    })

    Vue.prototype.$contentful = contentfulClient
  },
}

Vue.use(Contentful, {
  space: process.env.VUE_APP_CTFL_SPACE_ID,
  accessToken: process.env.VUE_APP_CTFL_PUBLIC_ACCESS_TOKEN,
})

export default Contentful
