import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  approval: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  approval: (state) => state.approval,
}

const actions = {
  fetchWorkOrderHomeownerApproval({ commit }, approvalGuid) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(apiPaths.workOrderHomeownerApproval.approval(approvalGuid))
      .then(({ data }) => {
        commit('setWorkOrderHomeownerApproval', {
          ...data,
        })
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  updateWorkOrderHomeownerApproval({ commit }, approval) {
    return this._vm.$http
      .put(apiPaths.workOrderHomeownerApproval.approval(approval.guid), {'status': approval.status})
      .then(({ data }) => commit('setWorkOrderHomeownerApproval', data))
      .catch((error) => this._vm.$sentry.captureException(error))
  }
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setWorkOrderHomeownerApproval(state, payload) {
    state.approval = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
