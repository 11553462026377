<template>
  <div
    v-if="isOpen"
    :class="['modalContainer', { 'fullscreen-modal': fullscreen, closeAnimation, openAnimation }]"
    ref="modalContainerRef"
    @click.self="backgroundOnClickHandler"
  >
    <component ref="modalComponent" :is="modalComponent" v-bind="props" />
  </div>
</template>
<script>
/**
 * This modal component listens to a root event name 'openModal'
 * it accepts any of the fields in the defaultModalState bellow to dynamically use the internal functionality
 * ( You can send the name of the modal you wish to render OR the actual component !!)
 *
 * EXAMPLE
 *
    import CommunityEventsModal from '@/components/website/Property/CommunityEventsModal.vue'

    this.$root.$emit('openModal', {
      modalComponent: CommunityEventsModal,
      props: {
        marketCommunityEvents: this.marketCommunityEvents
      }
    })
 */
/**
 * Loads all the modals in the /Modals folder
 * These modals can be triggered by simply sending the `name` of the component
 */
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { delay } from '@/utils'
import Login from '../Auth/Login.vue'
import { mapGetters } from 'vuex'

const defaultModalState = () => ({
  // determins weither or not to show the modal
  isOpen: false,
  // Animation Flags
  closeAnimation: false,
  openAnimation: true,
  // allow the user to dismiss the modal
  dismissible: true,
  // Modal To Render
  modalComponent: null,
  // Props that will be passed down to the modal component
  props: {},
  // Determins if the modal will take up the full screen size
  fullscreen: false,
})
export default {
  components: { Login },
  name: 'Modal',
  data() {
    return { ...defaultModalState() }
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
  },
  created() {
    // Event listeners
    this.$root.$on('openModal', this.openModalHandler)
    this.$root.$on('closeModal', this.closeModalHandler)
    this.$root.$on('checkForActiveModal', (callback) => callback(this.isOpen))
    window.addEventListener('keyup', this.onCloseModal)
  },
  destroyed() {
    window.removeEventListener('keyup', this.onCloseModal)
  },
  methods: {
    backgroundOnClickHandler() {
      if (!this.dismissible) return
      this.onCloseModal()
    },
    onCloseModal(evt = {}) {
      if (evt?.type === 'keyup' && evt?.key !== 'Escape') return

      this.$root.$emit('closeModal', { modal: this.modalComponent })
    },
    // Takes the provided configuration object and dynamically overwrites the current modal state
    async openModalHandler(config) {
      if (!config.modalComponent) {
        return console.error('Missing required parameter - modalComponent of type Vue Component')
      }
      // Check if we're chaining an already open modal, if so handle lifecycle + animations:
      if (this.isOpen) {
        this.closeModalHandler()
        await delay(400)
      }

      this.updateModalData(config)
      if (this.isOpen) {
        this.openAnimation = false
      } else {
        // uses $nextTick to ensure the element is available
        this.disableScroll()
      }

      this.isOpen = true
    },
    // Triggers a closeAnimation animation and resets the internal state to the default
    closeModalHandler(config = {}) {
      // enables page scrolling
      this.enableScroll()
      // sets the closeAnimation flag to true which triggers the closeAnimation animations
      this.closeAnimation = true
      // After 300 ms ( when the animation is over ) the modal state is set back to the default
      setTimeout(() => {
        this.updateModalData(defaultModalState())
      }, 300)
    },
    disableScroll() {
      setTimeout(() => {
        try {
          if (this.isOpen && this.$refs.modalContainerRef) {
            disableBodyScroll(this.$refs.modalContainerRef, {
              // for ios modals, when we lock the body we need to allow certain elements to scroll property
              // https://github.com/willmcpo/body-scroll-lock#readme
              allowTouchMove: (el) => {
                return el.getAttribute('role') === 'slider'
              },
            })
          }
        } catch (e) {}
      }, 100)
    },
    enableScroll() {
      try {
        clearAllBodyScrollLocks()
      } catch (e) {}
    },
    // loop through the object keys and replace current data values
    updateModalData(newData) {
      for (let [key, value] of Object.entries(newData)) {
        this[key] = value
      }
    },
  },
}
</script>
<style lang="scss">
@keyframes showModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hideModal {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20px);
  }
}

.modalContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #0000005e;
  top: 0;
  left: 0;
  z-index: 10000000;
  display: flex;
  overflow-y: scroll;
  &.openAnimation {
    animation: showModal 0.25s forwards;
    > * {
      animation: slideUp 0.5s forwards, showModal 0.25s forwards;
    }
  }

  &.closeAnimation {
    animation: hideModal 0.3s forwards;
    > * {
      animation: slideDown 0.3s forwards;
    }
  }

  &:not(.fullscreen-modal) {
    padding: 2rem 0;
    @include mobile {
      padding: 0.5rem;
    }

    $modalSmallWidth: 440px;
    $modalMediumWidth: 520px;
    $modalLargeWidth: 600px;

    > * {
      width: $modalMediumWidth; // default

      &.small-modal {
        width: $modalSmallWidth;
      }

      &.medium-modal {
        width: $modalMediumWidth;
      }

      &.large-modal {
        width: $modalLargeWidth;
      }

      @include mobile {
        width: 100% !important;
      }

      height: max-content;
    }
  }
  // full screen modal content styling
  &.fullscreen-modal {
    overflow-x: hidden;

    > * {
      width: 100%;
      min-height: 100%;
      border-radius: 0;
    }
  }
  // rendered modal component styling
  > * {
    position: relative;
    margin: auto;
    background: white;
    border-radius: 8px;
    padding: 3.75rem 2.25rem 3.5rem 2.25rem;

    @include mobile {
      padding: 3.5rem 1.5rem 3rem 1.5rem;
    }

    // optional grid utility styling for modals
    &.grid-enabled {
      display: grid;
      grid-template-rows: auto auto 1fr auto;
      grid-template-columns: 100%;
      grid-gap: 0;
      grid-template-areas:
        'lead'
        'header'
        'content'
        'footer';

      .modalLead {
        grid-area: lead;
        text-align: center;
      }
      .modalHeader {
        grid-area: header;
        text-align: center;
      }
      .modalContent {
        grid-area: content;
      }
      .modalFooter {
        grid-area: footer;
      }
    }

    .go-back,
    .close-modal {
      cursor: pointer;
      position: absolute;
      top: 1.75rem;
      width: 0.875rem;
      height: 0.875rem;
      z-index: 9843674589673498567;
    }

    .go-back {
      left: 2.25rem;
      @include mobile {
        left: 1.5rem;
      }
    }

    .close-modal {
      right: 2.25rem;
      @include mobile {
        right: 1.5rem;
      }
    }
  }
}
</style>
