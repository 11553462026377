import apiPaths from '@/constants/apiPaths'
import { DEFAULT_MARKETS } from '@/constants/markets'
import { annotateMarketDetails, annotizeMarket } from '@/utils/markets.js'

const state = {
  markets: DEFAULT_MARKETS,
  market: {},
  marketFlags: [],
  marketFlagsFetched: false,
  marketsFetched: false,
  fetchMarketsError: false,
}

const actions = {
  async fetchMarkets({ commit }) {
    commit('setFetchMarketsError', false)
    try {
      const {
        data: { results },
      } = await this._vm.$http.get(apiPaths.markets.list)
      const annotizedMarkets = results.map(annotizeMarket)
      commit('setMarkets', annotizedMarkets)
    } catch (error) {
      commit('setFetchMarketsError', error)
    }
  },
  async fetchMarket({ commit }, marketIdOrSlug) {
    if (!marketIdOrSlug) return
    try {
      const { data } = await this._vm.$http.get(apiPaths.markets.detail(marketIdOrSlug))
      commit('setMarket', annotateMarketDetails(data))
    } catch (error) {
      this._vm.$sentry.captureException(error)
    }
  },
  fetchMarketFlags({ commit }, marketSlug) {
    if (!marketSlug) return

    commit('setMarketFlags', [])

    const searchParams = { market_slug: marketSlug }

    return this._vm.$http
      .get('flags', { searchParams })
      .then(({ data }) => commit('setMarketFlags', data))
  },
}

const mutations = {
  setMarkets: (state, payload) => {
    state.markets = payload
    state.marketsFetched = true
  },
  setMarket: (state, payload) => {
    state.market = payload
  },
  setMarketsFetched: (state, payload) => {
    state.marketsFetched = payload
  },
  setMarketFlags: (state, payload) => {
    state.marketFlags = payload
    state.marketFlagsFetched = true
  },
  setMarketFlagsFetched: (state, payload) => {
    state.marketFlagsFetched = payload
  },
  setFetchMarketsError: (state, payload) => {
    state.fetchMarketsError = payload
  },
}

const getters = {
  markets: ({ markets }) => markets,
  market: ({ market }) => market,
  marketsFetched: ({ marketsFetched }) => marketsFetched,
  marketFlags: ({ marketFlags }) => marketFlags,
  marketFlagsFetched: ({ marketFlagsFetched }) => marketFlagsFetched,
  fetchMarketsError: ({ fetchMarketsError }) => fetchMarketsError,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
