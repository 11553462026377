// used for calculating rooms list price ( minimum calculated price)
export const DEFAULT_MAXIMUM_LEASE_LENGTH = 24
export const DEFAULT_MINIMUM_LEASE_LENGTH = 3
export const MIN_SFR_LEASE_LENGTH = 8

export const MINIMUM_DAY_TO_LEASE_BUFFER = 90
export const MINIMUM_AVAILABILITY_DATE_BUFFER = 2

export const MOVE_IN_DAYS_OUT_MAX_DEFAULT = 30
export const MOVE_IN_DAYS_OUT_MAX_BY_MARKET = {
  boston: 60,
}
