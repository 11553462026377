<template>
  <div class="verified-badge">
    <div class="verified-badge__slot">
      <slot />
    </div>
    <div v-if="showBadge" class="verified-badge__icon">
      <span />
      <inline-svg width="18" height="18" :src="require('@/assets/icons/verified-check.svg')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifiedBadge',
  props: {
    showBadge: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
.verified-badge {
  $badge-width: 20px;
  position: relative;

  &__icon {
    position: absolute;
    border-radius: 100%;
    right: 0px;
    bottom: 4px;

    width: $badge-width;
    height: $badge-width;

    svg {
      position: relative;
      z-index: 3;
    }

    &::before {
      content: '';
      position: absolute;
      border-radius: 100%;
      height: $badge-width;
      width: $badge-width;
      display: block;
      left: -1px;
      top: 2.5px;
      background-color: white;
    }
  }
}
</style>
