import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import loadDeferredScripts from '@/scripts/defer.js'
import WebpackChunkErrorModal from '@/components/Modals/Shared/WebpackChunkErrorModal.vue'
import { debounce } from 'lodash-es'

import App from '@/App.vue'
import router from '@/router'
import store from './store'
import '@/plugins'

Vue.config.productionTip = false

// Deferred scripts
if (window && window.addEventListener) {
  window.addEventListener('load', loadDeferredScripts, false)
} else if (window && window.attachEvent) {
  window.attachEvent('onload', loadDeferredScripts)
}

// Core integrations:
Vue.use(VueRouter)
Vue.use(VueMeta)

const vueApp = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

router.onError(
  debounce((error) => {
    try {
      // if the user gets a chunkloaderror from fetching chunks no longer available ( and it's not prerender )
      // show the new content available modal
      if (error?.name === 'ChunkLoadError' && !window.navigator.userAgent.includes('Prerender')) {
        vueApp.$root.$emit('openModal', {
          modalComponent: WebpackChunkErrorModal,
          dismissible: false,
        })
      }
    } catch {}
  }, 300)
)

// Expose Vue for Cypress during e2e:
if (window.Cypress) window._vm = vueApp
