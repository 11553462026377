import Vue from 'vue'
import Padlock from '@livebungalow/toolbox/padlock'
import Postbox from '@livebungalow/toolbox/postbox'
import { SentryHTTPErrorHandler } from '@/plugins/sentry'

const httpConfig = {
  prefixUrl: process.env.VUE_APP_BACKEND_URL,
  camelCaseKeys: true,
}

const authConfig = {
  prefixUrl: process.env.VUE_APP_BACKEND_URL,
  callbackUrl: process.env.VUE_APP_OAUTH_URL,
  domain: process.env.VUE_APP_COOKIE_DOMAIN,
  tokenSeparator: process.env.VUE_APP_AUTH_TOKEN_SEPARATOR,
  providers: {
    google: {
      clientId: process.env.VUE_APP_OAUTH_GOOGLE_CLIENT_ID,
    },
    facebook: {
      clientId: process.env.VUE_APP_OAUTH_FACEBOOK_CLIENT_ID,
    },
    linkedin: {
      clientId: process.env.VUE_APP_OAUTH_LINKEDIN_CLIENT_ID,
    },
    apple: {
      clientId: process.env.VUE_APP_OAUTH_APPLE_CLIENT_ID,
    },
  },
}

Vue.use(Postbox, httpConfig, SentryHTTPErrorHandler)
Vue.use(Padlock, authConfig)
