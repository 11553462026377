import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  file: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  file: ({ file }) => file,
}

const actions = {
  fetchFile({ commit }, fileId) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(apiPaths.files.file(fileId))
      .then(({ data }) => commit('setFile', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  async createFile({ commit, state }, { purpose, file }) {
    commit('setLoading', true)
    commit('setError', false)

    // Build the payload
    const formData = new FormData()
    formData.append('purpose', purpose)
    formData.append('file', file)

    // Upload the file
    return this._vm.$http
      .post(apiPaths.files.files, formData)
      .then(({ data }) => commit('setFile', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setFile(state, payload) {
    state.file = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
