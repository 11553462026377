export const MLP_SEO_CATEGORIES = [
  'neighborhood',
  'neighborhoods',
  'landmarks',
  'features',
  'schools',
  'zip',
]

export const SEO_TEST_PATHS = {
  // Meta & headlines
  '/rooms-for-rent/new-york-city/neighborhoods/williamsburg': {
    metaTitle: 'Rooms for Rent Williamsburg, Brooklyn | Williamsburg Rooms for Rent',
    headline: 'Rooms for Rent NYC',
  },
  '/rooms-for-rent/washington-dc/features/pet-friendly': {
    metaTitle: 'Rooms for Rent That Allow Pets Washington | Dog Friendly Rooms for Rent Washington',
  },
  '/rooms-for-rent/tampa': {
    metaTitle: 'Rooms for Rent Tampa, FL | Tampa Room Rent | Rent a Room Tampa',
  },
  '/rooms-for-rent/los-angeles/neighborhoods/sherman-oaks': {
    metaTitle: 'Rooms for Rent Sherman Oaks, CA | Sherman Oaks Rooms for Rent',
  },
  '/rooms-for-rent/seattle': {
    metaTitle:
      'Rooms for Rent Seattle WA | Seattle Rooms for Rent | Rent a Room Seattle Bungalow.com',
    headline: 'Rooms for rent Seattle',
  },
  '/rooms-for-rent/los-angeles/neighborhoods/santa-monica': {
    metaTitle: 'Rooms for Rent Santa Monica | Santa Monica Rooms for Rent | Rent a Room',
  },
  '/rooms-for-rent/san-diego': {
    metaTitle: 'Rooms for Rent San Diego, CA | San Diego Room Rent | Rent a Room San Diego',
    headline: 'Rooms for rent San Diego',
  },
  '/rooms-for-rent/san-antonio': {
    metaTitle:
      'Rooms for Rent San Antonio, TX | San Antonio Room Rent | Rent a Room in San Antonio',
  },
  '/rooms-for-rent/phoenix': {
    metaTitle: 'Rooms for Rent Phoenix, AZ | Rent a Room in Pheonix Arizona',
  },
  '/rooms-for-rent/orange-county': {
    metaTitle: 'Rooms for Rent Orange County | Orange County Room Rent | Rent a Room Near Me',
  },
  '/rooms-for-rent/new-york-city': {
    metaTitle: 'Rooms for Rent NYC | NYC Room Rent | Rent a Room New York City',
  },
  '/rooms-for-rent/los-angeles/landmarks/ucla': {
    metaTitle: 'Rooms for Rent Near UCLA Los Angeles | UCLA Rent a Room',
  },
  '/rooms-for-rent/new-york-city/neighborhoods/lower-east-side': {
    metaTitle: 'Rooms for Rent Lower East Side NYC | Lower Manhattan Rooms for Rent',
  },
  '/rooms-for-rent/los-angeles': {
    metaTitle: 'Rooms for Rent Los Angeles | LA Room Rent | Rent a Room in LA',
    headline: 'Rooms for rent Los Angeles',
  },
  '/rooms-for-rent/houston': {
    metaTitle: 'Rooms for Rent Houston | Houston Room Rent | Rent a Room in Houston',
  },
  '/rooms-for-rent/denver': {
    metaTitle: 'Rooms for Rent Denver, CO | Denver Room Rent | Rent a Room Denver',
    headline: 'Rooms for rent Denver',
    schema: {
      '@type': 'Product',
      name: 'Rooms for Rent Denver',
      brand: {
        '@type': 'Brand',
        '@id': 'Bungalow',
        name: 'Bungalow',
        description:
          'Find a room in a home that hits the sweet spot between good quality and sensible pricing. Bungalow homes are hand-selected across major markets to match your lifestyle.',
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.1',
        ratingCount: '821',
        bestRating: '5',
        worstRating: '1',
      },
    },
  },
  '/rooms-for-rent/bay-area/neighborhoods/concord': {
    metaTitle: 'Rooms for Rent Concord, Bay Area, CA Near Me',
  },
  '/rooms-for-rent/chicago': {
    metaTitle: 'Rooms for Rent Chicago, IL | Chicago Room Rent | Rent a Room Chicago',
    headline: 'Rooms for rent Chicago',
  },
  '/rooms-for-rent/new-york-city/neighborhoods/bushwick': {
    metaTitle: 'Rooms for Rent Bushwick, New York Near Me',
  },
  '/rooms-for-rent/baltimore': {
    metaTitle: 'Rooms for Rent Baltimore | Baltimore Room Rent | Rent a Room in Baltimore',
  },
  '/rooms-for-rent/austin': {
    metaTitle: 'Rooms for Rent Austin, TX | Coliving Austin Room Rent | Bungalow Austin',
    headline: 'Rooms for rent Austin',
  },
  '/rooms-for-rent/los-angeles/neighborhoods/long-beach': {
    metaTitle: 'Longbeach Rooms for Rent, CA | Room for Rent Long Beach',
  },
  '/rooms-for-rent/new-york-city/features/furnished': {
    metaTitle: 'Furnish Room NYC | Firnished Rooms for Rent NYC ',
  },

  // H1 headlines

  '/rooms-for-rent/orange-county/neighborhoods/buena-park-north': {
    headline: 'Rooms for Rent Buena Park North',
  },
  '/rooms-for-rent/seattle/landmarks/mohai': {
    headline: 'Rooms for Rent Near Mohai',
  },
  '/rooms-for-rent/seattle/neighborhoods/madison-park': {
    headline: 'Rooms for Rent Madison Park',
  },
  '/rooms-for-rent/bay-area/neighborhoods/alviso': {
    headline: 'Rooms for Rent Alviso',
  },
  '/rooms-for-rent/denver/landmarks/red-rocks-amphitheater': {
    headline: 'Rooms for Rent Near Red Rocks Amphitheater',
  },
  '/rooms-for-rent/new-york-city/neighborhoods/greenridge': {
    headline: 'Rooms for Rent Greenridge',
  },
  '/rooms-for-rent/boston/landmarks/harvard-stadium': {
    headline: 'Rooms for Rent Near Harvard Stadium',
  },
  '/rooms-for-rent/los-angeles/neighborhoods/mandeville-canyon': {
    headline: 'Rooms for Rent Mandeville Canyon',
  },
  '/rooms-for-rent/dallas/neighborhoods/beckley-club-estates': {
    headline: 'Rooms for Rent Becklley Club Estates',
  },
  '/rooms-for-rent/san-antonio/landmarks/hays-street-bridge': {
    headline: 'Rooms for Rent near Hays Street Bridge',
  },
  '/rooms-for-rent/bay-area/neighborhoods/apparel-city': {
    headline: 'Rooms for Rent Apparel City',
  },
  '/rooms-for-rent/washington-dc/zip/20261': {
    headline: 'Rooms for Rent in 20261',
  },
  '/rooms-for-rent/houston/zip/77026': {
    headline: 'Rooms for Rent in 77026',
  },
  '/rooms-for-rent/dallas/neighborhoods/el-barrio-little-mexico': {
    headline: 'Rooms for Rent El Barrio Little Mexico',
  },
  '/rooms-for-rent/atlanta/zip/30321': {
    headline: 'Rooms for Rent in 30321',
  },
  '/rooms-for-rent/atlanta/neighborhoods/south-oaks': {
    headline: 'Rooms for Rent South Oaks',
  },
}
