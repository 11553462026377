<template>
  <form
    id="mirage-server-modal"
    class="grid-enabled pt-5 large-modal"
    @submit.prevent="saveMirageConfigHandler"
  >
    <div v-if="!mirageServerIsActive" class="go-back" @click="goToEntitySelection">
      <img src="@/assets/icons/arrow-left.svg" />
    </div>
    <span class="modalLead lead text-center mb-4">Mirage UI Testing</span>
    <span class="modalHeader header-md">2. Configure your mock data</span>
    <div class="close-modal" @click="$root.$emit('closeModal')">
      <img src="@/assets/icons/close.svg" />
    </div>
    <b-container class="modalContent mt-5 mb-3 w-100">
      <b-row>
        <b-col class="text-center">
          This will create 2 dummy chats connected to dev sendbird chats
        </b-col>
      </b-row>
    </b-container>
    <b-row class="modalFooter">
      <b-col class="text-center">
        <input
          type="checkbox"
          id="include-archived-chat"
          class="mr-3"
          v-model="mirageConfigClone.chatConfig.includeArchivedChat"
        />
        <label for="include-archived-chat">Include archived chat</label>
      </b-col>
      <b-col class="text-center mb-4" md="10" offset-md="1">
        <div v-if="mirageServerIsActive">
          {{ actionsInfoText }}
        </div>
      </b-col>
      <b-col md="4" sm="12" class="order-2 order-md-1 mt-2">
        <b-btn block @click="cancelOrShutdownHandler" :variant="cancelOrShutdownButtonVariant">
          {{ cancelOrShutdownButtonText }}
        </b-btn>
      </b-col>
      <b-col md="8" sm="12" class="order-1 order-md-2 mt-2">
        <b-btn :variant="submitButtonVariant" block type="submit">
          {{ submitButtonText }}
        </b-btn>
      </b-col>
    </b-row>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { cloneDeep } from 'lodash-es'
import MirageEntitySelectionModal from './MirageEntitySelectionModal.vue'

export default {
  name: 'MirageChatConfigModal',
  data() {
    return {
      mirageConfigClone: null,
    }
  },
  beforeMount() {
    this.mirageConfigClone = cloneDeep(this.mirageConfig)
    this.mirageConfigClone.chatConfig.enabled = true
  },
  computed: {
    ...mapGetters('mirage', ['mirageConfig']),
    mirageServerIsActive() {
      return this.mirageConfigClone.active
    },
    actionsInfoText() {
      return `${
        this.mirageServerIsActive
          ? "Restarting the server will clear any data you've created and"
          : 'Starting the server will'
      } take you to the Homeowner billing page`
    },
    submitButtonVariant() {
      return this.mirageServerIsActive ? 'secondary' : 'success'
    },
    submitButtonText() {
      return this.mirageServerIsActive ? 'Restart Server' : 'Start Server'
    },
    cancelOrShutdownButtonVariant() {
      return this.mirageServerIsActive ? 'danger' : 'light'
    },
    cancelOrShutdownButtonText() {
      return this.mirageServerIsActive ? 'Shutdown' : 'Cancel'
    },
  },
  methods: {
    ...mapActions('mirage', ['updateMirageConfig', 'resetMirage']),
    goToEntitySelection() {
      this.$root.$emit('openModal', { modalComponent: MirageEntitySelectionModal })
    },
    cancelOrShutdownHandler() {
      if (this.mirageServerIsActive) {
        this.resetMirage()
      }
      this.$root.$emit('closeModal')
    },
    async saveMirageConfigHandler() {
      this.mirageConfigClone.active = true
      this.updateMirageConfig(this.mirageConfigClone)
      this.$root.$emit('closeModal')
    },
  },
}
</script>

<style lang="scss"></style>
