<template>
  <div id="mirage-intro-modal" class="grid-enabled small-modal">
    <div class="close-modal" @click="$root.$emit('closeModal')">
      <img src="@/assets/icons/close.svg" />
    </div>
    <div class="modalLead lead mb-4">
      <img src="https://assets.bungalow.com/illustrations/spot/wave.png" />
    </div>
    <div class="modalHeader header-sm">Mirage UI Testing</div>
    <div class="modalContent w-100 px-3 mb-5 mt-4 text-center">
      <span>
        Mock user data for testing and development.
        <br /><br />
        <b>
          Full end to end testing is still necessary to ensure all aspects of the feature is
          working!
        </b>
      </span>
    </div>
    <div class="modalFooter">
      <b-button block variant="dark" @click="onContinue">Continue</b-button>
    </div>
  </div>
</template>

<script>
import MirageEntitySelectionModal from './MirageEntitySelectionModal.vue'

export default {
  name: 'MirageIntroModal',
  methods: {
    onContinue() {
      this.$root.$emit('openModal', { modalComponent: MirageEntitySelectionModal })
    },
  },
}
</script>

<style lang="scss">
#mirage-intro-modal {
  .modalLead {
    img {
      height: 60px;
      width: auto;
    }
  }
}
</style>
