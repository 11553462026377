export const DEFAULT_MARKETS = [
  {
    id: '-1',
    internalName: 'Seattle',
    displayName: 'Seattle',
    slug: 'seattle',
    region: 'Washington',
    country: 'United States',
  },
  {
    id: '-2',
    internalName: 'NYC',
    displayName: 'New York City',
    slug: 'new-york-city',
    region: 'New York',
    country: 'United States',
  },
  {
    id: '-3',
    internalName: 'Los Angeles',
    displayName: 'Los Angeles',
    slug: 'los-angeles',
    region: 'California',
    country: 'United States',
  },
  {
    id: '-4',
    internalName: 'Bay Area',
    displayName: 'Bay Area',
    slug: 'bay-area',
    region: 'California',
    country: 'United States',
  },
]

export const MARKET_COLORS = {
  pink: '#F2BDC6',
  green: '#7BD284',
  blue: '#8BAADA',
  brown: '#D1B4A6',
}

export const MARKET_SWATCHES = {
  'los-angeles': {
    color: MARKET_COLORS.pink,
    code: 'LA',
  },
  'washington-dc': {
    color: MARKET_COLORS.blue,
    code: 'DC',
  },
  portland: {
    color: MARKET_COLORS.green,
    code: 'PDX',
  },
  seattle: {
    color: MARKET_COLORS.pink,
    code: 'SEA',
  },
  boston: {
    color: MARKET_COLORS.green,
    code: 'BOS',
  },
  'san-diego': {
    color: MARKET_COLORS.blue,
    code: 'SD',
  },
  chicago: {
    color: MARKET_COLORS.brown,
    code: 'CHI',
  },
  'bay-area': {
    color: MARKET_COLORS.pink,
    code: 'SF',
  },
  'new-york-city': {
    color: MARKET_COLORS.blue,
    code: 'NY',
  },
  philadelphia: {
    color: MARKET_COLORS.brown,
    code: 'PHL',
  },
  baltimore: {
    color: MARKET_COLORS.blue,
    code: 'BAL',
  },
  vancouver: {
    color: MARKET_COLORS.green,
    code: 'VAN',
  },
  austin: {
    color: MARKET_COLORS.pink,
    code: 'ATX',
  },
}
