<template>
  <div class="market-selection-modal grid-enabled overflow-hidden">
    <div class="modal-close-button" @click="closeModal">
      <img src="@/assets/icons/fnd/close.svg" />
    </div>
    <h2 class="header-xl mb-3">First, tell us where<br />do you want to live?</h2>
    <Combobox
      name="markets"
      iconName="search"
      valueKey="displayName"
      ref="marketsCombobox"
      label="Markets Selector"
      class="market-selector-combobox__markets mt-3"
      popupType="list"
      v-model="marketValue"
      :options="markets"
      :maxOptionsColumns="1"
      :selectedValue="marketDisplayName"
      :emptyOptionLabel="marketTypeLabel"
      :selectOptionHandler="selectMarketHandler"
      :data-cy="`${marketSelectorLocations.MODAL}__market-selector-input`"
      @update="(input) => (marketValue = input)"
    >
      <template #prepend-option-item="{ layout }">
        <li
          class="option py-2 px-2 my-1 w-100 align-items-center rounded-borders"
          @click="autoSelectMarketBasedOffLocation"
          role="option"
        >
          <MarketTypeLayout title="Near me" :layout="layout" />
        </li>
      </template>
      <template #combobox-options-layout="{ option, layout }">
        <MarketTypeLayout
          :title="option.displayName"
          :subtitle="option.regionCode"
          :layout="layout"
          :image="option.imgMd"
        />
      </template>
    </Combobox>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MarketTypeLayout from '@/components/Navigation/MarketTypeLayout.vue'
import Combobox from '@/components/Shared/Combobox/Combobox.vue'
import { getRandomMarket } from '@/utils/markets.js'
import GeoLocationService from '@/services/geolocationService'
import HomeTypeSelectionModal from './HomeTypeSelectionModal.vue'

import { homeTypes, marketSelectorLocations } from '@/constants/nav.js'

export default {
  name: 'MarketSelectionModal',
  components: {
    Combobox,
    MarketTypeLayout,
  },
  props: {
    homeType: {
      type: String,
    },
    location: {
      type: String,
    },
  },
  data() {
    return {
      marketValue: '',
      marketSelectorLocations,
    }
  },
  created() {
    this.saveMarketSlug(null)
  },
  mounted() {
    this.marketValue = this.marketDisplayName

    this.$segment.track('Market Selector Activated', {
      location: this.location,
      option: 'market',
      market: this.marketDisplayName,
      propertyType: this.homeTypeDisplayName,
      page: this.$route.name,
    })
  },
  computed: {
    ...mapGetters('markets', ['markets']),
    ...mapGetters('nav', ['marketDisplayName', 'homeTypeDisplayName']),
    marketTypeLabel() {
      return `Try "${getRandomMarket(this.markets)}"`
    },
  },
  methods: {
    ...mapActions('nav', ['saveMarketSlug']),
    async autoSelectMarketBasedOffLocation() {
      try {
        this.$segment.track('Auto select location triggered', {
          location: this.location,
          page: this.$route.name,
        })
        const { latitude, longitude } = await GeoLocationService.get(true)
        const [{ displayName }] = GeoLocationService.sortObjectsByNearestLatLong(
          this.markets,
          latitude,
          longitude
        )
        this.selectMarketHandler(displayName)
        this.$segment.track('Auto select location success', {
          location: this.location,
          market: displayName,
          page: this.$route.name,
        })
      } catch (e) {
        this.$root.$emit('showNotification', {
          type: 'error',
          title: `Woops, we were unable to find your nearest market.`,
          duration: 5000,
        })
        this.$segment.track('Auto select location failure', {
          location: this.location,
          error: e.message,
          page: this.$route.name,
        })
      }
    },
    closeModal() {
      this.$root.$emit('closeModal')
    },
    async selectMarketHandler(selectedMarket, filterText) {
      const marketSlug = this.markets.find((market) => market.displayName === selectedMarket).slug

      this.saveMarketSlug(marketSlug)

      this.$segment.track('Market Option Selected', {
        location: this.location,
        market: this.marketDisplayName,
        propertyType: this.homeTypeDisplayName || this.homeType,
        page: this.$route.name,
        filter: filterText,
      })

      this.homeType
        ? this.viewMarketHomesByHomeType({ marketSlug, homeType: this.homeType })
        : this.$root.$emit('openModal', {
            modalComponent: HomeTypeSelectionModal,
            fullscreen: true,
            props: {
              marketSlug,
              location: this.location,
            },
          })
    },
    async viewMarketHomesByHomeType({ marketSlug, homeType }) {
      // If market and home type exists navigate to the property page
      this.$segment.track('Market Search Initiated', {
        location: this.location,
        market: this.marketDisplayName,
        propertyType: this.homeTypeDisplayName,
        page: this.$route.path,
      })

      const offering = homeTypes.find(({ key }) => key === homeType).route

      await this.$router
        .push({
          name: 'Listings',
          params: { market: marketSlug, offering },
          query: this.$route.query,
        })
        .catch(() => {})

      this.$root.$emit('closeModal')
    },
  },
}
</script>

<style scoped lang="scss">
.city-selection-modal {
  padding-left: 32px;
  padding-right: 32px;

  .header-md {
    font-size: 24px !important;
  }

  .market-selection {
    opacity: 0;
    animation: show-element 1s forwards;
  }
  .market-image {
    height: 48px;
    width: 48px;
    border-radius: 100%;
    background-position: center;
    background-size: cover;
  }
  .market-display-name {
    font-family: Akkurat Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
