import apiPaths from '@/constants/apiPaths'

const state = {
  featureFlags: [],
}

const actions = {
  fetchFeatureFlags({ commit, dispatch }) {
    return this._vm.$http
      .get(apiPaths.flags.featureFlags)
      .then(({ data }) => commit('setFeatureFlags', data))
      .catch((response) => this._vm.$sentry.captureException(response))
  },
}

const mutations = {
  setFeatureFlags: (state, payload) => {
    const listOfEnabledFlags = payload.map((flag) => flag.name)
    state.featureFlags = listOfEnabledFlags
  },
}

const getters = {
  featureFlags: (state) => state.featureFlags,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
