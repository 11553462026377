<template>
  <transition appear @before-enter="beforeEnter" @enter="enter" :css="false">
    <slot />
  </transition>
</template>

<script>
export default {
  props: {
    duration: {
      type: Number,
      default: 500,
    },
    delay: {
      type: Number,
      default: 0,
    },
    translate: Array,
  },
  methods: {
    beforeEnter(element) {
      element.style.opacity = 0

      // Specify transform properties *before* component enters:
      if (this.translate) element.style.transform = `translate(${this.translate.join(',')})`
    },
    enter(element) {
      const opacity = `opacity ${this.duration}ms`
      const easing = 'ease'
      const delay = `${this.delay}ms`

      // Setup opacity transition:
      let transitions = `${opacity} ${easing} ${delay}`

      // Add transforms (if provided):
      if (this.translate) transitions += `, transform ${this.duration}ms ${easing} ${delay}`

      // Set transition styles:
      element.style.transition = transitions

      // Not required, but forces repaint to ensure animation is triggered correctly:
      getComputedStyle(element)

      this.resetCSS(element)
    },
    resetCSS(element) {
      // Ensures browser has finished painting after setting a new CSS property:
      setTimeout(() => {
        element.style.opacity = 1
        if (this.translate) element.style.transform = 'initial'
      })
    },
  },
}
</script>
