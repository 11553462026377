import { uniq } from 'lodash-es'
import { annotizeProperty, propertyIsMatchForUserProfile } from '@/utils/properties.js'
import apiPaths from '@/constants/apiPaths.js'
import { HOUSE_MATCH_KEY } from '@/constants/property.js'

const RECENTLY_VIEWED_LISTINGS_KEY = 'recently-viewed-listings'

const state = {
  listings: [],
  listingsFetched: false,
  upcomingListings: [],
  upcomingListingsFetched: false,
  crossSellListings: [],
  crossSellListingsFetched: false,
}

const getters = {
  // using the responsive root getters, add universal _isMatch flag
  // onto the property IF the users profile is compatible and the home is co-living
  // Empty homes on the listings page are recognized as a match
  listings: (state, getters, rootState, rootGetters) => {
    const userProfile = rootGetters['user/userProfile']
    const userProfileMatchReady = rootGetters['user/userProfileMatchReady']
    return state.listings.map((property) => ({
      ...property,
      ...(!property._isGroupHome &&
        userProfileMatchReady && {
          [HOUSE_MATCH_KEY]: propertyIsMatchForUserProfile(property, userProfile),
        }),
    }))
  },
  recentlyViewedStorageKey: () => RECENTLY_VIEWED_LISTINGS_KEY,
  recentlyViewedListings: () => {
    return JSON.parse(localStorage.getItem(RECENTLY_VIEWED_LISTINGS_KEY))
  },
  getRecentlyViewedListingsByMarket: () => (marketSlug) => {
    const recentlyViewed = JSON.parse(localStorage.getItem(RECENTLY_VIEWED_LISTINGS_KEY))
    return recentlyViewed?.[marketSlug] || []
  },
  upcomingListings: (state) => {
    return state.upcomingListings
  },
  listingsFetched: (state) => {
    return state.listingsFetched
  },
  upcomingListingsFetched: (state) => {
    return state.upcomingListingsFetched
  },
  crossSellListings: (state) => {
    return state.crossSellListings
  },
}

const actions = {
  async fetchListings(
    { commit, rootGetters, dispatch },
    {
      marketSlug: market__slug,
      neighborhoodSlug: neighborhood__slug,
      marketingType: marketing_type,
    }
  ) {
    try {
      commit('setListingsFetched', false)
      const {
        data: { results },
      } = await this._vm.$http.get(apiPaths.listings.list, {
        searchParams: {
          market__slug,
          ...(neighborhood__slug && {
            neighborhood__slug,
          }),
          ...(marketing_type && {
            marketing_type,
          }),
        },
      })
      const annotizedProperties = results.map((property, i) =>
        annotizeProperty(property, rootGetters['constants/allPricingConstants'])
      )
      commit(
        'setListings',
        annotizedProperties.filter(({ isComingSoon }) => !isComingSoon)
      )
      commit(
        'setUpcomingListings',
        annotizedProperties.filter(({ isComingSoon }) => isComingSoon)
      )
      dispatch('nav/saveMarketSlug', market__slug, { root: true })
    } catch (error) {
      this._vm.$sentry.captureException(error)
    }
  },
  async fetchCrossSellListings(
    { commit, rootGetters, dispatch },
    { marketSlug: market__slug, marketingType: marketing_type }
  ) {
    try {
      commit('setCrossSellListingsFetched', false)
      const {
        data: { results },
      } = await this._vm.$http.get(apiPaths.listings.list, {
        searchParams: {
          market__slug,
          limit: 3,
          ...(marketing_type && {
            marketing_type,
          }),
        },
      })
      const annotizedProperties = results.map((property, i) =>
        annotizeProperty(property, rootGetters['constants/allPricingConstants'])
      )
      commit(
        'setCrossSellListings',
        annotizedProperties.filter(({ isComingSoon }) => !isComingSoon)
      )
      dispatch('nav/saveMarketSlug', market__slug, { root: true })
    } catch (error) {
      this._vm.$sentry.captureException(error)
    }
  },
  updateRecentlyViewedListingsByMarket({ getters, commit }, { marketSlug, listings }) {
    // Update the data with newly viewed listing(s)
    const newRecentlyViewedListings = { ...getters.recentlyViewedListings }
    newRecentlyViewedListings[marketSlug] = uniq(listings)
    // Set to local storage
    commit('setRecentlyViewedListingsByMarket', newRecentlyViewedListings)
  },
}

const mutations = {
  setListings: (state, payload) => {
    state.listings = payload
    state.listingsFetched = true
  },
  setListingsFetched: (state, payload) => {
    state.listingsFetched = payload
  },
  setUpcomingListings: (state, payload) => {
    state.upcomingListings = payload
    state.upcomingListingsFetched = true
  },
  setUpcomingListingsFetched: (state, payload) => {
    state.upcomingListingsFetched = payload
  },
  setCrossSellListings: (state, payload) => {
    state.crossSellListings = payload
    state.crossSellListingsFetched = true
  },
  setCrossSellListingsFetched: (state, payload) => {
    state.crossSellListingsFetched = payload
  },
  setRecentlyViewedListingsByMarket: (state, payload) => {
    localStorage.setItem(RECENTLY_VIEWED_LISTINGS_KEY, JSON.stringify(payload))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
