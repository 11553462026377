<template>
  <validation-provider mode="lazy" ref="provider" :id="name" :name="name" :rules="validation">
    <div :class="['form-radio-toggle', { locked: readonly }]">
      <label
        tabindex="-1"
        :for="`radio-toggle-${componentId}__yes`"
        :class="{ selected: selection === true, locked: readonly }"
      >
        Yes
      </label>
      <span :class="['divider', { selected: selectionMade }]" />
      <label
        tabindex="-1"
        :for="`radio-toggle-${componentId}__no`"
        :class="{ selected: selection === false, locked: readonly }"
      >
        No
      </label>
      <input
        class="form-radio-toggle__input"
        :id="`radio-toggle-${componentId}__yes`"
        type="radio"
        :value="true"
        v-model="selection"
      />
      <input
        class="form-radio-toggle__input"
        :id="`radio-toggle-${componentId}__no`"
        type="radio"
        :value="false"
        v-model="selection"
      />
    </div>
  </validation-provider>
</template>

<script>
export default {
  name: 'RadioToggle',
  data() {
    return {
      selection: null,
    }
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    validation: {
      type: [String, Object],
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // Set initial value if provided:
    if (this.selectionMade) this.selection = !!this.$attrs.value
  },
  computed: {
    componentId() {
      return this._uid
    },
    selectionMade() {
      // If any value is passed other than strictly "nothing", it was set:
      return ![null, undefined].includes(this.$attrs.value)
    },
  },
  watch: {
    selection(newValue) {
      this.$emit('input', newValue)
    },
  },
}
</script>

<style lang="scss">
.form-radio-toggle {
  display: inline-flex;
  align-items: center;
  height: 34px;

  label {
    cursor: pointer;
    user-select: none;
    margin: 0;
    font-size: 13px;
    display: grid;
    place-content: center;
    width: 40px;
    height: 100%;
    padding-top: 1px;
    text-align: center;
    transition: all 0.15s ease;
    border: 1px solid $charcoal-30;
    background: white;

    &:first-of-type {
      border-radius: 8px 0 0 8px;
      border-right: none;
    }
    &:last-of-type {
      border-radius: 0 8px 8px 0;
      border-left: none;
    }

    &.selected {
      font-weight: 900;
      border-color: $blue-60;
      background: $blue-10;
    }

    &.locked {
      cursor: not-allowed !important;
      pointer-events: none;
      opacity: 0.75;
    }
  }

  input {
    opacity: 0;
  }

  .divider {
    transition: background 0.15s ease;
    display: inline-block;
    width: 1px;
    height: 100%;
    background: $charcoal-30;

    &.selected {
      background: $blue-60;
    }
  }

  &__input {
    position: absolute;
    z-index: -1;
  }
}
</style>
