import { sortBy } from 'lodash-es'
import Cookies from 'js-cookie'
import { distance } from '@/utils/mapping.js'
import apiPaths from '@/constants/apiPaths'

const CACHED_COOKIE_NAME = 'USER_GEO_LOCATION'
const COOKIE_EXPIRY_IN_DAYS = 10

const GeoLocationService = {
  /**
   * @param {Boolean} override forces the service to fetch the users location instead of using cache
   * @returns Object with the following keys
   * - city
   * - country
   * - latitude
   * - longitude
   * - region
   *  Checkout the docs here https://vercel.com/docs/concepts/functions/edge-functions/vercel-edge-package#geolocation
   */
  async get(override = false) {
    const cachedUserLocation = Cookies.get(CACHED_COOKIE_NAME)
    if (!override && cachedUserLocation) {
      try {
        const parsedCachedLocation = JSON.parse(cachedUserLocation)
        return parsedCachedLocation
      } catch (e) {
        // if parsing produces an error, clear
        Cookies.remove(GeoLocationService)
      }
    }
    const userLatLong = await fetch(apiPaths.geo.geolocation, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const { data } = await userLatLong.json()
    // save the data directly to a cookie
    this.save(data)
    return data
  },
  /**
   *
   * @param {Object} payload
   */
  save(payload) {
    Cookies.set(CACHED_COOKIE_NAME, JSON.stringify(payload), { expires: COOKIE_EXPIRY_IN_DAYS })
  },
  /**
   *
   * @param {Array} Objects ( must have latitude & longitude keys )
   * @param {Number} latitude
   * @param {Number} longitude
   * @return []
   */
  sortObjectsByNearestLatLong(listToSort, latitude, longitude) {
    if (!latitude || !longitude) throw new Error('Missing required latitude & longitude')
    return sortBy(
      listToSort.map((market) => {
        return {
          _distanceToUser: distance(latitude, longitude, market.latitude, market.longitude, 'K'),
          ...market,
        }
      }),
      ['_distanceToUser']
    )
  },
}

export default GeoLocationService
