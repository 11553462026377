<template>
  <div
    :class="[
      'form-input',
      `form-input__${type}`,
      {
        white,
        'auto-height': ['radio', 'radioSimple'].includes(type),
        'form-input--locked': readonly,
        'form-input--disabled': disabled,
      },
    ]"
  >
    <!-- Text | Email | Tel -->
    <template v-if="['text', 'email', 'tel', 'number'].includes(type)">
      <validation-provider
        :id="name"
        :mode="mode"
        ref="provider"
        :name="label"
        v-slot="{ errors }"
        :rules="validation"
      >
        <label :class="['form-input__label', { visible: value || dirty }]">
          {{ label }}
        </label>
        <b-input
          ref="textInput"
          :name="name"
          v-bind="$attrs"
          @input="onChange"
          @focus.native="onFocus"
          @blur.native="onBlur"
          @keypress="onKeyPress"
          :type="type"
          :value="value"
          :placeholder="label"
          :autocapitalize="type === 'email' && 'off'"
          :disabled="disabled"
          :readonly="readonly"
        />
        <div class="form-input__tray">
          <span :class="['form-input__tray-error', { visible: error || errors.length }]">
            {{ error || errors[0] }}
          </span>
          <span :class="['form-input__tray-hint', { visible: !error && !errors.length && hint }]">
            {{ hint }}
          </span>
        </div>
      </validation-provider>
    </template>

    <template v-if="type === 'radio'">
      <validation-provider
        :mode="mode"
        :id="name"
        ref="provider"
        :name="name"
        v-slot="{ errors }"
        :rules="validation"
      >
        <b-row class="px-2 mt-5">
          <b-col cols="12">
            <div class="font-weight-bold mb-3">{{ label }}</div>
            <div class="form-input__tray">
              <span :class="['form-input__tray-error', { visible: error || errors.length }]">
                {{ error || errors[0] }}
              </span>
              <span :class="['form-input__tray-hint', { visible: !errors.length && hint }]">
                {{ hint }}
              </span>
            </div>
          </b-col>
          <b-col
            v-for="(optionLabel, optionValue) in selectOptions"
            :key="optionValue"
            md="4"
            class="px-1 py-1"
          >
            <b-form-radio
              class="styled-checkbox"
              button
              button-variant="light"
              :name="name"
              v-model="radioValue"
              @input="onChange"
              :value="optionValue"
            >
              {{ optionLabel }}
            </b-form-radio>
          </b-col>
        </b-row>
      </validation-provider>
    </template>

    <template v-if="type === 'radioSimple'">
      <validation-provider
        :mode="mode"
        :id="name"
        ref="provider"
        :name="name"
        v-slot="{ errors }"
        :rules="validation"
      >
        <b-form-radio
          v-for="(optionLabel, optionValue) in selectOptions"
          :key="optionValue"
          :name="name"
          v-model="radioValue"
          @input="onChange"
          :value="optionValue"
        >
          {{ optionLabel }}
        </b-form-radio>
        <div class="form-input__tray">
          <span :class="['form-input__tray-error', { visible: error || errors.length }]">
            {{ error || errors[0] }}
          </span>
          <span :class="['form-input__tray-hint', { visible: !error && !errors.length && hint }]">
            {{ hint }}
          </span>
        </div>
      </validation-provider>
    </template>

    <!-- Select -->
    <template v-if="type === 'select'">
      <validation-provider
        :mode="mode"
        :id="name"
        ref="provider"
        :name="nameAsValidationLabel ? name : label"
        v-slot="{ errors }"
        :rules="validation"
      >
        <label :class="['form-input__label', { visible: value }]">{{ label }}</label>
        <b-form-select
          :class="{ empty: !value }"
          :name="name"
          @input="onChange"
          :value="value"
          :placeholder="label"
          :disabled="disabled"
          :readonly="readonly"
        >
          /** The label option needs a value of undefined to be viewable. You can override the value
          for this default disabled value by passing in placeholderValue ( default to undefined ) */
          <option :value="$attrs.placeholderValue || null" disabled>
            {{ $attrs.placeholder || label }}
          </option>
          <option v-for="(value, key) in selectOptions" :key="key" :value="key">
            {{ value }}
          </option>
        </b-form-select>
        <div class="form-input__tray">
          <span :class="['form-input__tray-error', { visible: error || errors.length }]">
            {{ error || errors[0] }}
          </span>
          <span :class="['form-input__tray-hint', { visible: !errors.length && hint }]">
            {{ hint }}
          </span>
        </div>
      </validation-provider>
    </template>

    <!-- Textarea -->
    <template v-if="type === 'textarea'">
      <validation-provider
        :mode="mode"
        :id="name"
        ref="provider"
        :name="label"
        v-slot="{ errors, failedRules }"
        :rules="textareaRules"
        type="text"
      >
        <label :class="['form-input__label', { visible: value || dirty }]">
          <div class="d-flex">{{ label }}</div>
        </label>
        <b-textarea
          :name="name"
          v-bind="$attrs"
          @input="onChange"
          @focus.native="onFocus"
          @blur.native="onBlur"
          :value="value"
          :placeholder="label"
          :disabled="disabled"
          :readonly="readonly"
        />
        <span
          v-if="max || min"
          :class="['form-input__helper', { error: hasLimitError(failedRules) }]"
        >
          {{ value ? value.length : 0 }} / {{ max || min }}
        </span>
        <span v-else-if="showCharacterCount" class="form-input__helper">
          {{ value ? value.length : 0 }}
        </span>

        <div class="form-input__tray pt-1">
          <span :class="['form-input__tray-error', { visible: errors.length }]">
            {{ errors[0] }}
          </span>
          <span :class="['form-input__tray-hint', { visible: !errors.length && hint }]">
            {{ hint }}
          </span>
        </div>
      </validation-provider>
    </template>

    <!-- Phone -->
    <template v-if="type === 'phone'">
      <validation-provider
        :mode="mode"
        :id="name"
        ref="provider"
        :name="label"
        v-slot="{ errors }"
        :rules="phoneValidationRules"
      >
        <label :class="['form-input__label', { visible: value || dirty }]">
          {{ label }}
        </label>
        <vue-tel-input
          ref="phoneInput"
          :wrapperClasses="['form-control', { filled: value }]"
          :placeholder="!dirty ? label : ''"
          :value="value"
          v-bind="$attrs"
          @input="onChange"
          @focus="onFocus"
          @blur="onBlur"
          defaultCountry="US"
          :onlyCountries="['US', 'CA', 'GB', 'MX']"
          disabledFetchingCountry
          :disabled="disabled"
          :readonly="readonly"
        />
        <div class="form-input__tray">
          <span :class="['form-input__tray-error', { visible: errors.length }]">
            {{ errors[0] }}
          </span>
          <span :class="['form-input__tray-hint', { visible: !errors.length && hint }]">
            {{ hint }}
          </span>
        </div>
      </validation-provider>
    </template>

    <!-- Date -->
    <template v-if="type === 'date'">
      <validation-provider
        :mode="mode"
        :id="name"
        ref="provider"
        :name="label"
        v-slot="{ errors }"
        :rules="validation"
      >
        <label :class="['form-input__label', { visible: value || dirty }]" style="z-index: 1">
          {{ label }}
        </label>
        <date-picker
          :initial-view="birthdayMode ? 'year' : 'day'"
          :wrapperClass="['form-control', { filled: value }]"
          inputClass="form-input-field-date-picker pointer"
          :placeholder="!dirty ? label : ''"
          :value="dateValue"
          format="MMMM d, yyyy"
          v-bind="$attrs"
          @input="onChange"
          @opened="onFocus"
          @closed="onBlur"
          :disabled="disabled"
          :readonly="readonly"
        />
        <div class="form-input__tray">
          <span :class="['form-input__tray-error', { visible: error || errors.length }]">
            {{ error || errors[0] }}
          </span>
          <span :class="['form-input__tray-hint', { visible: !errors.length && hint }]">
            {{ hint }}
          </span>
        </div>
      </validation-provider>
    </template>
  </div>
</template>

<script>
import { formatAsCurrency } from '@/utils'
import { formatISO } from 'date-fns'
import { VueTelInput } from 'vue-tel-input'
import DatePicker from 'vuejs-datepicker'

const NUMBER_FORMATS = ['currency', 'number']

export default {
  name: 'FormInput',
  components: { VueTelInput, DatePicker },
  props: {
    value: {
      type: [String, Number, Boolean, Date],
    },
    mode: {
      type: String,
      default: 'lazy',
      validator: (value) => {
        return ['eager', 'aggressive', 'lazy', 'passive'].includes(value)
      },
    },
    noPhoneValidation: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value) =>
        [
          'radio',
          'radioSimple',
          'text',
          'email',
          'tel',
          'select',
          'phone',
          'textarea',
          'number',
          'date',
        ].includes(value),
    },
    format: {
      type: String,
    },
    birthdayMode: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    nameAsValidationLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    hint: {
      type: String,
    },
    error: {
      type: [String, Boolean],
    },
    validation: {
      type: [String, Object],
      default: '',
    },
    min: {
      type: [String, Number],
    },
    max: {
      type: [String, Number],
    },
    showCharacterCount: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      radioValue: this.value || undefined,
      dirty: false,
      phoneNumber: {},
      dateValue: null,
    }
  },
  mounted() {
    this.ensureCorrectDate()
    this.ensurePhoneUpdates()
    this.ensureCurrencyFormat()
  },
  watch: {
    phoneNumber() {
      this.validate()
    },
    value(newValue) {
      if (this.type === 'date') this.ensureCorrectDate(newValue)
    },
  },
  computed: {
    selectedOptionsFromArray() {
      return this.$attrs.options.reduce((options, item) => {
        options[item] = item
        return options
      }, {})
    },
    selectOptions() {
      const selectOptionsInArray = Array.isArray(this.$attrs.options)

      return selectOptionsInArray ? this.selectedOptionsFromArray : this.$attrs.options
    },
    hasErrors() {
      return this.$refs.provider && this.$refs.provider.errors.length
    },
    textareaRules() {
      const { min, max } = this

      if (min && max) return `${this.validation}|minmax:${min},${max}`

      const minChars = this.min ? `|min:${this.min}` : ''
      const maxChars = this.max ? `|max:${this.max}` : ''

      return `${this.validation}${minChars}${maxChars}`
    },
    phoneValidationRules() {
      if (this.noPhoneValidation) return null
      return {
        required: this.validation === 'required' || this.validation.required === true,
        phone: this.phoneNumber,
      }
    },
  },
  methods: {
    onKeyPress(event) {
      // These effectively work as a "mask" preventing the keystroke(s) from appearing in the input
      if (this.max) {
        const newValue = event.target.value
        if (newValue.length >= this.max) event.preventDefault()
      }

      if (NUMBER_FORMATS.includes(this.format)) {
        const onlyNumbers = /[0-9/]+/
        if (!onlyNumbers.test(event.key)) event.preventDefault()
      }
    },
    validate() {
      this.$nextTick(() => this.$refs?.provider?.validate())
    },
    onFocus(el) {
      this.dirty = true
    },
    onBlur(el) {
      if (['phone', 'textarea'].includes(this.type)) this.validate()

      this.dirty = false
    },
    onChange(newValue = '', object = {}) {
      // Display any errors
      if (this.hasErrors) this.validate()

      // Parse the value entered if a phone number:
      if (this.type === 'phone') {
        this.phoneNumber = object
        // Set the valie to international format (downstream systems need country code):
        const intlNumber = object?.number?.international
        newValue = intlNumber || newValue
        this.$emit('parsed', object)
      }

      // Ensure emails are lowercase:
      if (this.type === 'email') {
        newValue = newValue.toLowerCase()
      }

      // Handle formatting of currency:
      if (this.format === 'currency') {
        return this.formatAsCurrency(newValue)
      }

      // Output date in format or ISO:
      if (this.type === 'date') {
        newValue = formatISO(newValue)
      }

      // Emit the value back to parent:
      this.$emit('input', newValue)
    },
    formatAsCurrency(value) {
      // Only format if using a compatible field type
      if (!['text', 'tel'].includes(this.type)) return value
      // Format as currency:
      const formattedValue = formatAsCurrency(value)
      // Emit back to parent:
      this.$emit('input', formattedValue)
      // Set the native input value to the formatted:
      this.$nextTick(() => {
        if (formattedValue) this.$refs.textInput.$el.value = formattedValue
      })
    },
    hasLimitError(failedRules) {
      return Object.keys(failedRules).some((rule) => ['max', 'min', 'minmax'].includes(rule))
    },
    ensureCurrencyFormat() {
      if (this.format === 'currency' && this.value) this.formatAsCurrency(this.value)
    },
    ensureCorrectDate(valueFromInput) {
      const dateValue = valueFromInput || this.value

      if (this.type === 'date' && dateValue) {
        // JS dates can be one day off depending on string format, using slashes helps:
        try {
          // replaces '-' with '/'
          const dateStringWithSlashes = dateValue?.replace(/-/g, '/').replace(/T.+/, '')
          // Update the value before sending (back) to the date-picker
          this.dateValue = formatISO(new Date(dateStringWithSlashes))
        } catch (dateParsingError) {
          console.error('Date Field Error:', dateParsingError)
        }
      }
    },
    ensurePhoneUpdates() {
      if (this.type === 'phone' && this.value) {
        this.$nextTick(() => {
          this.$refs?.phoneInput?.onInput?.(this.value)
        })
      }
    },
  },
}
</script>
