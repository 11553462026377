import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  groupApplication: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  groupApplication: ({ groupApplication }) => groupApplication,
  incompleteGroupStatuses: () => [null, 'open', undefined],
  groupSourceNotFound: ({ error }) => error?.code === 404,
  groupAppPropertyMatches: ({ groupApplication }, getters, { property }) =>
    property?.property?.slug === groupApplication?.propertySlug,
}

const actions = {
  // https://stage-fieldstone.bungalow.com/api/v1/internal-docs/#applications-groups-sources-get
  fetchGroupApplicationSource({ commit }, groupSourceSlug) {
    commit('setLoading', true)
    return this._vm.$http
      .get(apiPaths.applications.groups.preview + groupSourceSlug)
      .then(({ data }) => commit('setGroupApplication', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  // https://stage-fieldstone.bungalow.com/api/v1/internal-docs/#applications-groups-sources-post
  createGroupApplicationSource({ commit }, payload) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .post(apiPaths.applications.groups.source, payload)
      .then(({ data }) => commit('setGroupApplication', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setGroupApplication(state, payload) {
    state.groupApplication = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
