import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  classification: undefined,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  classification: ({ classification }) => classification,
}

const actions = {
  fetchClassification({ commit }, classificationId) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(apiPaths.support.classification(classificationId))
      .then(({ data }) => commit('setClassification', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setClassification(state, payload) {
    state.classification = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
