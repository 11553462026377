const state = {
  showBanner: true,
}

const actions = {
  dismissBanner({ commit }) {
    commit('setShowBanner', false)
  },
}

const mutations = {
  setShowBanner: (state, payload) => {
    state.showBanner = payload
  },
}

const getters = {
  showBanner: (state) => state.showBanner,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
