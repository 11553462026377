import { homeTypes } from '@/constants/nav.js'
import BookATourRoutes from '@/pages/BookATour'
import { MLP_SEO_CATEGORIES } from '@/constants/seoContent'
import MemberFunnelRoutes from '@/pages/MemberFunnel'

const listingsRouteConfig = {
  beforeEnter: (to, from, next) => {
    // if offering is not passed, default to rooms-for-rent
    const { offering } = to.params
    if (!offering) return next({ ...to, params: { ...to.params, offering: 'rooms-for-rent' } })
    homeTypes.find(({ route }) => route === offering) ? next() : next('/404')
  },
  props: ({ params: { offering } }) => ({
    homeType: homeTypes.find(({ route }) => route === offering)?.key || 'co_living',
  }),
}

// Dynamic routes to support SEO URL(s)
const dynamicSEORoutes = MLP_SEO_CATEGORIES.map((category) => {
  return {
    path: `/:offering/:market/${category}/:category`,
    name: `${category}Listings`, // e.g. neighborhoodListings
    meta: { category },
    component: () => import(/* webpackChunkName: "listings" */ './Listings.vue'),
    ...listingsRouteConfig,
  }
})

export default [
  // Property Short Link Redirect
  {
    path: '/home/:propertyId',
    name: 'PropertyShortLink',
    component: () =>
      import(/* webpackChunkName: "property" */ './containers/PropertyContainer.vue'),
  },

  // MLP Level Routes:
  {
    path: '/:offering/:market',
    name: 'Listings',
    component: () => import(/* webpackChunkName: "listings" */ './Listings.vue'),
    ...listingsRouteConfig,
  },

  // ~10k dynamic SEO URLs for MLPs
  ...dynamicSEORoutes,

  // PLP Routes:
  {
    path: '/:offering/:market/:slug',
    component: () =>
      import(/* webpackChunkName: "property" */ './containers/PropertyContainer.vue'),
    ...listingsRouteConfig,
    children: [
      {
        path: '',
        name: 'Property',
        component: () => import(/* webpackChunkName: "property" */ './Property.vue'),
        ...listingsRouteConfig,
      },
      ...BookATourRoutes,
      // Member Funnel
      ...MemberFunnelRoutes,

      // Catch-all for bad URL(s) past the property base
      {
        path: '*',
        redirect: '/:offering/:market/:slug',
      },
    ],
  },
]
