// Member Funnel 3.0 - Q3 2022
export default [
  {
    path: 'apply',
    component: () => import(/* webpackChunkName: "member-funnel" */ './MemberFunnelContainer.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'MemberFunnelStart',
        meta: { hideFooter: true },
        component: () => import(/* webpackChunkName: "member-funnel" */ './MemberFunnelStart.vue'),
      },
      {
        path: 'profile',
        name: 'MemberFunnelProfile',
        meta: { hideFooter: true },
        component: () =>
          import(/* webpackChunkName: "member-funnel" */ './MemberFunnelProfile.vue'),
      },
      {
        path: 'group',
        name: 'MemberFunnelGroup',
        meta: { hideFooter: true },
        component: () => import(/* webpackChunkName: "member-funnel" */ './MemberFunnelGroup.vue'),
      },
      {
        path: 'lease',
        name: 'MemberFunnelLease',
        meta: { hideFooter: true },
        component: () => import(/* webpackChunkName: "member-funnel" */ './MemberFunnelLease.vue'),
      },
      {
        path: 'preferences',
        name: 'MemberFunnelPreferences',
        meta: { hideFooter: true },
        component: () =>
          import(/* webpackChunkName: "member-funnel" */ './MemberFunnelPreferences.vue'),
      },
      {
        path: 'income',
        name: 'MemberFunnelIncome',
        meta: { hideFooter: true },
        component: () => import(/* webpackChunkName: "member-funnel" */ './MemberFunnelIncome.vue'),
      },
      {
        path: 'background',
        name: 'MemberFunnelBackground',
        meta: { hideFooter: true },
        component: () =>
          import(/* webpackChunkName: "member-funnel" */ './MemberFunnelBackground.vue'),
      },
      {
        path: 'evaluating',
        name: 'MemberFunnelOutcome',
        meta: { hideFooter: true },
        component: () =>
          import(/* webpackChunkName: "member-funnel" */ './MemberFunnelOutcome.vue'),
      },
      {
        path: 'application',
        name: 'MemberFunnelApply',
        meta: { hideFooter: true },
        component: () => import(/* webpackChunkName: "member-funnel" */ './MemberFunnelApply.vue'),
      },
      {
        path: 'pre-approved',
        name: 'MemberFunnelApproved',
        meta: { hideFooter: true },
        component: () =>
          import(/* webpackChunkName: "member-funnel" */ './MemberFunnelApproved.vue'),
      },
      {
        path: 'not-approved',
        name: 'MemberFunnelDenied',
        meta: { hideFooter: true },
        component: () => import(/* webpackChunkName: "member-funnel" */ './MemberFunnelDenied.vue'),
      },
    ],
  },
]
