<template>
  <div class="ideal-roommate-tags my-2">
    <slot name="header" />
    <div class="d-flex flex-wrap mt-2">
      <template v-if="showHouseRoommatePreferences">
        <EmojiTag :tag="houseAgeRange" fromBackend />
        <EmojiTag :tag="genderPreference" fromBackend />
      </template>
      <EmojiTag :tag="catPreference" fromBackend />
      <EmojiTag :tag="dogPreference" fromBackend />
      <EmojiTag v-if="showPetTag" :tag="petPreference" fromBackend />
      <!-- Default slot for additional tags when needed -->
      <slot />
    </div>
  </div>
</template>

<script>
import EmojiTag from '@/components/Property/EmojiTag.vue'

export default {
  name: 'IdealRoommateTags',
  props: {
    showPetTag: {
      type: Boolean,
      default: false,
    },
    isPetFriendly: {
      type: Boolean,
      required: true,
    },
    houseProfile: {
      type: Object,
      required: true,
    },
    emojiEnabled: {
      type: Boolean,
      default: false,
    },
    showHouseRoommatePreferences: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EmojiTag,
  },
  computed: {
    petPreference() {
      const petsOK = this.isPetFriendly
      const petsEmoji = petsOK ? '🐾' : '❌'
      return `${petsOK ? '' : 'No '}Pets Allowed ${this.emojiEnabled ? petsEmoji : ''}`
    },
    dogPreference() {
      const { esaDog } = this.houseProfile
      return `${esaDog && this.isPetFriendly ? '' : 'ESA '}Dogs ${this.emojiEnabled ? '🐶 ' : ''}OK`
    },
    catPreference() {
      const { esaCat } = this.houseProfile
      return `${esaCat && this.isPetFriendly ? '' : 'ESA '}Cats ${this.emojiEnabled ? '🐱 ' : ''}OK`
    },
    houseAgeRange() {
      const { minAge, maxAge } = this.houseProfile
      let ageRange = minAge || maxAge ? `${minAge || '18'} - ${maxAge || '40+'}` : '18+'
      return `${ageRange} yrs old${this.emojiEnabled ? ' ✅' : ''}`
    },
    genderPreference() {
      const preferences = this.houseProfile.genderPreferences.map(({ name }) => name)
      // if the house profile has no preferences or it's greater than 3
      // return any gender
      if (!preferences.length || preferences.length > 2) {
        return `Any gender${this.emojiEnabled ? ' 🙌' : ''}`
      }
      // otherwise loop through and join all selected preferences
      return `Identify as ${preferences.join(' or ')}${this.emojiEnabled ? ' 👍' : ''}`
    },
  },
}
</script>

<style lang="scss">
.ideal-roommate-tags {
  .emoji-tag {
    b {
      font-size: 11px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
</style>
