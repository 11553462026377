<template>
  <div id="mirage-server-modal" class="grid-enabled pt-5 large-modal">
    <span class="modalLead lead text-center mb-4">Mirage UI Testing</span>
    <span class="modalHeader header-md">1. Select a user type</span>
    <div class="close-modal" @click="$root.$emit('closeModal')">
      <img src="@/assets/icons/close.svg" />
    </div>
    <b-container class="modalContent mt-5 mb-3 w-100">
      <b-row>
        <b-col md="10" offset-md="1" class="mb-4">
          <swiper :options="swiperOptions">
            <swiper-slide v-for="(option, index) in entityOptions" :key="index">
              <div class="card text-center">
                <img class="card-img-top mt-3" :src="option.image" alt="A happy home" />
                <div class="card-body pb-5">
                  <h5 class="card-title" v-text="option.title" />
                  <p class="card-text" v-text="option.description" />
                  <b-button
                    block
                    variant="secondary"
                    @click="option.onClick"
                    :disabled="option.disabled"
                  >
                    Select
                  </b-button>
                </div>
              </div>
            </swiper-slide>

            <!-- Swiper Controls -->
            <div class="fah__card-prev swiper-button-prev" slot="button-prev">
              <inline-svg :src="require('@/assets/icons/fnd/chevron-left.svg')" />
            </div>
            <div class="fah__card-next swiper-button-next" slot="button-next">
              <inline-svg :src="require('@/assets/icons/fnd/chevron-right.svg')" />
            </div>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </b-col>
      </b-row>
    </b-container>
    <b-row class="modalFooter">
      <b-col sm="12" class="mt-2">
        <b-button block variant="light" class="border" @click="$root.$emit('closeModal')">
          Cancel
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MirageChatConfigModal from './MirageChatConfigModal.vue'
import MirageFunnelConfigModal from './MirageFunnelConfigModal.vue'
import MirageHomeownerConfigModal from './MirageHomeownerConfigModal.vue'
import MirageResidentConfigModal from './MirageResidentConfigModal.vue'

export default {
  name: 'MirageEntitySelectionModal',
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: '.fah__card-next',
          prevEl: '.fah__card-prev',
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
      entityOptions: [
        {
          image: 'https://assets.bungalow.com/illustrations/full-color/preapproved.png',
          title: 'Funnel',
          description: 'Test funnel variants',
          onClick: this.setupFunnelOnClickHandler,
          disabled: false,
        },
        {
          image: 'https://assets.bungalow.com/illustrations/spot/meet-roommates.png',
          title: 'Chat',
          description: 'Mock property chats',
          onClick: this.setupChatOnClickHandler,
          disabled: false,
        },
        {
          image: 'https://assets.bungalow.com/illustrations/spot/choose-your-home.png',
          title: 'Resident',
          description: 'Mock being a resident',
          onClick: this.setupResidentOnClickHandler,
          disabled: false,
        },
        {
          image: 'https://assets.bungalow.com/illustrations/spot/keys.png',
          title: 'Homeowner',
          description: '⛔️ This feature is currently disabled.',
          onClick: this.setupHomeownerOnClickHandler,
          disabled: true,
        },
      ],
    }
  },
  beforeMount() {
    if (this.mirageServerIsActive && this.mockingResident) {
      this.$root.$emit('openModal', { modalComponent: MirageResidentConfigModal })
    }
    if (this.mirageServerIsActive && this.mockingHomeowner) {
      this.$root.$emit('openModal', { modalComponent: MirageHomeownerConfigModal })
    }
    if (this.mirageServerIsActive && this.mockingChats) {
      this.$root.$emit('openModal', { modalComponent: MirageChatConfigModal })
    }
    if (this.mirageServerIsActive && this.mockingFunnel) {
      this.$root.$emit('openModal', { modalComponent: MirageFunnelConfigModal })
    }
  },
  computed: {
    ...mapGetters('mirage', ['mirageConfig']),
    mockingResident() {
      return this.mirageConfig.userConfig.isResident
    },
    mockingHomeowner() {
      return this.mirageConfig.userConfig.isHomeowner
    },
    mockingChats() {
      return this.mirageConfig.chatConfig.enabled
    },
    mockingFunnel() {
      return this.mirageConfig.funnelConfig.enabled
    },
    mirageServerIsActive() {
      return this.mirageConfig.active
    },
  },
  methods: {
    setupResidentOnClickHandler() {
      this.$root.$emit('openModal', { modalComponent: MirageResidentConfigModal })
    },
    setupHomeownerOnClickHandler() {
      this.$root.$emit('openModal', { modalComponent: MirageHomeownerConfigModal })
    },
    setupChatOnClickHandler() {
      this.$root.$emit('openModal', { modalComponent: MirageChatConfigModal })
    },
    setupFunnelOnClickHandler() {
      this.$root.$emit('openModal', { modalComponent: MirageFunnelConfigModal })
    },
  },
}
</script>

<style lang="scss" scoped>
#mirage-server-modal {
  .card-img-top {
    width: 80px;
    height: 80px;
    margin: auto;
  }
}
</style>
