import { get } from 'lodash-es'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  identity: null,
  verificationConfig: null,
  payoutMethods: null,
  accountActivity: null,
  properties: null,
  periscopeEmbed: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  identity: ({ identity }) => identity,
  homeownerIsVerified: ({ identity }) => get(identity, 'isVerified'),
  homeownerPayoutMethods: ({ payoutMethods }) => payoutMethods,
  verificationConfig: ({ verificationConfig }) => verificationConfig,
  outstandingHomeownerRequirements: ({ identity }) => get(identity, 'requirements', []).length,
  properties: ({ properties }) => properties,
  periscopeEmbed: ({ periscopeEmbed }) => periscopeEmbed,
}

const actions = {
  fetchHomeownerIdentity({ commit }) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(apiPaths.homeowner.identity)
      .then(({ data: verificationConfig }) => commit('setIdentity', verificationConfig))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  async fetchHomeownerPropertiesList({ commit }) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const response = await this._vm.$http.get(apiPaths.homeowner.listProperties)
      const data = response.data || response
      commit('setProperties', data.results)
    } catch (error) {
      console.error(error)
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },

  async fetchHomeownerPeriscopeEmbed({ commit }, propertyId) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const { data } = await this._vm.$http.get(apiPaths.homeowner.getPeriscopeEmbed(propertyId))
      commit('setPeriscopeEmbed', data)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },

  async fetchHomeownerPayoutMethods({ commit }) {
    try {
      commit('setLoading', true)
      commit('setError', false)

      const {
        data: { results },
      } = await this._vm.$http.get(apiPaths.homeowner.payoutMethods)
      const defaultOnly = results.filter(({ isDefault }) => isDefault)
      commit('setPayoutMethods', defaultOnly)
      commit('setError', false)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },

  fetchHomeownerIdentityVerificationConfig({ commit }, payload) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .post(apiPaths.homeowner.stripeIdentity, payload)
      .then(({ data: verificationConfig }) => commit('setVerificationConfig', verificationConfig))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  acceptHomeownerInvite({ commit }, payload) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .put(apiPaths.homeowner.invite, payload)
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  createHomeownerPayoutMethod({ commit }, payload) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .post(apiPaths.homeowner.createPayoutMethod, payload)
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  createManualHomeownerPayoutMethod({ commit }, payload) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .post(apiPaths.homeowner.createManualPayoutMethod, payload)
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setVerificationConfig(state, verificationConfig) {
    state.verificationConfig = verificationConfig
  },
  setIdentity(state, identity) {
    state.identity = identity
  },
  setPayoutMethods(state, payoutMethods) {
    state.payoutMethods = payoutMethods
  },
  setAccountActivity(state, accountActivity) {
    state.accountActivity = accountActivity
  },
  setProperties(state, properties) {
    state.properties = properties
  },
  setPeriscopeEmbed(state, periscopeEmbed) {
    state.periscopeEmbed = periscopeEmbed
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
