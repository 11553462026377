import moment from 'moment'
import { sum, times, find } from 'lodash-es'
import { differenceInDays, differenceInMonths, isPast, addDays } from 'date-fns'
import {
  roundPriceToNearestFiveDollars,
  getCalculatedRoomPrice,
  getAvailableLeaseLengths,
} from '@livebungalow/toolbox/abacus'
import {
  getRoomLowestPriceAvailable,
  getMaximumLeaseLength,
  getMoveInDaysOutMaxByMarket,
} from '@/utils/pricing.js'
import {
  MIN_SFR_LEASE_LENGTH,
  MINIMUM_DAY_TO_LEASE_BUFFER,
  MINIMUM_AVAILABILITY_DATE_BUFFER,
} from '@/constants/pricing.js'
import { DEFAULT_HOUSE_PROFILE, PRONOUN_MAP } from '@/constants/property.js'
import { formatAsCurrency } from '@/utils'
import { roomPricingOverrides } from '@/constants/propertyPrices'

/**
 *
 * @param {Object} property
 * @param {
 *  _seasonalMultiplier: {},
 *  _holdingFeeMultipliers: {},
 *  _leaseLengthMultipliers: {},
 * } pricingConstants
 * @returns {Property}
 *
 * This utility function adds convenience / computed fields onto the property object.
 * Any field which is added to the base should be prefixed with a _ to easily
 * identify that this is a computed field and not one directly from the api
 */
export const propertyIsMatchForUserProfile = (property, userProfile) => {
  try {
    const { age, pronoun } = userProfile
    // user has not completed their profile, no match
    if (!age || !pronoun) return false
    // if the user is within the age band and fits the gender preferences
    // this home is a instant match
    const { minAge, maxAge, genderPreferences } = property.houseProfile
    // Ensure minimum age
    const passesMinAge = !minAge || age >= minAge
    // Ensure maximum age
    const passesMaxAge = !maxAge || age <= maxAge
    // Ensure users pronoun matches the houses gender preferences
    const passesGenderPreference =
      // if the house has no gender preference or have selected the maximum ( 3 )
      // the user matches
      [0, 3].includes(genderPreferences.length) ||
      genderPreferences.some(({ name }) =>
        (PRONOUN_MAP[pronoun] || PRONOUN_MAP.other).includes(name)
      )
    // return check for all 3 criteria
    return passesMinAge && passesMaxAge && passesGenderPreference
  } catch (e) {
    return false
  }
}
// @TODO logic needs to live on the backend
// if the earliest available date is null, in the past, or less than 2 days in the future
// default the earliest available date to 2 days in the future
// @TODO https://livebungalow.atlassian.net/browse/WEB-875 This logic can be moved to the backend and removed / altered
const getPropertyAvailabilityDate = (earliestAvailableDate) => {
  return earliestAvailableDate &&
    (isPast(new Date(earliestAvailableDate)) ||
      differenceInDays(new Date(earliestAvailableDate), new Date()) <
        MINIMUM_AVAILABILITY_DATE_BUFFER)
    ? addDays(new Date(), MINIMUM_AVAILABILITY_DATE_BUFFER)
    : addDays(new Date(earliestAvailableDate), 1)
}

export const annotizeProperty = (property, pricingConstants = {}) => {
  // This should never trigger... but just in case, avoid parsing bad data:
  if (!property) return property
  // Add a tracking flag when a property has been annotated
  property.isAnnotized = true
  // default the house profile if it doesn't exist
  property.houseProfile = property.houseProfile || DEFAULT_HOUSE_PROFILE

  // Ensure the property earliestAvailableDate is not in the past
  property.earliestAvailableDate = getPropertyAvailabilityDate(property.earliestAvailableDate)

  // Surface first property level promotion(s)
  property.activePromotion = property?.promotions?.property[0] || null

  // calculate the maximum lease length up to the default 12
  const propertyMaxLeaseLength = getMaximumLeaseLength({
    demandLeasesEndDate: property.demandLeasesEndDate,
    availabilityDate: property.earliestAvailableDate,
  })

  // detect group homes by property marketing type field
  const _isGroupHome = property.propertyMarketingType === 'group_living'

  // establish group home minimum pricing
  const groupHomeMinimumPrice = getRoomLowestPriceAvailable({
    basePrice: property.fullPropertyPrice,
    availabilityDate: property.earliestAvailableDate,
    maximumLeaseLength: propertyMaxLeaseLength,
    ...pricingConstants,
  })

  // HACK: Override pricing for specific properties/rooms
  const ROOM_PRICING_OVERRIDES = find(roomPricingOverrides, { slug: property.slug })

  // rooms are only returned in the property detail api call
  // we then stamp the lowest available price for each room based off its availability,
  // base price, and a calculated maximum lease length for minimum pricing
  if (property.rooms) {
    property.rooms = property.rooms
      .filter((room) => room.price)
      .map((room) => ({
        ...room,
        _lowestPriceAvailable: getRoomLowestPriceAvailable({
          basePrice: room.price,
          availabilityDate: isPast(new Date(room.availabilityDate))
            ? new Date()
            : new Date(room.availabilityDate),
          ...pricingConstants,
          // HACK: Apply the overrides last to ensure they take precedence
          ...ROOM_PRICING_OVERRIDES,
        }),
      }))
  }

  // the property room prices key is returned in the list view for market properties
  // we also need to crunch the true lowest available price accounting for the
  // demand lease end date
  property.roomPrices = property.roomPrices.map((basePrice) =>
    getRoomLowestPriceAvailable({
      basePrice,
      availabilityDate: property.earliestAvailableDate,
      ...pricingConstants,
      // HACK: Apply the overrides last to ensure they take precedence
      ...ROOM_PRICING_OVERRIDES,
    })
  )

  // If a property is returned with no images, add a single image
  // with the default placeholder values
  if (!property.images.length)
    property.images.push({
      mdUrl: 'https://assets.bungalow.com/images/property-placeholder.jpg',
      smUrl: 'https://assets.bungalow.com/images/property-placeholder.jpg',
      lgUrl: 'https://assets.bungalow.com/images/property-placeholder.jpg',
    })

  // Base pricing:
  // For group homes, we need to set the min and max price to use the fullPropertyPrice as the base
  const priceMin = roundPriceToNearestFiveDollars(
    _isGroupHome ? groupHomeMinimumPrice : Math.min(...property.roomPrices)
  )
  const priceMax = roundPriceToNearestFiveDollars(
    _isGroupHome ? groupHomeMinimumPrice : Math.max(...property.roomPrices)
  )
  const priceDisplay =
    priceMin !== priceMax ? `${formatAsCurrency(priceMin)}+` : formatAsCurrency(priceMin)

  const priceRange =
    priceMin !== priceMax
      ? `${formatAsCurrency(priceMin)} – ${formatAsCurrency(priceMax)}`
      : String(formatAsCurrency(priceMin))

  // Previous pricing:
  const prevMinPrice = Math.min(...property.prevPrices)
  const prevMaxPrice = Math.max(...property.prevPrices)
  const prevFullPropertyPrice = sum(...property.prevPrices)
  const prevPriceDisplay =
    prevMinPrice !== prevMaxPrice
      ? `${formatAsCurrency(prevMinPrice)}+`
      : formatAsCurrency(prevMaxPrice)
  const prevPriceRange =
    prevMinPrice !== prevMaxPrice
      ? `${formatAsCurrency(prevMinPrice)} – ${formatAsCurrency(prevMaxPrice)}`
      : String(formatAsCurrency(prevMinPrice))

  // Omit rooms with no pricing data:
  const availableRooms = property.rooms

  // Filtering / flag keys start
  const masterBedroomAvailable =
    availableRooms && availableRooms.some((room) => room.name.toLowerCase().includes('master'))

  const filledRooms = property.rooms && property.rooms.filter((room) => !room.price)

  const hasDryer =
    property.amenities && property.amenities.some((amenity) => amenity.displayName === 'Dryer')
  const hasWasher =
    property.amenities && property.amenities.some((amenity) => amenity.displayName === 'Washer')
  const isPetFriendly =
    property.amenities &&
    property.amenities.some((amenity) => amenity.displayName === 'Pet Friendly')

  // filter flag keys end

  const marketMoveInDaysOutMax = getMoveInDaysOutMaxByMarket(property.market.slug)

  // default the move out days max to the original 89 days as it was in the property page
  property._moveInDaysOutMax = marketMoveInDaysOutMax
  // The following code is a temporary solution for preventing homes which are
  // being off-boarded from being viewed. The backend will soon handle this for us and this
  // code can be removed. This code also establishes a maximum move in days out, this is
  // extremely important when the home is being off-boarded to prevent errors throughout the code.
  if (property.demandLeasesEndDate) {
    // Calculate the maximum dates from the earliest available date a user
    // can select as a move in date ( this needs to ensure that the move in date and lease end date
    // DO NOT exceed the demand lease end date )
    property._moveInDaysOutMax =
      differenceInDays(new Date(property.demandLeasesEndDate), property.earliestAvailableDate) -
      MINIMUM_DAY_TO_LEASE_BUFFER
    // reset the move in days max if it exceeds the default maximum
    if (property._moveInDaysOutMax > marketMoveInDaysOutMax)
      property._moveInDaysOutMax = marketMoveInDaysOutMax
  }

  // Additional computed fields:
  const additionalFields = {
    marker: {},
    label: {},
    priceMin,
    priceMax,
    priceDisplay,
    priceRange,
    prevMinPrice,
    prevMaxPrice,
    prevPriceDisplay,
    prevPriceRange,
    prevFullPropertyPrice,
    hasDryer,
    hasWasher,
    isPetFriendly,
    availableRooms,
    filledRooms,
    masterBedroomAvailable,
    occupiedRoomCount: property.totalRoomCount - property.availableRoomCount,
    vacancyPercent: property.availableRoomCount / property.totalRoomCount,
    position: {
      lat: property.address.latitude,
      lng: property.address.longitude,
    },
    _isGroupHome,
    _isEmpty: property.availableRoomCount === property.totalRoomCount,
  }

  // Return fully annotized property with additional fields injected:
  return { ...property, ...additionalFields }
}

export const propertySalesforceUrl = (SFDC_ID) =>
  `https://bungalowliving.lightning.force.com/lightning/r/Property__c/${SFDC_ID}/view`

export const getRoomAvailabilityDate = (roomAvailabilityDate) => {
  const today = moment().toDate()
  const roomIsAvailableNow = moment(today).isAfter(roomAvailabilityDate)

  return roomIsAvailableNow ? today : moment(roomAvailabilityDate).toDate()
}

export const getBedroomPriceMatrix = ({
  room,
  property,
  pricingConstants,
  availableLeaseLengths = getAvailableLeaseLengths(),
}) => {
  // Ensure we have a property
  if (!property) return
  // Ensure property is annotized!
  if (property && !property.isAnnotized) property = annotizeProperty(property)
  // Get the room price (if it comes from /applications API, it's a tuple)
  const roomPrice = Array.isArray(room.price) ? room.price[0] : room.price
  // Earliest room availability
  const availabilityDate = getRoomAvailabilityDate(room.availabilityDate)
  // Dictionary for (price -by- move-in -by- lease length)
  const basePriceMatrix = availableLeaseLengths.reduce((matrix, leaseLength) => {
    // There are 12 possible move-in week "periods"
    const roomPrices = times(property._moveInDaysOutMax + 1, (daysUntilMoveIn) => {
      // Calculate the room price for each period, based on all the factors:
      return getCalculatedRoomPrice({
        basePrice: roomPrice,
        availabilityDate,
        daysUntilMoveIn,
        leaseLength,
        ...pricingConstants,
      }).roomPrice
    })
    // Save the result
    matrix[leaseLength] = roomPrices
    return matrix
  }, {})

  // Merge data onto property room
  return {
    ...room,
    basePriceMatrix,
  }
}

export const getFirstAvailableLeaseLength = ({
  property,
  minimumLeaseLengthForPropertyBySlug = () => {},
  availableLeaseLengths = getAvailableLeaseLengths(),
}) => {
  // Non-retail/SFR properties shouldn't advertise leases < SFR MIN
  if (!property?.retailProperty) return MIN_SFR_LEASE_LENGTH

  return minimumLeaseLengthForPropertyBySlug(property?.slug) || availableLeaseLengths[0]
}

export const getLastAvailableLeaseLength = ({
  property,
  availabilityDate,
  availableLeaseLengths = getAvailableLeaseLengths(),
  demandLeasesEndDateOverride,
}) => {
  const demandLeasesEndDate = demandLeasesEndDateOverride || property?.demandLeasesEndDate

  // If lease end date is populated, respect the properties end of life date
  if (demandLeasesEndDate) {
    const monthsToEndDate = differenceInMonths(
      new Date(demandLeasesEndDate),
      new Date(availabilityDate)
    )
    return monthsToEndDate < 24
      ? monthsToEndDate
      : availableLeaseLengths[availableLeaseLengths.length - 1]
  }
  // Otherwise just return the default longest lease length
  return availableLeaseLengths[availableLeaseLengths.length - 1]
}

export const getMaximumOccupancyFromAvailableRooms = (availableRooms = 0) => {
  // Maximum default occupancy is 2n+1
  return availableRooms * 2 + 1
}

export const getEarliestAvailabilityDate = (property) => {
  if (!property) return

  const earliestAvailability = moment(property.earliestAvailableDate)
  return Math.max(moment().add(2, 'days').toDate(), earliestAvailability.toDate())
}
