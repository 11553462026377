export default [
  {
    path: '/about',
    alias: ['/about-us'],
    name: 'About',
    beforeEnter() {
      return (window.location.href = '/about')
    },
  },
  {
    path: '/contact',
    alias: ['/help-form'],
    name: 'Help',
    beforeEnter() {
      return (window.location.href = '/contact')
    },
  },
  {
    path: '/careers',
    name: 'Careers',
    beforeEnter() {
      return (window.location.href = '/careers')
    },
  },
  {
    path: '/institutional-investor',
    name: 'InstitutionalInvestor',
    beforeEnter() {
      return (window.location.href = '/institutional-investor')
    },
  },
  {
    path: '/homeowners',
    alias: ['/partners', '/homeowner'],
    name: 'Homeowners',
    beforeEnter() {
      return (window.location.href = '/homeowners')
    },
  },
  {
    path: '/phone',
    name: 'Phone',
    beforeEnter() {
      return (window.location.href = '/phone')
    },
  },
  {
    path: '/radar',
    alias: ['/investors', '/buy-with-bungalow', '/real-estate-investing'],
    name: 'Radar',
    beforeEnter() {
      return (window.location.href = '/radar')
    },
  },
  {
    path: '/real-estate-licenses',
    name: 'RealEstateLicenses',
    beforeEnter() {
      return (window.location.href = '/real-estate-licenses')
    },
  },
  {
    path: '/start',
    alias: ['/live', '/try'],
    name: 'Start',
    beforeEnter() {
      return (window.location.href = '/start')
    },
  },
  {
    path: '/offer',
    name: 'HomeownerOffer',
    beforeEnter() {
      return (window.location.href = '/homeowners?utm_source=mail')
    },
  },
  {
    path: '/meet-and-greet',
    name: 'MeetAndGreet',
    beforeEnter() {
      return (window.location.href = '/live/app')
    },
  },
  {
    path: '/refer',
    name: 'refer',
    beforeEnter() {
      return (window.location.href = '/refer')
    },
  },
  {
    path: '/renters-rooms',
    name: 'RenterRooms',
    beforeEnter() {
      return (window.location.href = '/renters-rooms')
    },
  },
  {
    path: '/renters-homes',
    name: 'RenterHomes',
    beforeEnter() {
      return (window.location.href = '/renters-homes')
    },
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    beforeEnter(route) {
      // Pass along email query param if present:
      const emailParam = route?.query?.email ? `?email=${route.query.email}` : ''
      const url = `/unsubscribe${emailParam}`
      return (window.location.href = url)
    },
  },
  {
    path: '/acquisitions',
    name: 'Acquisitions',
    beforeEnter(route) {
      return (window.location.href = '/acquisitions')
    },
  },
  {
    path: '/ca-support',
    name: 'CASupport',
    beforeEnter() {
      return (window.location.href = '/ca-support')
    },
  },
  {
    path: '/resident/meet-and-greet',
    name: 'ResidentMeetAndGreet',
    beforeEnter() {
      return (window.location.href = '/resident/meet-and-greet')
    },
  },
  {
    path: '/resident-screening-requirements',
    name: 'ResidentScreeningRequirements',
    beforeEnter() {
      return (window.location.href = '/resident-screening-requirements')
    },
  },
]
