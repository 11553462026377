import Vue from 'vue'
import packageJson from '../../package.json'

/* Analytics.js Documentation:
  https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/ */

const Segment = {
  page(name, properties = {}, options, callback) {
    if (!window.analytics) return

    const category = null
    window.analytics.page(
      name,
      category,
      {
        version: packageJson.version,
        ...properties,
        url: window.location.href,
      },
      options,
      callback
    )
  },
  track(event, properties = {}, options, callback) {
    if (!window.analytics) return

    window.analytics.track(
      event,
      {
        version: packageJson.version,
        ...properties,
      },
      options,
      callback
    )
  },
  viewed(pageElement) {
    if (!window.analytics || !pageElement) return

    window.analytics.track('Page Element Viewed', {
      name: pageElement,
      elementId: pageElement,
    })
  },
  identify(userId, traits = {}, options = {}, callback) {
    if (!window.analytics || !userId) return

    const defaultOptions = { integrations: { Salesforce: true, Intercom: false } }
    const mergedOptions = { ...defaultOptions, ...options }

    window.analytics.identify(userId, traits, mergedOptions, callback)
  },
  reset() {
    if (!window.analytics) return

    window.analytics.reset()
  },
}

Vue.prototype.$segment = Segment

export default Segment
