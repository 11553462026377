export const delay = (timeout) => new Promise((resolve) => setTimeout(resolve, timeout))

export const toFixed = (value) => new Number(parseFloat(value)).toFixed(2)

export const convertToDecimal = (number, decimalPlaces = 2) => {
  const parsedFloat = new Number(parseFloat(number)).toFixed(decimalPlaces)
  return isNaN(parsedFloat) ? '0.00' : parsedFloat
}

export const setLocalStorageData = (storageKey, newData = {}) => {
  const existingData = JSON.parse(window.localStorage.getItem(storageKey)) || {}

  window.localStorage.setItem(storageKey, JSON.stringify({ ...existingData, ...newData }))
}

export const getLocalStorageData = (storageKey) => {
  return JSON.parse(window.localStorage.getItem(storageKey)) || {}
}

export const removeLocalStorageData = (storageKey) => {
  window.localStorage.removeItem(storageKey)
}

export const clearLocalStorageData = () => {
  window.localStorage.clear()
}

export const getCurrentUrl = () => window.location.href.split('?')[0]

export const formatAsNumber = (value) => String(value).replace(/[^.0-9/-]/g, '')

export function formatAsCurrency(value, withDecimals = false) {
  // Ensure value is provided, or at least 0
  if (!value && value !== 0 && value !== '$') return value

  // Strip out any non-numeric characters:
  value = formatAsNumber(value)

  // If that left nothing, return nothing...
  if (!value) return value

  // Attempt to remove any non-digit chars:
  value = parseFloat(value)

  // Return value formatted as currency:
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    minimumFractionDigits: withDecimals ? 2 : 0,
  }).format(value)
}

export function parseHtmlText(html = '') {
  const removeHTMLRegex = /(<([^>]+)>)/gi

  return html.replace(removeHTMLRegex, '')
}

export function isHeadlessBrowser() {
  const prerenderIO = 'Prerender (+https://github.com/prerender/prerender)'

  return (
    !!(window.navigator.webdriver && !window.chrome) ||
    (window.navigator.userAgent.includes('HeadlessChrome') &&
      !window.navigator.userAgent.includes(prerenderIO))
  )
}

export function downloadAsFile(fileContent, fileName) {
  const url = window.URL.createObjectURL(new Blob([fileContent]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.parentElement.removeChild(link)
}

export function getCookie(name) {
  const getCookieValues = (cookie) => {
    const cookieArray = cookie.split('=')
    return cookieArray.length > 1 ? cookieArray[1].trim() : null
  }

  const getCookieNames = (cookie) => {
    const cookieArray = cookie.split('=')
    return cookieArray.length > 0 ? cookieArray[0].trim() : null
  }

  const cookies = document.cookie.split(';')

  const cookieValue = cookies.map(getCookieValues)[cookies.map(getCookieNames).indexOf(name)]

  return cookieValue
}

export function setCookie(name, value, days) {
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)

  const expires = '; expires=' + date.toGMTString()
  document.cookie = name + '=' + value + expires + ';path=/'
}

export function getParam(param) {
  const match = RegExp('[?&]' + param + '=([^&]*)').exec(window.location.search)

  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

export const getUtmParams = (query) => {
  const utmKeys = [
    'utm_source',
    'utm_campaign',
    'utm_medium',
    'utm_term',
    'utm_content',
    'gclid',
    'fbclid',
  ]

  const utmParams = utmKeys.reduce((params, key) => {
    const utmParam = query[key] || getCookie(key)
    if (utmParam) params[key] = utmParam
    return params
  }, {})
  // Ensure source, using default if not set:
  if (!utmParams.utm_source) utmParams.utm_source = 'organic'

  return utmParams
}

export const trackGoogleClicks = () => {
  // Google Click ID (glcid) is provided as a query param via google adwords clicks:
  const googleClickId = getParam('gclid')
  // Set glcid as a cookie, if a Google click source is present:
  if (googleClickId) {
    const googleClickSource = getParam('gclsrc')
    if (!googleClickSource || googleClickSource.indexOf('aw') !== -1) {
      setCookie('gclid', googleClickId, 90)
    }
  }
}

export const setUTMCookies = () => {
  let utmParams = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_term', 'utm_content']

  utmParams.forEach(function (param) {
    let value = getParam(param)
    if (value) setCookie(param, value, 90)
  })
}

export const convertCamelCaseToDashCase = (originalString) =>
  originalString.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase())

// Formatting verified phone numbers using North American numbers standards
// https://developers.google.com/style/phone-numbers
export const formatPhoneNumber = (phoneNumberString) => {
  const cleanedPhoneString = ('' + phoneNumberString).replace(/\D+/g, '')
  const match = cleanedPhoneString.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)
  if (match) return '(' + match[2] + ') ' + match[3] + '-' + match[4]
  return null
}
