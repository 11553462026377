<template>
  <div class="combobox">
    <span
      :id="comboboxElementIds.label"
      class="combobox__label"
      :class="{ 'combobox__label--visually-hidden': !showLabel }"
    >
      {{ label }}
    </span>
    <button
      v-if="readOnly"
      type="button"
      ref="comboboxTrigger"
      aria-haspopup="listbox"
      :id="comboboxElementIds.btn"
      :class="[
        'combobox-btn d-flex justify-content-between align-items-center px-3 py-2 br-4 text-base',
        !selectedValue ? 'placeholder' : 'font-bold',
        needsAttention && 'needs-attention',
      ]"
      :aria-labelledby="toggleLabelledById"
      :aria-expanded="isExpanded"
      @blur="handleComboboxTargetBlur"
      @click.stop="toggleOptions"
      @keyup="handleKeyUp"
    >
      <span style="margin-top: 3px">
        {{ selectedValue ? selectedValue : emptyOptionLabel }}
      </span>
      <inline-svg :src="getIconUrl(iconName)" class="ml-1" />
    </button>

    <div
      v-else
      :class="[
        'combobox-input-wrapper d-flex justify-content-between align-items-center px-3 py-2 pointer',
        needsAttention && 'needs-attention',
      ]"
      role="combobox"
      :aria-expanded="isExpanded"
      :aria-owns="`${name}-combobox-options-list`"
      aria-haspopup="listbox"
      @click.stop="triggerComboboxInput"
    >
      <input
        type="text"
        name="market"
        autocomplete="off"
        ref="comboboxTrigger"
        aria-labelledby="toggleLabelledById"
        class="combobox-input font-sub-header pointer"
        v-model="displayValue"
        :placeholder="emptyOptionLabel"
        :id="comboboxElementIds.btn"
        :aria-controls="`${name}-combobox-options-list`"
        style="margin-top: 3px"
        @blur="handleComboboxTargetBlur"
        @input="handleInput"
        @keyup="handleKeyUp"
      />
      <inline-svg :src="getIconUrl(iconName)" class="ml-1" />
    </div>
    <div>
      <ComboboxOptions
        ref="optionsList"
        v-if="options && (!isDesktop || isExpanded)"
        :name="name"
        :layout="popupType"
        :value="displayValue"
        :close="hideOptions"
        :valueKey="valueKey"
        :options="readOnly ? options : availableOptions"
        :isReadOnly="readOnly"
        :label="`${name}-label`"
        :visibleRows="visibleOptionsRows"
        :maxColmuns="maxOptionsColumns"
        :selectOption="updateSelectedOption"
        :triggerRef="$refs.comboboxTrigger"
      >
        <template v-for="(_, name) in $scopedSlots" v-slot:[name]="optionData">
          <slot :name="name" v-bind="optionData"></slot>
        </template>
      </ComboboxOptions>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Combobox',
  components: {
    ComboboxOptions: () => import('@/components/Shared/Combobox/ComboboxOptions.vue'),
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    selectedValue: {
      type: String,
    },
    valueKey: {
      type: String,
      default: 'value',
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    emptyOptionLabel: {
      type: String,
      default: 'Choose a value',
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    needsAttention: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: 'chevron-down',
    },
    selectOptionHandler: {
      type: Function,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    popupType: {
      type: String,
      default: 'list',
      validator: (type) => {
        return type === 'list' || type === 'grid'
      },
    },
    visibleOptionsRows: {
      type: Number,
    },
    maxOptionsColumns: {
      type: Number,
    },
  },
  data() {
    return {
      isExpanded: false,
      initialValue: this.selectedValue,
      isComboboxOptionsFirstRender: true,
    }
  },
  watch: {
    selectedValue(selectedValue) {
      // Ensures proper initial population for input style component
      if (selectedValue && !this.initialValue) this.initialValue = selectedValue
    },
  },
  computed: {
    ...mapGetters(['isDesktop']),
    displayValue: {
      get() {
        return this.initialValue
      },
      set(newValue) {
        this.$emit('onChange', newValue)
        this.initialValue = newValue
      },
    },
    availableOptions() {
      // Only filter if we have a value to search and it's not the first render of ComboboxOptions
      if (!this.displayValue || this.isComboboxOptionsFirstRender) return this.options
      try {
        // Match part of any option(s) and return those...
        const matchingOptions = this.options.filter((option) =>
          // Need to use RegExp to cast a variable, also case-insensitive
          option[this.valueKey]?.match(new RegExp(this.displayValue, 'ig'))
        )
        // If they've filtered out everything, show the whole list
        return matchingOptions.length ? matchingOptions : this.options
      } catch (ignoredError) {
        // If something went wrong, just show the whole list
        return this.options
      }
    },
    comboboxElementIds() {
      return {
        btn: `${this.name}-combobox-btn`,
        input: `${this.name}-combobox-input`,
        label: `${this.name}-combobox-label`,
      }
    },
    toggleLabelledById() {
      return `${this.comboboxElementIds.btn} ${this.comboboxElementIds.label}`
    },
  },
  methods: {
    getIconUrl() {
      return require(`@/assets/icons/fnd/${this.iconName}.svg`)
    },
    handleInput() {
      this.isComboboxOptionsFirstRender = false
    },
    handleKeyUp(evt) {
      const key = evt.key
      // If the options list is expanded, prevent event handling
      if (this.isExpanded) return

      switch (key) {
        case 'Tab':
          if (this.isExpanded) this.toggleOptions()
          break
        case 'Enter':
          evt.preventDefault()
          this.showOptions()
          break
        case 'Shift':
        case 'Escape':
          evt.preventDefault()
          break
        default:
          this.showOptions()
          break
      }
    },
    triggerComboboxInput() {
      this.showOptions()
      this.$refs.comboboxTrigger.focus()
    },
    handleComboboxTargetBlur(evt) {
      let nextTarget = evt.relatedTarget
      if (nextTarget) {
        if (nextTarget.id !== `${this.name}-combobox-options-list`) {
          this.hideOptions()
        }
      }
    },
    updateSelectedOption(value) {
      const currentFilter = this.displayValue
      this.displayValue = value
      this.selectOptionHandler(value, currentFilter)
      this.$refs.comboboxTrigger.focus()
      setTimeout(() => {
        this.hideOptions()
      }, 300)
    },
    toggleOptions() {
      this.isExpanded ? this.hideOptions() : this.showOptions()
    },
    showOptions() {
      this.isExpanded = true
      this.$emit('onToggle', this.isExpanded)
    },
    hideOptions() {
      this.isExpanded = false
      this.$emit('onToggle', this.isExpanded)
    },
  },
}
</script>

<style lang="scss">
.combobox {
  .combobox-input-wrapper {
    height: 48px;
    border: 1.5px solid $charcoal-20;
    border-radius: 4px;
    transition: border-color 0.25s ease;
    background: white;

    &.needs-attention {
      border-color: $salmon-60;
    }

    &:focus-within {
      border-color: $blue-60;
    }

    > .combobox-input {
      border: none;
      background: white;
      &:focus {
        color: $blue-60;
      }
    }
  }
  .combobox-btn {
    height: 48px;
    width: 100%;
    background: white;
    border: 1.5px solid $charcoal-20;
    white-space: nowrap;
    transition: border-color 0.25s ease;
    &[aria-expanded='true'] {
      color: $blue-60;
      border-color: $blue-60;
    }

    // TODO: Decide if we want a highlighted error state
    &.needs-attention {
      border-color: $salmon-60;
    }
    &:focus {
      color: $blue-60;
      border-color: $blue-60;
    }
    &.placeholder {
      color: $charcoal-30;
    }
  }
  .combobox__label--visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }

  @mixin comboboxPlaceholder {
    color: $charcoal-30;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    opacity: 1;
    transition: opacity 0.35s ease-in-out;
  }

  /* Place Holder CSS */
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    @include comboboxPlaceholder();
  }
  ::-moz-placeholder {
    /* Mozilla Firefox */
    @include comboboxPlaceholder();
  }
}
</style>
