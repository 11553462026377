<template>
  <form
    id="mirage-server-modal"
    class="grid-enabled pt-5 large-modal"
    @submit.prevent="saveMirageConfigHandler"
  >
    <div v-if="!mirageServerIsActive" class="go-back" @click="goToEntitySelection">
      <img src="@/assets/icons/arrow-left.svg" />
    </div>
    <span class="modalLead lead text-center mb-4">Mirage UI Testing</span>
    <span class="modalHeader header-md">2. Configure your mock data</span>
    <div class="close-modal" @click="$root.$emit('closeModal')">
      <img src="@/assets/icons/close.svg" />
    </div>
    <b-container class="modalContent mt-5 mb-3 w-100">
      <b-row class="mt-3">
        <b-col sm="12" md="6">
          <div class="input-group mb-3 pointer" @click="toggleVerifiedIdentity">
            <div class="input-group-prepend">
              <div class="input-group-text pointer">
                <input
                  type="checkbox"
                  class="pointer"
                  :checked="mirageConfigClone.homeowner.identity.is_verified"
                  name="verified-identity"
                  :value="true"
                  :unchecked-value="false"
                />
              </div>
            </div>
            <div class="form-control">Identity Verified</div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="input-group mb-3 pointer" @click="toggleAdditionalInfoNeeded">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  type="checkbox"
                  class="pointer"
                  :checked="mirageConfigClone.homeowner.identity.requirements.length"
                  name="additional-info-needed"
                  :value="true"
                  :unchecked-value="false"
                />
              </div>
            </div>
            <div class="form-control">Additional Info Needed</div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col sm="12" md="6">
          <div class="input-group mb-3 pointer" @click="togglePayoutMethod">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  type="checkbox"
                  class="pointer"
                  :checked="mirageConfigClone.homeowner.payoutMethods.length"
                  name="payout-methods"
                  :value="true"
                  :unchecked-value="false"
                />
              </div>
            </div>
            <div class="form-control">Add Payout Method</div>
          </div>
        </b-col>
      </b-row>
      <b-row
        class="mt-3"
        v-for="(statementLine, i) in mirageConfigClone.homeowner.statementLines"
        :key="i"
      >
        <b-col>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <div class="input-group-text pointer">
                <select required name="statement-line-type" v-model="statementLine.type">
                  <option value="">Type</option>
                  <option value="charge">Charge</option>
                  <option value="payout">Payout</option>
                  <option value="credit">Credit</option>
                </select>
              </div>
            </div>
            <div class="form-control">Statement Line</div>
            <div class="input-group-append">
              <input
                @click="focusInput"
                v-model="statementLine.amount"
                type="number"
                min="0"
                step="0.01"
                placeholder="0.00"
                class="input-group-text bg-white future-charge-amount"
              />
            </div>
            <div class="input-group-append" @click="deleteStatementLine(i)">
              <div class="input-group-text pointer">x</div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col sm="12" md="6" @click="addStatementLine">
          <div class="input-group mb-3 pointer">
            <div class="input-group-prepend">
              <span class="input-group-text">+</span>
            </div>
            <div class="form-control">Add Statement Line</div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-row class="modalFooter">
      <b-col class="text-center mb-4" md="10" offset-md="1">
        <div v-if="mirageServerIsActive">
          {{ actionsInfoText }}
        </div>
      </b-col>
      <b-col md="4" sm="12" class="order-2 order-md-1 mt-2">
        <b-btn block @click="cancelOrShutdownHandler" :variant="cancelOrShutdownButtonVariant">
          {{ cancelOrShutdownButtonText }}
        </b-btn>
      </b-col>
      <b-col md="8" sm="12" class="order-1 order-md-2 mt-2">
        <b-btn :variant="submitButtonVariant" block type="submit">
          {{ submitButtonText }}
        </b-btn>
      </b-col>
    </b-row>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { cloneDeep } from 'lodash-es'
import MirageEntitySelectionModal from './MirageEntitySelectionModal.vue'

export default {
  name: 'MirageHomeownerConfigModal',
  data() {
    return {
      mirageConfigClone: null,
    }
  },
  beforeMount() {
    this.mirageConfigClone = cloneDeep(this.mirageConfig)
    this.mirageConfigClone.userConfig.isHomeowner = true
  },
  computed: {
    ...mapGetters('mirage', ['mirageConfig']),
    mirageServerIsActive() {
      return this.mirageConfigClone.active
    },
    actionsInfoText() {
      return `${
        this.mirageServerIsActive
          ? "Restarting the server will clear any data you've created and"
          : 'Starting the server will'
      } take you to the Homeowner billing page`
    },
    submitButtonVariant() {
      return this.mirageServerIsActive ? 'secondary' : 'success'
    },
    submitButtonText() {
      return this.mirageServerIsActive ? 'Restart Server' : 'Start Server'
    },
    cancelOrShutdownButtonVariant() {
      return this.mirageServerIsActive ? 'danger' : 'light'
    },
    cancelOrShutdownButtonText() {
      return this.mirageServerIsActive ? 'Shutdown' : 'Cancel'
    },
  },
  methods: {
    ...mapActions('mirage', ['updateMirageConfig', 'resetMirage']),
    toggleAdditionalInfoNeeded() {
      if (!this.mirageConfigClone.homeowner.identity.requirements.length) {
        this.mirageConfigClone.homeowner.identity.is_verified = false
        this.mirageConfigClone.homeowner.identity.requirements = ['ssn']
      } else {
        this.mirageConfigClone.homeowner.identity.requirements = []
      }
    },
    focusInput(e) {
      e.target.select()
    },
    addStatementLine() {
      this.mirageConfigClone.homeowner.statementLines.push({
        description: 'Charge',
        amount: '200.00',
        type: 'charge',
        posted_at: '2019-10-01T07:00:00Z',
        address: '123 Rainbow Road',
      })
    },
    deleteStatementLine(index) {
      this.mirageConfigClone.homeowner.statementLines.splice(index, 1)
    },
    goToEntitySelection() {
      this.$root.$emit('openModal', { modalComponent: MirageEntitySelectionModal })
    },
    toggleVerifiedIdentity() {
      this.mirageConfigClone.homeowner.identity.requirements = []
      this.mirageConfigClone.homeowner.identity.is_verified =
        !this.mirageConfigClone.homeowner.identity.is_verified
    },
    togglePayoutMethod() {
      if (this.mirageConfigClone.homeowner.payoutMethods.length) {
        this.mirageConfigClone.homeowner.payoutMethods = []
      } else {
        this.createPayoutMethod()
      }
    },
    createPayoutMethod() {
      this.mirageConfigClone.homeowner.payoutMethods = [
        {
          is_default: true,
          created_at: moment(),
          provider_data: {
            bank_name: 'Bank of Bungalow',
            last4: '1234',
          },
        },
      ]
    },
    cancelOrShutdownHandler() {
      if (this.mirageServerIsActive) {
        this.resetMirage()
      }
      this.$root.$emit('closeModal')
    },
    async saveMirageConfigHandler() {
      this.mirageConfigClone.active = true
      this.updateMirageConfig(this.mirageConfigClone)
      this.$root.$emit('closeModal')
    },
  },
}
</script>

<style lang="scss">
#mirage-server-modal {
  .future-charge-amount {
    width: 6.25rem;
  }
}
</style>
