<template>
  <appear>
    <div :class="['loading-container', { fullscreen, inline }]">
      <div :class="['spinner-border', { small, large, white, dark, salmon }]" />
      <span class="sr-only">Loading...</span>
    </div>
  </appear>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    salmon: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
$loading-spinner-width: 75px;
$loading-spinner-height: 75px;
$small-loading-spinner-width: 22px;
$small-loading-spinner-height: 22px;
$large-loading-spinner-width: 120px;
$large-loading-spinner-height: 120px;

.loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.fullscreen {
    background: white;
    height: calc(100vh - #{$default-navbar-height});

    @include mobile {
      height: calc(100vh - #{$mobile-navbar-height});
    }
  }

  &.inline {
    height: 80vh;
  }

  .spinner-border {
    color: $blue-60;
    height: $loading-spinner-width;
    width: $loading-spinner-width;
    border-width: 5px;

    &.small {
      border-width: 2px;
      height: $small-loading-spinner-width;
      width: $small-loading-spinner-width;
    }

    &.large {
      border-width: 2px;
      height: $large-loading-spinner-width;
      width: $large-loading-spinner-width;
    }

    &.white {
      color: white;
    }

    &.dark {
      color: $charcoal-60;
    }

    &.salmon {
      color: $salmon-60;
    }
  }
}
// Handle hidden nav /w fullscreen loader:
.hide-nav {
  .loading-container {
    &.fullscreen {
      height: calc(100vh - #{$default-navbar-height}) !important;

      @include mobile {
        height: calc(100vh - #{$mobile-navbar-height}) !important;
      }
    }
  }
}
</style>
