export const homeTypes = [
  {
    key: 'group_living',
    route: 'homes-for-rent',
    img: 'https://assets.bungalow.com/navigation/entire-home.png',
    title: 'Entire Home',
    subtitle: 'Have the whole place to yourself or your group',
  },
  {
    key: 'co_living',
    route: 'rooms-for-rent',
    img: 'https://assets.bungalow.com/navigation/shared-home.png',
    title: 'Shared Home',
    subtitle: 'Have a private room and share common spaces',
  },
]

export const marketSelectorLocations = Object.freeze({
  HEADER: 'header',
  HOME_PAGE: 'home-page',
  MODAL: 'modal',
  MOBILE: 'mobile',
  FLOATING: 'floating',
})

export const focusedRoutes = [
  'MemberFunnel',
  'BookATour',
  'MyToursLogin',
  'MyTours',
  'BookedTour',
  'ManageTour',
]
