import { getField, updateField } from 'vuex-map-fields'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'
import { getTimeBetweenDates } from '@/utils/dates'
import { MEETING_STATUSES } from '@/constants/chat'

const getDefaultState = () => ({
  ...stateHelpers,
  chats: {
    count: 0,
    previous: '',
    next: '',
    results: [],
  },
  acceptedInvites: {
    count: 0,
    previous: '',
    next: '',
    results: [],
  },
  chatsWithUnreadMessages: [],
  sendbirdInitialized: false,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  getField,
  chats: ({ chats: { results } }, getters) =>
    results.map((chat) => ({
      ...chat,
      _acceptedInvite: getters.getAcceptedInviteForMeeting(chat.id),
      _timeRemaining:
        chat.status === MEETING_STATUSES.open
          ? getTimeBetweenDates(chat.endsAt).hours <= 48
            ? getTimeBetweenDates(chat.endsAt)
            : null
          : null,
    })),
  chatCount: ({ sendbirdInitialized }, getters) => sendbirdInitialized && getters.chats.length,
  unreadChats: ({ chatsWithUnreadMessages }) => !!chatsWithUnreadMessages.length,
  getAcceptedInviteForMeeting:
    ({ acceptedInvites: { results } }) =>
    (meetingId) =>
      results.find(({ meeting }) => meeting === meetingId),
  getChatForProperty:
    ({ chats: { results } }) =>
    (propertyId) =>
      results.find(({ property: { id } }) => id === propertyId),
  propertyChat: ({ chats: { results } }, getters, rootState, rootGetters) => {
    const property = rootGetters['property/property']
    return results.find(({ property: { id } }) => id === property?.id)
  },
}

const actions = {
  async fetchOpenChats({ commit }) {
    try {
      const chatEnabledApplicationStatuses = [
        'pre_meet_and_greet',
        'open',
        'submitted',
        'submitted_request_pending',
        'preapproved',
        'preapproved_roommate_pending',
        'approved',
      ]
      commit('setLoading', true)
      commit('setError', false)
      const { data } = await this._vm.$http.get(
        `${
          apiPaths.chats.myChats
        }?is_applicant=true&all_meetings=true&${chatEnabledApplicationStatuses
          .map((status) => `application_status=${status}`)
          .join('&')}`
      )
      commit('setChats', data)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },

  async fetchAcceptedInvites({ commit }) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const { data } = await this._vm.$http.get(apiPaths.chats.acceptInvite)
      commit('setAcceptedInvites', data)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
  /**
   *  If a chat already exists the api will still succeed
   * @param {String} property_id
   */
  async createChatForProperty({ commit }, property_id) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      await this._vm.$http.post(apiPaths.chats.myChats, {
        property_id,
      })
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
  /**
   * @param {
   *  channel_id: *Channel which to send this message*,
   *  message: *Text message to send*
   * } payload
   */
  async sendPendingMessage({ commit }, payload) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      await this._vm.$http.post(apiPaths.chats.pendingMessage, payload)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
  /**
   *  Posting to this api will automatically transition the application to open
   *  and create a soft room lock for the specified room
   * @param {
   *  application: *id of application*
   *  meeting: meeting id
   * } payload
   */
  async acceptPropertyChatInvite({ commit }, payload) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const { data } = await this._vm.$http.post(apiPaths.chats.acceptInvite, payload)
      return data
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
  /**
   *  Posting to this api will automatically transition the meeting to applicant_left,
   *  deactivate all room selections for the applicant for that home
   * @param {
   *  application: *id of application*
   *  meeting: meeting id
   * } payload
   */
  async declinePropertyChatInvite({ commit }, payload) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      await this._vm.$http.post(apiPaths.chats.declineMeeting, payload)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
}

const mutations = {
  ...mutationHelpers,
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setChats(state, payload) {
    state.chats = payload
  },
  setAcceptedInvites(state, payload) {
    state.acceptedInvites = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
