// Funnel Flags
export const AUTOMATED_TOURS = 'automated_tours'
export const PERSONA_ENABLED = 'persona_enabled'
export const HOUSE_PREFERENCES = 'house_roommate_preferences'
export const PRIORITIZED_CHATS = 'funnel20PrioritiesChats'
export const SMS_POWERED_CHATS = 'sms_powered_chat'
export const SHOWDIGS_ENABLED = 'showdigs_enabled'
export const MOUNTAIN_ENABLED = 'mountain_enabled'

// Appfolio Transition Feature Flags
export const BW_APPFOLIO_PHASE_ONE = 'appfolioTransitionPhaseOne'
export const BW_APPFOLIO_PHASE_TWO = 'appfolioTransitionPhaseTwo'
