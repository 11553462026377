import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

extend('optional', {
  validate() {
    return true
  },
})

extend('required', {
  ...required,
  message: 'Required',
})

extend('email', {
  ...email,
  message: 'Should be a valid email address',
})

extend('name', {
  validate: (value) => !!value.match(/^[a-z\d\-\s\u00E0-\u00FCß]+$/i),
  message: 'Can only contain letters, numbers, spaces and dashes',
})

extend('numeric', {
  validate(value) {
    const hasDigitsOnly = new RegExp(/^\d*$/)
    return hasDigitsOnly.test(value)
  },
  message: 'Only numbers please!',
})

extend('length', {
  validate(value, { length }) {
    return value.length == length
  },
  params: ['length'],
  message: 'Needs to be exactly {length} characters',
})

extend('min', {
  validate(value, { length }) {
    return value.length >= length
  },
  params: ['length'],
  message: 'Must have at least {length} characters',
})

extend('max', {
  validate(value, { length }) {
    return value.length <= length
  },
  params: ['length'],
  message: 'Must not have more than {length} characters',
})

extend('minmax', {
  validate(value, { min, max }) {
    return value.length >= min && value.length <= max
  },
  params: ['min', 'max'],
  message: 'Must have at least {min} characters and {max} at most',
})

extend('min-num', {
  validate(value, { min }) {
    return value >= min
  },
  params: ['min'],
  message: 'Must be greater than or equal to {min}',
})

extend('phone', {
  validate(value, { phoneNumber = {} }) {
    return phoneNumber.isValid
  },
  params: ['phoneNumber'],
  message: 'Should be a valid valid phone number',
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
