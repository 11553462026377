var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"combobox"},[_c('span',{staticClass:"combobox__label",class:{ 'combobox__label--visually-hidden': !_vm.showLabel },attrs:{"id":_vm.comboboxElementIds.label}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.readOnly)?_c('button',{ref:"comboboxTrigger",class:[
      'combobox-btn d-flex justify-content-between align-items-center px-3 py-2 br-4 text-base',
      !_vm.selectedValue ? 'placeholder' : 'font-bold',
      _vm.needsAttention && 'needs-attention',
    ],attrs:{"type":"button","aria-haspopup":"listbox","id":_vm.comboboxElementIds.btn,"aria-labelledby":_vm.toggleLabelledById,"aria-expanded":_vm.isExpanded},on:{"blur":_vm.handleComboboxTargetBlur,"click":function($event){$event.stopPropagation();return _vm.toggleOptions.apply(null, arguments)},"keyup":_vm.handleKeyUp}},[_c('span',{staticStyle:{"margin-top":"3px"}},[_vm._v(" "+_vm._s(_vm.selectedValue ? _vm.selectedValue : _vm.emptyOptionLabel)+" ")]),_c('inline-svg',{staticClass:"ml-1",attrs:{"src":_vm.getIconUrl(_vm.iconName)}})],1):_c('div',{class:[
      'combobox-input-wrapper d-flex justify-content-between align-items-center px-3 py-2 pointer',
      _vm.needsAttention && 'needs-attention',
    ],attrs:{"role":"combobox","aria-expanded":_vm.isExpanded,"aria-owns":`${_vm.name}-combobox-options-list`,"aria-haspopup":"listbox"},on:{"click":function($event){$event.stopPropagation();return _vm.triggerComboboxInput.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.displayValue),expression:"displayValue"}],ref:"comboboxTrigger",staticClass:"combobox-input font-sub-header pointer",staticStyle:{"margin-top":"3px"},attrs:{"type":"text","name":"market","autocomplete":"off","aria-labelledby":"toggleLabelledById","placeholder":_vm.emptyOptionLabel,"id":_vm.comboboxElementIds.btn,"aria-controls":`${_vm.name}-combobox-options-list`},domProps:{"value":(_vm.displayValue)},on:{"blur":_vm.handleComboboxTargetBlur,"input":[function($event){if($event.target.composing)return;_vm.displayValue=$event.target.value},_vm.handleInput],"keyup":_vm.handleKeyUp}}),_c('inline-svg',{staticClass:"ml-1",attrs:{"src":_vm.getIconUrl(_vm.iconName)}})],1),_c('div',[(_vm.options && (!_vm.isDesktop || _vm.isExpanded))?_c('ComboboxOptions',{ref:"optionsList",attrs:{"name":_vm.name,"layout":_vm.popupType,"value":_vm.displayValue,"close":_vm.hideOptions,"valueKey":_vm.valueKey,"options":_vm.readOnly ? _vm.options : _vm.availableOptions,"isReadOnly":_vm.readOnly,"label":`${_vm.name}-label`,"visibleRows":_vm.visibleOptionsRows,"maxColmuns":_vm.maxOptionsColumns,"selectOption":_vm.updateSelectedOption,"triggerRef":_vm.$refs.comboboxTrigger},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(optionData){return [_vm._t(name,null,null,optionData)]}}})],null,true)}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }