import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  issueAttachments: {
    results: [],
    count: 0,
    nextUrl: '',
    previousUrl: '',
  },
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  issueAttachments: ({ issueAttachments: { results } }) => results,
  count: ({ issueAttachments: { count } }) => count,
  nextUrl: ({ issueAttachments: { next } }) => next,
  previousUrl: ({ issueAttachments: { previous } }) => previous,
}

const actions = {
  fetchIssueAttachments({ commit }, query) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(apiPaths.maintenance.issueAttachments(query))
      .then(({ data }) => commit('setIssueAttachments', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  resetIssueAttachments({ commit }) {
    commit('resetState')
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setIssueAttachments(state, payload) {
    state.issueAttachments = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
