<template>
  <div class="emoji-tag tag-border">
    <b v-if="isTagMapped">{{ emojiTags[tag] }}</b>
    <b v-else-if="ageRange">{{ ageRange }} yrs old ✅</b>
    <b v-else-if="fromBackend">{{ tag }}</b>
  </div>
</template>
<script>
export default {
  name: 'EmojiTag',
  data() {
    return {
      emojiTags: {
        Beach: 'Beach 🏖️',
        'Board Games': 'Board games 🎲',
        Coffee: 'Coffee ☕',
        Comics: 'Comics 💥',
        Concerts: 'Music 🎧',
        Cooking: 'Home cook 👨🏽‍🍳',
        'Crafts/DIY': 'Crafts ✂️',
        Dancing: 'Dancing 💃',
        'Exploring The City': 'Exploring the city 🌆',
        Fitness: 'Fitness 🏃🏽‍♂️',
        Hiking: 'Hiking 🥾',
        'Hosting People': 'Hosting people 🍸',
        'Ice Cream': 'Ice cream 🍦',
        Movies: 'Movies 🎞️',
        Nightlife: 'Nightlife 🍾',
        Outdoors: 'Outdoors ⛺',
        Politics: 'Politics 🗳️',
        Reading: 'Reading 📖',
        Restaurants: 'Food and drink 🍣',
        Running: 'Fitness 🏃🏽‍♂️',
        Shopping: 'Shopping 🛍️',
        'Skiing/Snowboarding': 'Skiing ⛷️',
        Sports: 'Sports 🏟️',
        Television: 'Television 📺',
        Theatre: 'Theater 🎭',
        Traveling: 'Travel ✈️',
        'Video Games': 'Gaming 🕹️',
        Volunteering: 'Volunteering 🤝',
        'Watching Sports': 'Watching sports 🎫',
        Yoga: 'Yoga 🧘‍♂️',
      },
    }
  },
  computed: {
    isTagMapped() {
      return !!this.emojiTags[this.tag]
    },
  },
  props: {
    tag: {
      type: String,
    },
    ageRange: {
      type: String,
    },
    fromBackend: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss">
.emoji-tag {
  border: 1px solid #f2f1f1;
  border-radius: 8px;
  max-width: max-content;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 2px;
  b {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 10px;
    margin-bottom: 12px;
    white-space: nowrap;
    font-size: 12px;
  }
}
</style>
