<template>
  <div class="login-page">
    <div v-if="showBackButton" class="login-page__back" @click="goBack">
      <inline-svg :src="require('@/assets/icons/fnd/close.svg')" />
    </div>
    <appear v-if="loginPending">
      <div class="login-page__overlay" />
    </appear>
    <b-container>
      <div class="login-page__inner">
        <b-row class="login-page__logo">
          <router-link to="/">
            <img src="https://assets.bungalow.com/logos/bungalow_logo_dark.svg" />
          </router-link>
        </b-row>
        <b-row class="login-page__illustration">
          <img src="https://assets.bungalow.com/illustrations/full-color/five-plants.png" />
        </b-row>
        <p v-if="previousRouteIsResident" class="font-small mx-auto text-center" v-limit="312">
          Please <strong>login below</strong> to submit a ticket or speak with a Bungalow
          representative via live chat 💬
        </p>
        <LoginButtons :onLogin="redirectToNextRoute" :location="$options.name" />
      </div>
      <div
        v-if="previousRouteIsResident"
        :class="[
          'd-flex align-items-center font-small font-bold mx-auto',
          isMobile ? 'mt-3' : 'mt-5',
        ]"
      >
        <inline-svg class="mr-1" :src="require('@/assets/icons/fnd/info.svg')" />
        <div @click="viewHelpModal">
          <span class="blue-80--txt pointer"> Trouble logging in and need assistance? </span>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginButtons from '@/components/Auth/LoginButtons.vue'
import LoginIssueHelpModalVue from '../Modals/Shared/LoginIssueHelpModal.vue'

export default {
  name: 'LoginPage',
  components: {
    LoginButtons,
  },
  computed: {
    ...mapGetters(['isMobile']),
    ...mapGetters('user', ['isLoggedIn', 'loginPending']),
    // Only offer resident help for relevant pages/experiences
    previousRouteIsResident() {
      const {
        query: { next = '' },
      } = this.$route
      return next.includes('/resident/')
    },
    showBackButton() {
      return this.$route.query.hide_back_button !== 'true'
    },
  },
  methods: {
    viewHelpModal() {
      this.$root.$emit('openModal', { modalComponent: LoginIssueHelpModalVue })
    },
    goBack() {
      const {
        query: { from = '' },
      } = this.$route
      from.includes('https://')
        ? window.location.replace(from)
        : this.$router.replace(from || '/').catch(() => {})
    },
    redirectToNextRoute() {
      // Check if a follow-through page was specified...
      const urlParams = new URLSearchParams(window.location.search)
      const nextRoute = urlParams.get('next')

      // If we have a destination, navigate there:
      if (nextRoute) {
        /* NOTE: Typically nextRoute will be a local route, however in the case that
          it's a external url ( apply.bungalow.com ), do a full window replace */
        return nextRoute.includes('https://')
          ? window.location.replace(nextRoute)
          : this.$router.replace(nextRoute).catch(() => {})
      }
    },
  },
}
</script>

<style lang="scss">
.login-page {
  height: 100vh;
  display: grid;
  place-items: center;

  &__back {
    @include closeButton;
    right: 2rem;
    top: 2rem;
  }

  &__overlay {
    display: block;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    pointer-events: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    padding-top: 84px;
    padding-bottom: 40px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  &__logo {
    width: 165px;

    @include mobile {
      margin: 0 auto 1.5rem auto;
    }
  }

  &__illustration {
    margin: 3rem auto;

    img {
      height: 200px;
      width: 240px;
      object-fit: contain;

      @include mobile {
        height: 160px;
        width: 180px;
      }
    }

    @include mobile {
      margin: 0 auto 1rem auto;
    }

    @media (max-width: 350px) {
      display: none;
    }
  }
}
</style>
