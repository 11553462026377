<template>
  <form
    id="mirage-server-modal"
    class="grid-enabled pt-5 large-modal"
    @submit.prevent="saveMirageConfigHandler"
  >
    <div v-if="!mirageServerIsActive" class="go-back" @click="goToEntitySelection">
      <img src="@/assets/icons/arrow-left.svg" />
    </div>
    <span class="modalLead lead text-center mb-4">Mirage UI Testing</span>
    <span class="modalHeader header-md">{{ modalHeaderText }}</span>
    <div class="close-modal" @click="$root.$emit('closeModal')">
      <img src="@/assets/icons/close.svg" />
    </div>
    <b-container class="modalContent mt-5 mb-3 w-100">
      <div>
        <b-row>
          <b-col md="8" offset-md="2" class="mb-4 pb-1">
            <b-dropdown class="w-100" text="Pre-configured options" variant="dark">
              <b-dropdown-item
                v-for="item in preConfiguredOptions"
                :key="item.title"
                class="mt-3 mb-2"
                @click="item.onClickHandler"
              >
                {{ item.title }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6" md="4" @click="focusInput('totalChargesOwedImmediately')">
            <b-form-group>
              <label for="totalChargesOwedImmediately">One Time Charges</label>
              <input
                ref="totalChargesOwedImmediately"
                v-model="mirageConfigClone.resident.balance.total_charges_owed_immediately"
                type="number"
                @change="convertToDecimal('total_charges_owed_immediately')"
                min="0"
                step="0.01"
                placeholder="0.00"
                class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6" md="4" @click="focusInput('totalChargesOwed')">
            <b-form-group>
              <label for="totalChargesOwed">Total Owed</label>
              <input
                ref="totalChargesOwed"
                v-model="mirageConfigClone.resident.balance.total_charges_owed"
                type="number"
                @change="convertToDecimal('total_charges_owed')"
                min="0"
                step="0.01"
                placeholder="0.00"
                class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6" md="4" @click="focusInput('totalPrepaid')">
            <b-form-group>
              <label for="totalPrepaid">Total Prepaid</label>
              <input
                ref="totalPrepaid"
                v-model="mirageConfigClone.resident.balance.total_prepaid"
                type="number"
                @change="convertToDecimal('total_prepaid')"
                min="0"
                step="0.01"
                placeholder="0.00"
                class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6" md="4" @click="focusInput('invitation')">
            <b-form-group>
              <label for="invitation">Invitation Slug</label>
              <input
                ref="invitation"
                v-model="mirageConfigClone.resident.invitation"
                placeholder="None"
                class="form-control"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <div class="input-group-text pointer" @click="toggleFutureCharges">
                  <input
                    class="pointer"
                    id="future-charges"
                    type="checkbox"
                    :checked="mirageConfigClone.resident.futureCharges.length"
                    name="future-charges"
                    :value="true"
                    :unchecked-value="false"
                  />
                </div>
              </div>
              <div class="form-control h-max-content">Add upcoming charge</div>
              <div class="input-group-append">
                <input
                  @click="focusInput('futureChargeAmount')"
                  ref="futureChargeAmount"
                  v-if="mirageConfigClone.resident.futureCharges.length"
                  v-model="mirageConfigClone.resident.futureCharges[0].amount"
                  @change="formatFutureChargeAmount"
                  type="number"
                  min="0"
                  step="0.01"
                  placeholder="0.00"
                  class="input-group-text bg-white future-charge-amount"
                />
                <span v-else class="input-group-text">0.00</span>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col sm="12" md="6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <div class="input-group-text pointer" @click="togglePaymentMethod">
                  <input
                    type="checkbox"
                    class="pointer"
                    :checked="mirageConfigClone.resident.paymentMethods.length"
                    name="payment-methods"
                    :value="true"
                    :unchecked-value="false"
                  />
                </div>
              </div>
              <div class="form-control">Add payment method</div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <div class="input-group-text pointer" @click="toggleUseCreditCard">
                  <input
                    type="checkbox"
                    class="pointer"
                    :checked="mirageConfigClone.resident.useCreditCard"
                    name="payment-methods"
                    :value="true"
                    :unchecked-value="false"
                  />
                </div>
              </div>
              <div class="form-control">Use credit card</div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <div class="input-group-text pointer" @click="toggleThrowPaymentError">
                  <input
                    type="checkbox"
                    class="pointer"
                    :checked="mirageConfigClone.resident.throwPaymentError"
                    name="payment-methods"
                    :value="true"
                    :unchecked-value="false"
                  />
                </div>
              </div>
              <div class="form-control">Throw Payment Error</div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <div
                  class="input-group-text pointer"
                  @click="
                    mirageConfigClone.resident.productSettings.auto_pay_enabled =
                      !mirageConfigClone.resident.productSettings.auto_pay_enabled
                  "
                >
                  <input
                    type="checkbox"
                    class="pointer"
                    :checked="mirageConfigClone.resident.productSettings.auto_pay_enabled"
                    name="payment-methods"
                    :value="true"
                    :unchecked-value="false"
                  />
                </div>
              </div>
              <div class="form-control">AutoPay Enabled</div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <div class="input-group-text pointer" @click="toggleStatementLinesCharges">
                  <input
                    type="checkbox"
                    class="pointer"
                    :checked="mirageConfigClone.resident.statementLines.length"
                    name="statement-lines"
                    :value="true"
                    :unchecked-value="false"
                  />
                </div>
              </div>
              <div class="form-control">Add Statement Lines</div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-row class="modalFooter">
      <b-col class="text-center mb-4" md="10" offset-md="1">
        <div v-if="mirageServerIsActive">
          {{ residentActionsInfoText }}
        </div>
      </b-col>
      <b-col md="4" sm="12" class="order-2 order-md-1 mt-2">
        <b-btn block @click="cancelOrShutdownHandler" :variant="cancelOrShutdownButtonVariant">
          {{ cancelOrShutdownButtonText }}
        </b-btn>
      </b-col>
      <b-col md="8" sm="12" class="order-1 order-md-2 mt-2">
        <b-btn :variant="submitButtonVariant" block type="submit">
          {{ submitButtonText }}
        </b-btn>
      </b-col>
    </b-row>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { generateInitialMirageState } from '@/store/modules/mirage.js'
import moment from 'moment'
import { cloneDeep } from 'lodash-es'
import MirageEntitySelectionModal from './MirageEntitySelectionModal.vue'

export default {
  name: 'MirageResidentConfigModal',
  data() {
    return {
      mirageConfigClone: null,
      preConfiguredOptions: [
        {
          title: 'First Time Resident',
          onClickHandler: () => {
            this.resetResidentMirageConfig()
            this.mirageConfigClone.resident.balance = {
              total_charges_owed: '500.00',
              total_charges_owed_immediately: '500.00',
              total_prepaid: '0.00',
            }
          },
        },
        {
          title: 'First Time Pro-rated Resident',
          onClickHandler: () => {
            this.resetResidentMirageConfig()
            this.mirageConfigClone.resident.balance = {
              total_charges_owed: '1500.00',
              total_charges_owed_immediately: '500.00',
              total_prepaid: '0.00',
            }
          },
        },
        {
          title: 'Overdue Resident',
          onClickHandler: () => {
            this.resetResidentMirageConfig()
            this.mirageConfigClone.resident.balance = {
              total_charges_owed: '500.00',
              total_charges_owed_immediately: '0.00',
              total_prepaid: '0.00',
            }
            this.createPaymentMethod()
          },
        },
        {
          title: 'Upcoming Charge',
          onClickHandler: () => {
            this.resetResidentMirageConfig()
            this.mirageConfigClone.resident.balance = {
              total_charges_owed: '0.00',
              total_charges_owed_immediately: '0.00',
              total_prepaid: '0.00',
            }
            this.createFutureCharge()
            this.createPaymentMethod()
          },
        },
        {
          title: 'Pre-paid Resident',
          onClickHandler: () => {
            this.resetResidentMirageConfig()
            this.mirageConfigClone.resident.balance = {
              total_charges_owed: '0.00',
              total_charges_owed_immediately: '0.00',
              total_prepaid: '800.00',
            }
            this.createFutureCharge('800.00')
            this.createPaymentMethod()
          },
        },
      ],
    }
  },
  beforeMount() {
    this.mirageConfigClone = cloneDeep(this.mirageConfig)
    this.mirageConfigClone.userConfig.isResident = true
  },
  computed: {
    ...mapGetters('mirage', ['mirageConfig']),
    mirageServerIsActive() {
      return this.mirageConfigClone.active
    },
    modalHeaderText() {
      return '2. Configure your mock data'
    },
    residentActionsInfoText() {
      return `${
        this.mirageServerIsActive
          ? "Restarting the server will clear any data you've created and"
          : 'Starting the server will'
      } take you to the resident billing page`
    },
    submitButtonVariant() {
      return this.mirageServerIsActive ? 'secondary' : 'success'
    },
    submitButtonText() {
      return this.mirageServerIsActive ? 'Restart Server' : 'Start Server'
    },
    cancelOrShutdownButtonVariant() {
      return this.mirageServerIsActive ? 'danger' : 'light'
    },
    cancelOrShutdownButtonText() {
      return this.mirageServerIsActive ? 'Shutdown' : 'Cancel'
    },
  },
  methods: {
    ...mapActions('mirage', ['updateMirageConfig', 'resetMirage']),
    goToEntitySelection() {
      this.$root.$emit('openModal', { modalComponent: MirageEntitySelectionModal })
    },
    resetResidentMirageConfig() {
      this.mirageConfigClone.resident = cloneDeep(
        generateInitialMirageState()
      ).mirageConfig.resident
    },
    focusInput(inputRef) {
      this.$refs[inputRef] && this.$refs[inputRef].select()
    },
    convertToDecimal(field) {
      this.mirageConfigClone.resident.balance[field] = parseFloat(
        this.mirageConfigClone.resident.balance[field] || 0
      ).toFixed(2)
    },
    formatFutureChargeAmount(field) {
      this.mirageConfigClone.resident.futureCharges[0].amount = parseFloat(
        this.mirageConfigClone.resident.futureCharges[0].amount || 0
      ).toFixed(2)
    },
    togglePaymentMethod() {
      if (this.mirageConfigClone.resident.paymentMethods.length) {
        this.mirageConfigClone.resident.paymentMethods = []
      } else {
        this.createPaymentMethod()
      }
    },
    toggleUseCreditCard() {
      this.mirageConfigClone.resident.useCreditCard = !this.mirageConfigClone.resident.useCreditCard

      // re-create payment method if needed
      if (this.mirageConfigClone.resident.paymentMethods.length) {
        this.createPaymentMethod()
      }
    },
    toggleThrowPaymentError() {
      this.mirageConfigClone.resident.throwPaymentError =
        !this.mirageConfigClone.resident.throwPaymentError
    },
    createPaymentMethod() {
      this.mirageConfigClone.resident.paymentMethods = [
        {
          type: this.mirageConfigClone.resident.useCreditCard ? 'Visa' : 'bank_account',
          currency: 'usd',
          is_default: true,
          created_at: moment(),
          verified_at: this.mirageConfigClone.resident.useCreditCard ? moment() : null,
          provider_data: {
            bank_name: this.mirageConfigClone.resident.useCreditCard
              ? 'Credit of Bungalow'
              : 'Bank of Bungalow',
            last4: '1234',
            num_verification_attempts_remaining: 5,
          },
        },
      ]
    },
    toggleFutureCharges() {
      if (this.mirageConfigClone.resident.futureCharges.length) {
        this.mirageConfigClone.resident.futureCharges = []
      } else {
        this.createFutureCharge()
      }
      this.$nextTick(() => this.focusInput('futureChargeAmount'))
    },
    createFutureCharge(amount = '1500.00') {
      this.mirageConfigClone.resident.futureCharges = [
        {
          description: 'August Rent',
          amount,
          type: 'charge',
          posted_at: moment().add(1, 'M').startOf('month'),
        },
        {
          description: 'Cleaning Credit',
          amount: '200.00',
          type: 'credit',
          posted_at: moment().add(1, 'M').startOf('month'),
        },
      ]
    },
    toggleStatementLinesCharges() {
      if (this.mirageConfigClone.resident.statementLines.length) {
        this.mirageConfigClone.resident.statementLines = []
      } else {
        this.createStatementLines()
      }
    },
    createStatementLines() {
      this.mirageConfigClone.resident.statementLines = [
        {
          description: 'Utilities for June',
          amount: '3.83',
          posted_at: '2021-06-01T07:00:00Z',
          type: 'charge',
          category: 'utilities',
          data: {
            property_total: '13.87',
            occupancy_total: '3.83',
            bills: [
              {
                id: '683893431306122626',
                category: 'Gas',
                source: 'conservice',
                amount: '1.88',
                start_date: '2021-03-30',
                end_date: '2021-04-28',
                days: 30,
                bill_flags: {
                  is_estimate: false,
                  is_long: false,
                },
                share_occupancy_id: '99',
                share_occupancy_name: 'Brittany Keys',
                share_occupancy_days: 18,
                billed_occupancy_id: '99',
                share: '0.47',
                share_flags: {
                  is_capped: false,
                  is_reduced_for_airbnb: false,
                },
                splits: [
                  {
                    occupancy_id: '1',
                    occupancy_name: 'Johanna A.',
                    days: 30,
                    amount: '0.47',
                  },
                  {
                    occupancy_id: '2',
                    occupancy_name: 'Fatima F.',
                    days: 30,
                    amount: '0.47',
                  },
                  {
                    occupancy_id: '99',
                    occupancy_name: 'Brittany K.',
                    days: 18,
                    amount: '0.47',
                  },
                  {
                    occupancy_id: '3',
                    occupancy_name: 'Moamen M.',
                    days: 30,
                    amount: '0.47',
                  },
                  {
                    occupancy_id: '4',
                    occupancy_name: 'Tyvon M.',
                    days: 0,
                    amount: '0.00',
                  },
                  {
                    occupancy_id: '5',
                    occupancy_name: 'Ali-khan L.',
                    days: 0,
                    amount: '0.00',
                  },
                  {
                    occupancy_id: '6',
                    occupancy_name: 'Iyore O.',
                    days: 0,
                    amount: '0.00',
                  },
                ],
              },
              {
                id: '683893431353368268',
                category: 'Utilities processing fee',
                source: 'conservice',
                amount: '11.99',
                start_date: '2021-04-21',
                end_date: '2021-05-20',
                days: 30,
                bill_flags: {
                  is_estimate: false,
                  is_long: false,
                },
                share_occupancy_id: '99',
                share_occupancy_name: 'Brittany Keys',
                share_occupancy_days: 12,
                billed_occupancy_id: '99',
                share: '3.36',
                share_flags: {
                  is_capped: false,
                  is_reduced_for_airbnb: false,
                },
                splits: [
                  {
                    occupancy_id: '1',
                    occupancy_name: 'Johanna A.',
                    days: 30,
                    amount: '3.36',
                  },
                  {
                    occupancy_id: '2',
                    occupancy_name: 'Fatima F.',
                    days: 30,
                    amount: '3.36',
                  },
                  {
                    occupancy_id: '99',
                    occupancy_name: 'Brittany K.',
                    days: 12,
                    amount: '3.36',
                  },
                  {
                    occupancy_id: '3',
                    occupancy_name: 'Moamen M',
                    days: 10,
                    amount: '1.12',
                  },
                  {
                    occupancy_id: '4',
                    occupancy_name: 'Tyvon M.',
                    days: 5,
                    amount: '0.56',
                  },
                  {
                    occupancy_id: '5',
                    occupancy_name: 'Ali-khan L.',
                    days: 2,
                    amount: '0.23',
                  },
                  {
                    occupancy_id: '6',
                    occupancy_name: 'Iyore O.',
                    days: 0,
                    amount: '0.00',
                  },
                ],
              },
            ],
          },
        },

        {
          description: 'Payment from bank account **1234',
          amount: '1500.00',
          type: 'payment',
          posted_at: '2020-02-01T07:00:00Z',
        },
        {
          description: 'Credit: Bungalow resident of the year',
          amount: '75.00',
          type: 'credit',
          posted_at: '2020-02-15T07:00:00Z',
        },
        {
          description: 'January Rent',
          amount: '1500.00',
          type: 'charge',
          posted_at: '2020-01-01T07:00:00Z',
        },
      ]
    },
    cancelOrShutdownHandler() {
      if (this.mirageServerIsActive) {
        this.resetMirage()
      }
      this.$root.$emit('closeModal')
    },
    async saveMirageConfigHandler() {
      this.mirageConfigClone.active = true
      this.updateMirageConfig(this.mirageConfigClone)
      this.$root.$emit('closeModal')
    },
  },
}
</script>

<style lang="scss">
#mirage-server-modal {
  .future-charge-amount {
    width: 6.25rem;
  }
}
</style>
