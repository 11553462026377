export const MEETING_TYPES = {
  instantLease: 'instant_lease',
  instantChat: 'instant_chat',
  pendingChat: 'pending_chat',
  email: 'email',
}

export const MEETING_STATUSES = {
  open: 'open',
  approved: 'approved',
  rejected: 'rejected',
  applicantLeft: 'applicant_left',
}
