import { differenceInMonths, isPast } from 'date-fns'
import { getCalculatedRoomPrice } from '@livebungalow/toolbox/abacus'
import {
  DEFAULT_MAXIMUM_LEASE_LENGTH,
  DEFAULT_MINIMUM_LEASE_LENGTH,
  MOVE_IN_DAYS_OUT_MAX_BY_MARKET,
  MOVE_IN_DAYS_OUT_MAX_DEFAULT,
} from '@/constants/pricing.js'

// Assuming the earliest available move in date
// return the lowest available price for a rooms base price
// Uses constants from fieldstone to drive pricing crunch
// @TODO / @HERE
// - This needs to be moved to the backend. Need to discuss how this impacts
// 3rd part listings etc.
export const getRoomLowestPriceAvailable = ({
  basePrice,
  availabilityDate,
  maximumLeaseLength = DEFAULT_MAXIMUM_LEASE_LENGTH,
  minimumLeaseLength = DEFAULT_MINIMUM_LEASE_LENGTH,
  _seasonalMultiplier,
  _holdingFeeMultipliers,
  _leaseLengthMultipliers,
}) => {
  const availablePrices = Array.from({ length: maximumLeaseLength }, (v, k) => k + 1)
    .filter((num) => num >= minimumLeaseLength)
    .map(
      (leaseLength) =>
        getCalculatedRoomPrice({
          basePrice,
          availabilityDate,
          daysUntilMoveIn: 0,
          leaseLength,
          _seasonalMultiplier,
          _holdingFeeMultipliers,
          _leaseLengthMultipliers,
        }).roomPrice
    )
  return Math.min(...availablePrices)
}

export const getMaximumLeaseLength = ({ demandLeasesEndDate, availabilityDate }) => {
  const formattedAvailabilityDate = isPast(new Date(availabilityDate))
    ? new Date()
    : new Date(availabilityDate)
  return demandLeasesEndDate
    ? Math.min(
        DEFAULT_MAXIMUM_LEASE_LENGTH,
        differenceInMonths(new Date(demandLeasesEndDate), new Date(formattedAvailabilityDate))
      )
    : DEFAULT_MAXIMUM_LEASE_LENGTH
}

export const getMoveInDaysOutMaxByMarket = (marketSlug) =>
  MOVE_IN_DAYS_OUT_MAX_BY_MARKET[marketSlug] ?? MOVE_IN_DAYS_OUT_MAX_DEFAULT

export const getBestPriceLeaseLength = (priceMatrix) => {
  // If a bad price matrix was passed, just abort
  if (!priceMatrix) return

  // For each lease length, organize the best prices into an array...
  const bestPricesByMonth = Object.values(priceMatrix).reduce((bestPrices, prices) => {
    // Since the lease lengths are in order, an array retains the sequence!
    bestPrices.push(prices[0])
    return bestPrices
  }, [])
  // Get the very best price of all the lease lengths (the first if multiple)
  const bestPriceMonthIndex = bestPricesByMonth.indexOf(Math.min(...bestPricesByMonth))
  // Default the lease length to match the best possible price
  return bestPriceMonthIndex + DEFAULT_MINIMUM_LEASE_LENGTH
}
