export const copyToClipboard = (content) => {
  // create temporary input to hold address
  const tempElement = document.createElement('textarea')
  // add the input to the dom
  document.body.append(tempElement)
  // set the value
  tempElement.value = content
  // select input text
  tempElement.select()
  // copy text to clipboard
  document.execCommand('copy')
  // remove element
  tempElement.remove()
}

// used to unregister all service workers if the user has legacy ones
export const unRegisterServiceWorkers = async () => {
  try {
    if (window?.navigator?.serviceWorker) {
      const registrations = await navigator.serviceWorker.getRegistrations()
      // no service workers detected, exit chain
      if (!registrations.length) return
      // unregister all service workers
      for (let registration of registrations) {
        await registration.unregister()
      }
      // reload page
      window.location.reload()
    }
  } catch (e) {}
}

export const smoothScrollTo = (element, duration, offset = 100) => {
  const start = window.pageYOffset
  const target = element.getBoundingClientRect().top - offset
  const startTime = performance.now()

  function scroll(timestamp) {
    const elapsed = timestamp - startTime
    const progress = Math.min(elapsed / duration, 1)
    const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2)

    window.scrollTo(0, start + target * easeInOutQuad(progress))

    if (progress < 1) {
      window.requestAnimationFrame(scroll)
    }
  }

  window.requestAnimationFrame(scroll)
}
