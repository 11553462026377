<template>
  <div id="home-type-selection-modal" class="grid-enabled" data-cy="home-type-selection-modal">
    <div class="modal-back-button" @click="goBackToMarketSelection">
      <img src="@/assets/icons/fnd/arrow-left.svg" />
    </div>
    <div class="modal-close-button" @click="closeModal">
      <img src="@/assets/icons/fnd/close.svg" />
    </div>
    <div class="modalContent pt-3">
      <h2 class="header-xl mb-3 mt-5">What type of home are you looking for?</h2>
      <ul
        tabindex="-1"
        role="listbox"
        ref="comboboxNode"
        class="options-list list-unstyled d-flex flex-column align-items-center justify-content-between p-0"
      >
        <li
          v-for="option in homeTypes"
          :key="option.title"
          :data-value="option.title"
          :class="['option py-3 w-100 align-items-center rounded-borders']"
          @click.stop="selectHomeTypeHandler(option.key)"
          ref="comboboxOptions"
          role="option"
        >
          <HomeTypeLayout :homeType="option" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { homeTypes } from '@/constants/nav.js'
import HomeTypeLayout from '@/components/Navigation/HomeTypeLayout.vue'
import MarketSelectionModal from './MarketSelectionModal.vue'

export default {
  name: 'HomeTypeSelectionModal',
  components: {
    HomeTypeLayout,
  },
  props: {
    marketSlug: {
      type: String,
      required: true,
    },
    location: {
      type: String,
    },
  },
  data() {
    return {
      homeTypes,
    }
  },

  computed: {
    ...mapGetters('nav', ['homeTypeDisplayName', 'marketDisplayName']),
  },
  methods: {
    ...mapActions('nav', ['saveHomeType', 'saveMarketSlug']),
    closeModal() {
      this.$root.$emit('closeModal')
    },
    goBackToMarketSelection() {
      this.$root.$emit('openModal', {
        modalComponent: MarketSelectionModal,
        fullscreen: true,
        location: this.location,
      })
    },
    async selectHomeTypeHandler(selectedHomeTypeKey) {
      this.saveHomeType(selectedHomeTypeKey)

      this.$segment.track('Property Type Selected', {
        location: this.location,
        market: this.marketDisplayName,
        propertyType: this.homeTypeDisplayName,
        page: this.$route.name,
      })

      this.$segment.track('Market Search Initiated', {
        location: this.location,
        market: this.marketDisplayName,
        propertyType: this.homeTypeDisplayName,
        page: this.$route.name,
      })

      const offering = homeTypes.find(({ key }) => key === selectedHomeTypeKey).route

      await this.$router
        .push({
          name: 'Listings',
          params: { market: this.marketSlug, offering },
          query: this.$route.query,
        })
        .catch(() => {})

      this.$root.$emit('closeModal')
    },
  },
}
</script>

<style lang="scss">
#home-type-selection-modal {
  padding-left: 32px;
  padding-right: 32px;
  .go-back {
    left: 32px;
  }
  .close-modal {
    right: 32px;
  }
  .header-md {
    font-size: 24px !important;
    opacity: 0;
    animation: show-element 1s forwards;
  }
  .home-type {
    display: flex;
    align-items: center;
    height: 110px;
    opacity: 0;
    animation: fadeAndScrollUp 0.8s forwards 0.5s;
    .home-type-image {
      height: 72px;
      width: 72px;
      background-size: cover;
      background-position: center;
      border-radius: 100%;
    }
  }
  .home-type-subtitle {
    font-size: 12px;
    line-height: 20px;
  }
}
</style>
