<template>
  <div class="notification-wrap" v-if="showNotification">
    <div :class="[`notification-container notify-${position}`, { closing }]">
      <div :class="`notification-content notify-${type} notify-${position}`">
        <span v-if="title" class="font-weight-bold pr-1">{{ title }}</span>
        <span v-html="text" />
      </div>
    </div>
  </div>
</template>

<script>
import { delay } from '@/utils'
const CLOSING_ANIMATION_DURATION = 500
const defaultNotificationData = () => ({
  showNotification: false,
  closing: false,
  duration: 4000,
  delay: 0,
  text: '',
  type: 'default',
  position: 'center',
  title: '',
})
/**
 * Notification component
 *
 * example usages:
 *
 * this.$root.$emit('showNotification', {
    text: `Oops! Looks like that property is no longer available. Checkout these other listings!`,
  })

  Over ride the default duration of 4 seconds
  this.$root.$emit('showNotification', {
    text: `Oops! Looks like that property is no longer available. Checkout these other listings!`,
    duration: 8000,
  })

  Add a delay before the notification is shown
  this.$root.$emit('showNotification', {
    text: `Oops! Looks like that property is no longer available. Checkout these other listings!`,
    delay: 1000
  })
 */
export default {
  name: 'Notifications',
  data() {
    return defaultNotificationData()
  },
  mounted() {
    this.$root.$on('showNotification', this.showNotificationHandler)
  },
  methods: {
    async showNotificationHandler(config) {
      // If the notification is already open don't re-open
      if (this.showNotification) return

      // if there is a delay, wait for the delay duration
      if (config.delay) await delay(config.delay)
      // update the notification config with the submitted data from emitted event
      this.updateLocalData(config)
      this.showNotification = true
      // set the timeout to the duration the notification is shown
      setTimeout(() => this.hideNotificationsHandler(config), this.duration)
    },
    hideNotificationsHandler() {
      // set the closing flag to trigger the animation
      this.closing = true
      // set the timeout
      setTimeout(() => this.updateLocalData(defaultNotificationData()), CLOSING_ANIMATION_DURATION)
    },
    updateLocalData(config) {
      for (const [key, value] of Object.entries(config)) {
        this[key] = value
      }
    },
  },
}
</script>

<style lang="scss">
@import 'src/styles/animations.scss';
@import 'src/styles/mixin.scss';

$notificationAnimationDuration: 0.5s;
$notificationZIndex: 1000000000;

.notification-wrap {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 2rem;
  z-index: $notificationZIndex;
  @include mobile {
    left: 0;
    transform: initial;
  }

  @include mobile {
    left: 0;
    right: 0;
    bottom: 1.5rem;
    margin: 0 1.5rem;
  }
}

.notification-container {
  display: flex;
  align-items: center;
  color: white;
  opacity: 0;
  animation: fade-in $notificationAnimationDuration forwards,
    slide-up $notificationAnimationDuration forwards;
  &.closing {
    animation: fade-out $notificationAnimationDuration,
      slide-down $notificationAnimationDuration forwards;
  }
  &.notify-center {
    justify-content: center;
  }
  &.notify-right {
    justify-content: flex-end;
  }
  > .notification-content {
    box-shadow: 0px 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-size: 15px;
    text-align: center;
    padding: 1rem 1.25rem;
    width: max-content;

    &.notify-default {
      background: $charcoal-60;
    }

    &.notify-success {
      color: $green-60;
      background: $green-20;
    }

    &.notify-primary {
      color: $blue-80;
      background: $blue-20;
    }

    &.notify-error {
      color: $red-60;
      background: $red-20;
    }
    &.notify-warning {
      color: $yellow-80;
      background: $yellow-5;
    }
  }
}
</style>
