import Vue from 'vue'
import Vuex from 'vuex'
import { get } from 'lodash-es'

import modules from './modules'

Vue.use(Vuex)

export function getDefaultStore() {
  return new Vuex.Store({
    modules,
    state: {
      isMobile: false,
      isTablet: false,
      isLaptop: false,
      isDesktop: false,
      isScrolled: false,
    },
    getters: {
      isMobile: (state) => state.isMobile,
      isTablet: (state) => state.isTablet,
      isLaptop: (state) => state.isLaptop,
      isDesktop: (state) => state.isDesktop,
      isScrolled: (state) => state.isScrolled,
    },
    mutations: {
      setMobile(state, payload) {
        state.isMobile = payload
      },
      setTablet(state, payload) {
        state.isTablet = payload
      },
      setLaptop(state, payload) {
        state.isLaptop = payload
      },
      setDesktop(state, payload) {
        state.isDesktop = payload
      },
      setScrolled(state, payload) {
        state.isScrolled = payload
      },
    },
    actions: {
      setBreakpoints({ commit }) {
        const windowWidth = document.documentElement.clientWidth
        const isMobile = windowWidth < 600
        const isTablet = windowWidth < 965
        const isLaptop = windowWidth < 1400
        const isDesktop = !isMobile && !isTablet

        commit('setMobile', isMobile)
        commit('setTablet', isTablet)
        commit('setLaptop', isLaptop)
        commit('setDesktop', isDesktop)
      },
      setScrolled({ commit }) {
        const headerHeight = 100
        const bodyAlreadyScrolled = document.body.classList.contains('body-scrolled')

        if (window.scrollY > headerHeight && !bodyAlreadyScrolled) {
          document.body.classList.add('body-scrolled')
          commit('setScrolled', true)
        } else if (window.scrollY < headerHeight && bodyAlreadyScrolled) {
          document.body.classList.remove('body-scrolled')
          commit('setScrolled', false)
        }
      },
      // Searches for all reset state functions of each module and fires then:
      resetState({ commit }) {
        Object.entries(modules).forEach(([moduleName, moduleConfig]) => {
          const moduleResetFunction = get(moduleConfig, 'mutations.resetState')

          if (moduleResetFunction) commit(`${moduleName}/resetState`)
        })
      },
    },
  })
}

const store = getDefaultStore()

export { store as default }
