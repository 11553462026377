import Vue from 'vue'

// Carousel slider/swiper:
import 'swiper/css/swiper.min.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'

Vue.use(VueAwesomeSwiper)

// Global Components:
import PropertyCard from '@/components/Listings/ListingsPropertyCard'
import FriendlyDate from '@/components/Layout/FriendlyDate'
import LoadingSpinner from '@/components/Shared/LoadingSpinner'
import FormInput from '@/components/Forms/FormInput'
import FormToggle from '@/components/Forms/FormToggle'
import RadioToggle from '@/components/Forms/RadioToggle'
import Appear from '@/components/Shared/Appear'
import StaffContextMenuOverride from '@/components/Shared/StaffContextMenuOverride'

Vue.component('friendly-date', FriendlyDate)
Vue.component('property-card', PropertyCard)
Vue.component('loading-spinner', LoadingSpinner)
Vue.component('form-input', FormInput)
Vue.component('form-toggle', FormToggle)
Vue.component('radio-toggle', RadioToggle)
Vue.component('appear', Appear)
Vue.component('StaffContextMenuOverride', StaffContextMenuOverride)
