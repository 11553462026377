import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  issueComment: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  issueComment: ({ issueComment }) => issueComment,
}

const actions = {
  async createIssueComment({ commit, state }, payload) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .post(apiPaths.maintenance.issueComments(), {
        // We want all payloads to include the source = website so we know
        // where the comment originated from.
        source: 'website',
        ...payload,
      })
      .then(({ data }) => commit('setIssueComment', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setIssueComment(state, payload) {
    state.issueComment = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
