import VueRouter from 'vue-router'
import 'nprogress/nprogress.css'
import NProgress from 'nprogress'

import routes from '@/pages'

let router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to) {
    if (to.params.viewId) return

    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 100 },
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
})

NProgress.configure({ showSpinner: false, trickleSpeed: 150 })

const generateExternalLoginPath = () => {
  return `${process.env.VUE_APP_EXTERNAL_LOGIN_ROUTE}?title=Website Preview Login&next=${window.location.href}`
}

// Automatic Page View Tracking:
// for property pages and listings we manually log a viewed event
// with all the required properties
const excludedAutoPageViewedRoutes = ['Property']
router.afterEach(
  (to) => !excludedAutoPageViewedRoutes.includes(to.name) && router.app.$segment.page(to.name)
)

// Auth Middleware & Lifecycle Enforcement
router.beforeEach(async (goingTo, comingFrom, next) => {
  const progressStopNext = (config) => {
    NProgress.done()
    return next(config)
  }
  // Fix URL if mutliple slashes are appended somehow
  const MULTIPLE_SLASHES = /\/\/+/g
  if (window.location.pathname.match(MULTIPLE_SLASHES)) {
    // Remove double slashes from the path, since Vue router cannot handle until v4
    window.location.pathname = window.location.pathname.replace(MULTIPLE_SLASHES, '/')
    return next()
  }
  const { $auth, $store } = router.app
  const isAuthenticated = $auth.isAuthenticated()
  const hasRefreshToken = $auth.getRefreshToken()

  // Check if the route the user is going to ( or any of it's containers ) require auth
  const routeRequiresAuth = goingTo.matched.some((route) => route.meta.requiresAuth)
  // if the route doesn't require auth and the user isn't trying to auto show
  // their chats, unblock the rendering of the site
  if (!routeRequiresAuth && !goingTo.query.showChats) {
    next()
    // if the comingFrom.name is empty, this represents an initial load, and if the route does require
    // auth OR we're auto showing the chats, start the top bar loader to indicate to the user
    // that the site is loading
  } else if (!comingFrom.name && (routeRequiresAuth || !!goingTo.query.showChats)) {
    NProgress.start()
  }

  // If you're already authenticated...
  if (isAuthenticated) {
    // Fetch the user profile if not already present:
    !$store.getters['user/activeUser'] && (await $store.dispatch('user/fetchActiveUser'))
    // user is authenticated, no need to go to login page so direct to home page
    if (goingTo.name === 'Login') {
      return progressStopNext({
        ...goingTo,
        replace: true,
        name: 'Home Page',
      })
    }
    // Proceed:
    return progressStopNext()
  }

  // If not authenticated, but a refresh token exists, attempt to refresh access token...
  if (!isAuthenticated && hasRefreshToken) {
    try {
      await $auth.refreshAccessToken()
      // If successful, fetch the user profile if not already present:
      if (!$store.getters['user/activeUser']) await $store.dispatch('user/fetchActiveUser')
    } catch (errorRefreshingToken) {
      // Since refreshing failed, expunge tokens (likely bad refresh token):
      await $auth.logout()
    }
  }

  // Global listeners for specific routes -or- query params

  // Automatically open chat tray on showChats param:
  if (goingTo.query.showChats && !$auth.isAuthenticated()) {
    if (process.env.VUE_APP_EXTERNAL_LOGIN_ROUTE)
      return window.location.replace(generateExternalLoginPath())
    return progressStopNext({
      name: 'Login',
      query: {
        next: goingTo.fullPath,
        from: comingFrom?.fullPath,
      },
    })
  }

  // Branded "My Tours" login page:
  if (goingTo.name === 'MyTours' && !$auth.isAuthenticated()) {
    if (process.env.VUE_APP_EXTERNAL_LOGIN_ROUTE)
      return window.location.replace(generateExternalLoginPath())
    return progressStopNext({
      name: 'MyToursLogin',
      query: {
        next: goingTo.fullPath,
        from: comingFrom?.fullPath,
      },
    })
  }

  // If you're heading to a non-authenticated route, proceed:
  if (!routeRequiresAuth) return progressStopNext()

  // Check again if we're authenticated:
  if ($auth.isAuthenticated()) {
    // Success, request user profile:
    await $store.dispatch('user/fetchActiveUser')
  } else {
    // user is attempting to load a authentication required route
    // stamp where their going to the next query parameter
    // and redirect to login
    if (process.env.VUE_APP_EXTERNAL_LOGIN_ROUTE)
      return window.location.replace(generateExternalLoginPath())
    return progressStopNext({
      ...goingTo,
      name: 'Login',
      query: {
        ...goingTo.query,
        next: goingTo.fullPath,
        from: comingFrom?.fullPath,
      },
    })
  }

  // In all other cases, proceed:
  progressStopNext()
})

export default router
