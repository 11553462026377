import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import { Integrations as TracingIntegrations } from '@sentry/tracing'

const IGNORED_ERROR_CODES = [401, 404, 409, 410, 429]

Sentry.init({
  release: process.env.SENTRY_RELEASE,
  environment: process.env.VUE_APP_TARGET || 'development',
  dsn: process.env.VUE_APP_SENTRY_PUBLIC_DSN,
  integrations: [
    new Integrations.Vue({
      Vue,
      attachProps: true,
      logErrors: process.env.VUE_APP_TARGET === 'development',
    }),
    new Integrations.ExtraErrorData(),
    new TracingIntegrations.BrowserTracing({
      tracingOrigins: ['fieldstone.bungalow.com'],
    }),
  ],
  // Intelligently sample
  tracesSampler: (samplingContext) => {
    const pathName = samplingContext?.location?.pathname
    const parentSampled = samplingContext?.parentSampled

    // We don't want partial traces, if the parent has been sampled, continue sampling
    if (parentSampled) return 1

    // Paths of interest
    if (
      pathName &&
      (pathName === '/' ||
        pathName.includes('rooms-for-rent') ||
        pathName.includes('homes-for-rent'))
    ) {
      return 0.05 // 5%
    }

    // Default
    return 0.01 // 1%
  },
  // errors to ignore
  ignoreErrors: [
    'Command received no ack',
    `undefined is not an object (evaluating 'r["@context"].toLowerCase')`,
  ],
})

// We wrap Sentry handler(s) in order to filter unwanted errors/issues
const captureException = (exception, exceptionContext) => {
  // Don't fire ignored errors to Sentry:
  const errorCode = exception && exception.code
  if (IGNORED_ERROR_CODES.includes(errorCode)) return

  // Log the issue to Sentry normally...
  Sentry.captureException(exception, exceptionContext)
}

// Vue Install
Vue.prototype.$sentry = { ...Sentry, captureException }

// Sentry HTTP Error Handler
const SentryHTTPErrorHandler = (response = {}) => {
  // Ignore certian error codes:
  if (response.error && IGNORED_ERROR_CODES.includes(response.error.code)) return

  // Extract Useful Error Message(s)
  let errorMessage = ''
  const apiErrorResponse = response.error || response
  const nonFieldErrors = apiErrorResponse.non_field_errors || []
  const fieldErrors = apiErrorResponse.field_errors || []

  // Format Fieldstone specific error messages...
  if (nonFieldErrors && nonFieldErrors.length) {
    nonFieldErrors.forEach((errorMessageText) => (errorMessage += errorMessageText))
  } else if (fieldErrors) {
    for (let [key, value] of Object.entries(fieldErrors)) {
      errorMessage += `${key.toUpperCase()}: ${value}`
    }
  } else {
    errorMessage = apiErrorResponse.message
  }

  // Log to Sentry
  Sentry.withScope((scope) => {
    scope.setExtra('data', apiErrorResponse ? apiErrorResponse : response)
    const fingerPrint = ['{{ default }}', apiErrorResponse.message, apiErrorResponse.type]
    errorMessage && fingerPrint.push(errorMessage)
    apiErrorResponse && scope.setFingerprint(fingerPrint)

    // Use the best/most relevant error we have
    Sentry.captureException(apiErrorResponse ? Error(errorMessage) : response)
  })
}

export { SentryHTTPErrorHandler, Sentry }
