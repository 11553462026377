import { getField, updateField } from 'vuex-map-fields'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import { convertCamelCaseToDashCase } from '@/utils'
import apiPaths from '@/constants/apiPaths'

const LEASE_LENGTH_MULTIPLIER_IDENTIFIER = 'lease_length_multiplier'
const HOLDING_FEE_MULTIPLIER_IDENTIFIER = 'holding_fee_multiplier'
const SEASONAL_MULTIPLIER_IDENTIFIER = 'cold_zone_month_multiplier'
const PROPERTY_LEASE_LENGTH_MINIMUMS = 'property_lease_length_minimums'

const getDefaultState = () => ({
  ...stateHelpers,
  constants: {
    count: 0,
    previous: '',
    next: '',
    results: [],
  },
  propertyLeaseLengthMinimumsBySlug: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  getField,
  constants: ({ constants: { results } }) => results,
  minimumLeaseLengthForPropertyBySlug:
    ({ propertyLeaseLengthMinimumsBySlug }) =>
    (propertySlug) =>
      propertyLeaseLengthMinimumsBySlug?.defaultValue[propertySlug],
  leaseLengthMultipliers: ({ constants: { results } }) =>
    results.find(({ identifier }) => identifier === LEASE_LENGTH_MULTIPLIER_IDENTIFIER)
      ?.defaultValue,
  holdingFeeMultipliers: ({ constants: { results } }) =>
    results.find(({ identifier }) => identifier === HOLDING_FEE_MULTIPLIER_IDENTIFIER)
      ?.defaultValue,
  seasonalMultiplier: ({ constants: { results } }) =>
    results.find(({ identifier }) => identifier === SEASONAL_MULTIPLIER_IDENTIFIER)?.defaultValue,
  minimumLeaseLengthMultiplier: (state, getters) => {
    return getters.leaseLengthMultipliers
      ? Math.min(...Object.values(getters.leaseLengthMultipliers))
      : 1
  },
  allPricingConstants: (state, getters) => ({
    _seasonalMultiplier: getters.seasonalMultiplier,
    _holdingFeeMultipliers: getters.holdingFeeMultipliers,
    _leaseLengthMultipliers: getters.leaseLengthMultipliers,
  }),
  propertyLeaseLengthMinimumsBySlug: ({ propertyLeaseLengthMinimumsBySlug }) =>
    propertyLeaseLengthMinimumsBySlug,
}

const actions = {
  async fetchConstants({ commit }, query) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const { data } = await this._vm.$http.get(apiPaths.constants.list(query))
      commit('setConstants', data)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
  async fetchPropertyLeaseLengthMinimums({ commit }) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const { data } = await this._vm.$http.get(
        apiPaths.constants.list({ category: 'Property Lease Length Minimums' })
      )
      const foundConstant = data.results.find(
        ({ identifier }) => identifier === PROPERTY_LEASE_LENGTH_MINIMUMS
      )
      // no constant was found with identifier
      // retain what is in default state
      if (!foundConstant) return
      // establish override for converted default value
      const convertedDefaultValue = {}
      // convert camel case to dash ( slug ) format
      for (let [key, value] of Object.entries(foundConstant.defaultValue)) {
        convertedDefaultValue[convertCamelCaseToDashCase(key)] = value
      }
      // swap original with converted default value
      foundConstant.defaultValue = convertedDefaultValue
      // assign state
      commit('setPropertyLeaseLengthMinimums', foundConstant)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
}

const mutations = {
  ...mutationHelpers,
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setConstants(state, payload) {
    state.constants = payload
  },
  setPropertyLeaseLengthMinimums(state, payload) {
    state.propertyLeaseLengthMinimumsBySlug = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
