<template>
  <div id="site-header-mobile-container">
    <div class="site-header-mobile" data-cy="mobile-header">
      <b-navbar toggleable="md" type="light" :class="creamNav">
        <button
          class="nav-button-arrow"
          :class="{ fixed: navState }"
          @click="navButtonClickHandler"
          data-cy="mobile-header-nav-button"
        >
          <inline-svg
            v-if="!navState && !marketListState"
            :src="require('@/assets/icons/hamburger.svg')"
            alt="hamburger"
          />
          <inline-svg
            v-if="navState && !marketListState"
            :src="require('@/assets/icons/cancel.svg')"
            alt="cancel"
          />
          <inline-svg
            v-if="marketListState"
            :src="require('@/assets/icons/arrow-right.svg')"
            alt="back"
          />
        </button>
        <b-navbar-brand @click="hideNavAndMarketList">
          <router-link to="/">
            <img src="https://assets.bungalow.com/logos/bungalow_logo_dark.svg" alt="Bungalow" />
          </router-link>
        </b-navbar-brand>
        <ul class="nav header-badge-wrapper">
          <li class="w-100 p-0">
            <header-badge />
          </li>
        </ul>
      </b-navbar>
      <div class="off-canvas" :class="{ show: navState }" data-cy="mobile-header-off-canvas">
        <ul class="nav h-100 d-flex flex-column">
          <li class="nav-item mb-2 px-4">
            <p class="header-md pt-4 mt-0 mb-1">Find your home</p>
          </li>
          <b-btn
            block
            variant="gradient"
            class="mx-auto mb-3"
            v-limit="327"
            @click="openMarketSelector"
          >
            See Available Cities
            <inline-svg class="ml-2" :src="require('@/assets/icons/fnd/search.svg')" />
          </b-btn>
          <div class="my-2">
            <hr />
          </div>
          <li class="nav-item accent-font header-lg mb-4 px-4" @click="toggleNav">
            <router-link to="/about"> About </router-link>
          </li>
          <li class="nav-item accent-font header-lg mb-4 px-4">
            <div
              v-b-toggle="`renters-dropdown-toggle`"
              class="d-flex align-items-center justify-content-between"
            >
              Renters
              <inline-svg
                style="height: 20px; width: 20px"
                :src="require('@/assets/icons/icon-caret-down.svg')"
              />
            </div>
            <b-collapse id="renters-dropdown-toggle" class="pl-4">
              <div class="pt-3">
                <router-link to="/renters-rooms"> Rooms </router-link>
              </div>
              <div class="pt-3">
                <router-link to="/renters-homes"> Entire Homes </router-link>
              </div>
            </b-collapse>
          </li>
          <li class="nav-item accent-font header-lg mb-4 px-4" @click="toggleNav">
            <router-link to="/homeowners"> Homeowners </router-link>
          </li>
          <li class="nav-item accent-font header-lg mb-4 px-4" @click="toggleNav">
            <router-link to="/radar"> Investors </router-link>
          </li>
          <li class="nav-item accent-font header-lg mb-4 px-4" @click="toggleNav">
            <router-link to="/faq"> FAQ </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HeaderBadge from '@/components/Auth/HeaderBadge.vue'
import { delay } from '@/utils'
import MarketSelectionModal from '../Modals/MarketSelect/MarketSelectionModal.vue'

export default {
  name: 'SiteHeaderMobile',
  components: {
    HeaderBadge,
  },
  data() {
    return {
      navState: false,
      marketListState: false,
    }
  },
  computed: {
    ...mapGetters('nav', ['marketSlug', 'marketDisplayName', 'homeType', 'homeTypeDisplayName']),
    ...mapGetters('markets', ['markets']),
    ...mapGetters('user', ['isLoggedIn', 'activeUser']),
    creamNav() {
      return this.$route.meta.creamNav ? 'cream' : ''
    },
  },
  methods: {
    ...mapActions('user', ['logout']),
    async openMarketSelector() {
      this.$root.$emit('openModal', {
        modalComponent: MarketSelectionModal,
        fullscreen: true,
      })
      await delay(800)
      this.navButtonClickHandler()
    },
    navButtonClickHandler() {
      if (!this.navState && !this.marketListState) {
        return this.toggleNav()
      } else if (this.navState && !this.marketListState) {
        return this.toggleNav()
      } else if (this.marketListState) {
        return this.toggleMarketList()
      }
    },
    hideNavAndMarketList() {
      this.marketListState = false
      this.navState = false
    },
    toggleNav() {
      this.navState = !this.navState
    },
    toggleMarketList() {
      this.marketListState = !this.marketListState
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#site-header-mobile-container {
  &.hidden {
    display: none !important;
  }
  .off-canvas {
    ul.nav {
      width: 100%;
      border-top: $border-default;
      padding: 0;
      li,
      a {
        color: $charcoal-80;
        width: 100%;
      }
      .nav-item {
        position: relative;
        &.city-search {
          padding: 1em 3em;
        }
        &.city-search::before {
          position: absolute;
          text-align: center;
          width: 2.75rem;
          font-size: 1.3em;
          left: 0.25rem;
          top: 1.6rem;
          opacity: 0.6;
        }
        & > a,
        div,
        p {
          display: block;
          color: $charcoal-60;
        }
      }
    }
  }
  .site-header-mobile {
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 10000;
    background: $white;
    .region-code {
      color: $charcoal-40 !important;
      margin: 0px;
    }
    .nav-button-arrow {
      font-size: 14px;
      width: auto;
      height: auto;
      display: inline-block;
      border: none;
      background: none;
      position: absolute;
      overflow: visible;
      transform: rotateZ(180deg);
      transition: all 0.25s ease;
      z-index: 1501;
      top: 1.25em;
      left: 1.25em;
      &:focus {
        outline: none;
      }
      .bar {
        position: absolute;
        width: 100%;
        height: 0.1875em;
        background: black;
        border-radius: none;
        top: 0;
        left: 0;
        transform-origin: right top;
        transition: all 0.25s ease;
        box-shadow: black 0 0 0 0.5px;
      }
      .top {
        left: -0.2em;
        transform: translateX(0.2em) translateY(0em) rotateZ(0deg);
      }
      .middle {
        top: 50%;
        margin-top: -1.5px;
      }
      .bottom {
        top: auto;
        bottom: 0;
        left: -0.2em;
        transform: translateX(0.2em) translateY(0em) rotateZ(0deg);
      }
      &.open {
        transform: rotateZ(0deg);
        .bar {
          background: black;
          box-shadow: black 0 0 0 0.5px;
        }
        .top {
          transform: translateX(0em) translateY(0.13em) rotateZ(-28deg);
          width: 60%;
        }
        .bottom {
          transform: translateX(0.1em) translateY(-0.19em) rotateZ(27deg);
          width: 60%;
        }
      }
    }
    .off-canvas {
      overflow-y: auto;
      padding: $mobile-navbar-height 0 0 0;
    }
    .navbar {
      height: 61px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
      width: 100%;
      transition: all 0.25s;
      background: white;
      z-index: 100000;

      &.cream {
        background: $cream;
      }

      &.fixed {
        border: none;
        background: transparent;
        top: 0px;
      }
      .navbar-brand {
        position: absolute;
        top: 9px;
        width: 135px;
        margin-left: -67px;
        left: 50%;
        z-index: 999;
      }
      .header-badge-wrapper {
        z-index: 1;
        position: absolute;
        right: 0;
        .btn-social {
          i {
            line-height: 2.05em;
          }
        }
      }
      .nav-item,
      .dropdown-item {
        color: $charcoal-80;
        font-size: $font-size-link;
        font-weight: $font-bold;
        @media (max-width: 575px) {
          padding-left: 3.125em;
          font-size: 1.01562rem;
        }
        a,
        .nav-link,
        .nav-link {
          color: $charcoal-80 !important;
        }
        .dropdown-toggle::after {
          display: none;
        }
      }
      .dropdown-menu {
        border-radius: $border-radius-sm;
      }
    }
  }
  #search-home-container {
    width: auto;

    .search-homes-control {
      background: $white;
      border: 1.5px solid $charcoal-20;
      justify-content: space-between;
      border-radius: 4px;
      height: 52px;
      font-weight: bold;
      font-size: 15px;
      display: flex !important;
      align-items: center;
      padding-left: 20px;
      font-family: $base-font;

      > span {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: $charcoal-40;
      }
    }
  }
  .off-canvas {
    position: fixed;
    width: 100vw;
    height: 100%;
    background: white;
    z-index: 1;
    top: 0;
    transform: translate3d(-100vw, 0, 0);
    transition: all 0.5s 0.125s ease;
    overflow-y: auto;
    padding: $mobile-navbar-height 0 0 0;
    &.show {
      transform: translate3d(0, 0, 0);
      border-radius: 0;
    }
  }
  li {
    ul.dropdown-menu {
      @include card;
    }
    button.dropdown-toggle {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 0;
      padding-left: 0;
      padding-bottom: 0;
      &:hover {
        color: inherit;
      }
    }
  }
}
</style>
