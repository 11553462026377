import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  vendex: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  vendex: (state) => state.vendex,
}

const actions = {
  fetchVendorExperience({ commit }, experienceGuid) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(apiPaths.vendex.experience(experienceGuid))
      .then(({ data }) => {
        commit('setVendorExperience', {
          ...data,
          // @TODO https://app.clickup.com/t/86dqkrx0j
          form: { ...data.form, fields: data.form.fields.filter(({ active }) => !!active) },
        })
      })
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setVendorExperience(state, payload) {
    state.vendex = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
