import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  interests: {
    results: [],
    count: 0,
    next: '',
    prev: '',
  },
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  interests: ({ interests }) => interests.results,
}

const actions = {
  fetchInterests({ commit }) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(apiPaths.profiles.interests)
      .then(({ data }) => commit('setInterests', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setInterests(state, payload) {
    state.interests = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
