<template>
  <div :class="['fah__card', { clickable, hover, fluid }]" ref="propertyCard">
    <div
      v-if="error"
      class="salmon-20--bg salmon-60--txt py-2 px-3 br-4 mb-2 font-xs font-bold"
      style="width: 100%"
    >
      <span>{{ error }}</span>
    </div>
    <!-- Image -->
    <div class="fah__card-image">
      <template v-if="renderImages">
        <appear>
          <img style="min-width: 100%" :src="mainPropertyImage" />
        </appear>
      </template>
      <div :class="`top-card d-flex ${topCardPosition} align-items-baseline`">
        <!-- Viewed Badge -->
        <appear v-if="recentlyViewed">
          <div class="bg-white br-4 px-2 font-size-10 py-1 charcoal-40--txt">
            <small class="font-bold"> Viewed </small>
          </div>
        </appear>
        <!-- Open House Badge -->
        <div
          v-if="shouldShowOpenHouseBadge"
          style="padding: 8px 0 6px 0"
          class="bg-white br-4 px-2 font-size-10 text-right"
        >
          <small style="line-height: 0">
            🏡
            <span class="sunrise-text-gradient font-bold">Open House</span><br />
            {{ friendlyOpenHouseDate }}
          </small>
        </div>
        <!-- Match Badge -->
        <div v-else-if="property._isMatch" class="salmon-5--bg br-4 px-2 font-size-10 py-1">
          <small class="sunrise-text-gradient font-bold">
            <inline-svg
              height="16"
              width="16"
              style="margin-bottom: 2px"
              :src="require('@/assets/icons/fnd/fire.svg')"
            />
            Match
          </small>
        </div>
        <div
          v-if="property.isInstantLease && !property._isGroupHome"
          class="yellow-5--bg br-4 px-2 font-size-10 py-1"
        >
          <small class="yellow-60--txt font-bold">
            <inline-svg height="16" width="16" :src="require('@/assets/icons/fnd/zap.svg')" />
            Instant
          </small>
        </div>
      </div>

      <!-- Price -->
      <div v-if="showPrice" class="fah__card-image-price">
        <div class="fah__card-price ml-0">
          <span v-if="hasDiscountedPrice" class="previous-price">
            {{ formattedPrevPrice }}
          </span>
          <span class="current-price">{{ lowestAdvertisedPrice }} /mo</span>
        </div>
      </div>
    </div>

    <div class="fah__card-inner">
      <div>
        <!-- Neighborhood -->
        <div v-if="showNeighborhood" class="fah__card-neighborhood lead">
          {{ neighborhood }}
        </div>

        <!-- Headline -->
        <h3 class="fah__card-headline">
          {{ property.headline }}
        </h3>
      </div>

      <!-- Promotion Tag -->
      <div v-if="property.activePromotion" class="fah__card-promotion">
        <span>
          {{ property.activePromotion.emoji }}
        </span>
        {{ property.activePromotion.name }}
      </div>

      <div v-if="showAddress" class="fah__card-address">
        {{ shortStreetAddress }}
      </div>

      <!-- Details -->
      <div class="fah__card-details">
        <!-- Ammenities -->
        <div class="fah__card-amenities">
          {{ bedroomCount }} Beds
          <span class="charcoal-30--txt">&bull;</span>
          {{ bathroomCount }} Baths
        </div>

        <!-- Available Rooms -->
        <div v-if="showAvailableRooms" class="fah__card-available-rooms">
          <span>
            {{ availableRooms }}
          </span>
        </div>
      </div>

      <!-- TODO: Restore this with interests instead -->
      <IdealRoommateTags
        v-if="false"
        :houseProfile="property.houseProfile"
        :isPetFriendly="property.isPetFriendly"
      >
        <template #header>
          <span class="font-size-12 font-weight-bold">Ideal roommate</span>
        </template>
      </IdealRoommateTags>

      <!-- Household -->
      <div v-if="!hideRoommates" class="fah__card-roommates">
        <!-- Interests -->
        <template>
          <div class="fah__card-interests" @click.prevent="!!clickable">
            <template v-if="hasRoommateData">
              <div
                v-b-tooltip.hover="interest.label"
                :class="['fah__card-interest', { match: interest.match }]"
                v-for="interest in sharedInterests"
                :key="interest.label"
              >
                <img :src="interest.iconSvg" />
              </div>
            </template>
            <div v-else v-b-tooltip.hover="`No interests shared yet`" class="fah__card-interest">
              <img src="@/assets/icons/question.svg" />
            </div>
          </div>

          <!-- Roommates -->
          <div
            class="fah__card-avatar"
            v-b-tooltip.hover="roommateTooltip"
            @click.prevent="!!clickable"
          >
            <inline-svg :src="require('@/assets/icons/fnd/people.svg')" />
            <span>{{ numberOfRoommates }}</span>
          </div>
        </template>
      </div>

      <!-- CTAs -->
      <div v-if="!hideActions" class="fah__card-actions">
        <b-button variant="primary" class="w-100" @click="viewProperty"> View details </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { format, isBefore } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { mapGetters } from 'vuex'
import { formatAsCurrency } from '@/utils'

import IdealRoommateTags from '@/components/Listings/IdealRoommateTags.vue'

export default {
  name: 'ListingsPropertyCard',
  components: {
    IdealRoommateTags,
  },
  props: {
    property: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    hideRoommates: {
      type: Boolean,
      default: false,
    },
    recentlyViewed: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    showNeighborhood: {
      type: Boolean,
      default: true,
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    showAvailableRooms: {
      type: Boolean,
      default: true,
    },
    showGroupPrice: {
      type: Boolean,
      default: false,
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    topCardPosition: {
      type: String,
      default: 'right',
      validator: (value) => ['left', 'right'].includes(value),
    },
  },
  data() {
    return {
      renderImages: false,
      propertyPlaceholder: 'https://assets.bungalow.com/images/property-placeholder.jpg',
    }
  },
  mounted() {
    this.lazyLoadImages()
  },
  computed: {
    shouldShowOpenHouseBadge() {
      if (!this.property) return false
      const { nextOpenHouse, market } = this.property

      const nowInTimezone = utcToZonedTime(new Date(), market.timezone)
      const openHouseDate = new Date(nextOpenHouse)

      const showingHasNotPassed = isBefore(nowInTimezone, openHouseDate)

      return nextOpenHouse && showingHasNotPassed
    },

    friendlyOpenHouseDate() {
      const { nextOpenHouse, market } = this.property
      const startDate = new Date(nextOpenHouse)
      const zonedDate = utcToZonedTime(startDate, market.timezone)

      const friendlyDate = format(zonedDate, 'eee h:mmaaaa', { timeZone: market.timezone })

      return friendlyDate
    },
    shortStreetAddress() {
      const address = this.property.streetAddress.split(',')
      if (address.length === 4) return `${this.property.market.displayName}, ${address[2]}`
      return this.property.streetAddress
    },
    ...mapGetters('funnel', ['funnelData']),
    mainPropertyImage() {
      return this.property.images?.[0].smUrl || this.propertyPlaceholder
    },
    availableRooms() {
      if (this.property._isGroupHome) {
        return this.showGroupPrice ? this.property.priceDisplay : 'Entire home'
      }
      if (this.property.market.hideRoomDetails)
        return `At least one room ${this.availabilityTimeframe}`

      const roomPluralized = this.property.availableRoomCount == 1 ? 'room' : 'rooms'

      const availableRooms = this.property.isComingSoon
        ? `${this.property.totalRoomCount} ${roomPluralized}`
        : `${this.property.availableRoomCount} ${roomPluralized}`

      return `${availableRooms} ${this.availabilityTimeframe}`
    },
    availabilityTimeframe() {
      return this.property.isComingSoon ? 'coming soon' : 'available'
    },
    userInterests() {
      const queryInterests = this.$route.query.roommateInterests?.split(',')
      if (queryInterests?.length) return queryInterests
      return this.funnelData.interests || []
    },
    neighborhood() {
      // some properties don't have a neighbourhood assigned
      const { market, neighborhoodArea } = this.property

      return neighborhoodArea ? neighborhoodArea.name : market.displayName
    },
    sharedInterests() {
      let matchingInterests = []
      let otherInterests = []

      // Part out shared interests...
      this.property.roommateSharedInterests.forEach((interest) => {
        this.userInterests.includes(interest.label)
          ? matchingInterests.push({ ...interest, match: true })
          : otherInterests.push(interest)
      })

      // Prioritize shared interests first (if any):
      const organizedInterests = [...matchingInterests, ...otherInterests]

      // Return only up to 4
      return organizedInterests.slice(0, 4)
    },
    hasRoommateData() {
      return this.property.roommateSharedInterests.length
    },
    numberOfRoommates() {
      return this.property.totalRoomCount - this.property.availableRoomCount
    },
    roommateTooltip() {
      return this.numberOfRoommates
        ? `${this.numberOfRoommates} roommates`
        : 'Be the first roommate!'
    },
    bedroomCount() {
      return this.property.totalRoomCount
    },
    bathroomCount() {
      return this.property.numBathrooms
    },
    hasDiscountedPrice() {
      return this.property._isGroupHome
        ? this.property.fullPropertyPrice < this.property.prevFullPropertyPrice
        : this.property.priceMin < this.property.prevMinPrice
    },
    formattedPrevPrice() {
      return this.property._isGroupHome
        ? formatAsCurrency(this.property.prevFullPropertyPrice)
        : formatAsCurrency(this.property.prevMinPrice)
    },
    lowestAdvertisedPrice() {
      return this.property._isGroupHome
        ? formatAsCurrency(this.property.fullPropertyPrice)
        : this.property.priceDisplay
    },
  },
  methods: {
    lazyLoadImages() {
      // Compatibility check:
      if (!window.IntersectionObserver || !!navigator.webdriver) return (this.renderImages = true)

      try {
        const io = new IntersectionObserver(
          ([event], observer) => {
            if (event.isIntersecting) {
              this.renderImages = true
              observer.unobserve(event.target)
            }
          },
          { rootMargin: '0px 150px 0px 0px' }
        )
        // Start observing an element
        io.observe(this.$refs.propertyCard)
      } catch (observerError) {
        console.error(observerError)
      }
    },
    viewProperty() {
      // Build property page URL:
      const propertyRouteData = this.$router.resolve({
        name: 'Property',
        params: {
          offering: this.property._isGroupHome ? 'homes-for-rent' : 'rooms-for-rent',
          market: this.$route.params.market,
          slug: this.property.slug,
        },
      })
      // Attempt to open in a new tab:
      window.open(propertyRouteData.href, '_blank')
    },
  },
}
</script>

<style lang="scss">
.fah__card {
  @include card;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  border-radius: 8px;
  background: white;
  margin: auto;
  max-width: 312px;

  &.clickable {
    cursor: pointer;
    transition: box-shadow 0.25s ease;
    box-shadow: none;

    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
  }

  &.hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  &-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 0.5rem;
    @include tabletAndUp {
      height: 100%;
    }
  }

  &-headline {
    display: flex;
    font-weight: bold;
    font-family: $accent-font;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0.25rem;
    width: 100%;
    height: 48px;
    overflow: hidden;
  }

  &-promotion {
    display: inline-block;
    background: $salmon-60;
    color: white;
    font-size: 13px;
    font-weight: bold;
    border-radius: 4px;
    padding: 0 8px;
    padding-top: 1px;
    margin-top: 0rem;
    margin-bottom: 0.25rem;

    span {
      display: inline-block;
      padding-top: 2px;
    }
  }

  &-details {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    width: 100%;
  }

  &-neighborhood {
    width: 100%;
    font-size: 11px;
    font-weight: normal;
    margin-bottom: 0.25rem;
  }

  &-amenities {
    color: $charcoal-40;
    font-size: 14px;
    white-space: nowrap;
    margin-right: 0.5rem;
    margin-top: 0.25rem;
  }

  &-available-rooms {
    font-weight: bold;
    color: $green-80;
    font-size: 14px;
    margin-right: -0.25rem;
  }

  &-price {
    font-size: 14px;
    white-space: nowrap;
    color: $charcoal-60;

    .current-price {
      font-weight: bold;
      color: $green-80;
      background: $green-10;
      padding: 4px 4px 3px 4px;
      border-radius: 4px;
    }

    .previous-price {
      margin-right: 0.25rem;
      position: relative;

      // Price strike-through:
      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        height: 2px;
        background: $charcoal-60;
        width: calc(100% + 2px);
        left: -2px;
        top: 6px;
        opacity: 0.65;
      }
    }
  }

  &-address {
    text-align: left;
    font-size: 13px;
    line-height: 20px;
  }

  &-image {
    position: relative;
    width: 100%;
    margin-bottom: 1.25rem;
    height: 200px;
    min-height: 200px;
    background: $charcoal-5;
    border-radius: 4px;

    &:hover {
      .fah__card-prev,
      .fah__card-next {
        opacity: 0.85;
      }
    }

    .fah__card-prev,
    .fah__card-next {
      transition: opacity 0.15s ease;
      opacity: 0;

      path {
        fill: white;
      }
    }

    img {
      object-fit: cover;
      border-radius: 4px;
      height: 100%;
      width: 100%;
      background: $charcoal-10;

      &.fill {
        height: 200px;
      }
    }

    &-overlay {
      z-index: 1;
      position: absolute;
      left: 0.5rem;
      bottom: 0.5rem;
      border-radius: 4px;
      font-weight: bold;
      color: white;
      font-size: 13px;
      padding: 4px 8px;
      background: rgba(36, 32, 31, 0.5);
    }

    &-price {
      z-index: 1;
      position: absolute;
      left: 0.35rem;
      bottom: 0.35rem;
      border-radius: 4px;
      font-size: 13px;
      padding: 5px 8px 3px 8px;
      background: $green-10;
    }
  }

  &-roommates {
    margin-top: 1rem;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    p {
      @include mobile {
        font-size: 13px;
      }
    }
  }

  &-avatar {
    cursor: default;
    transition: background 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $charcoal-60;
    border-radius: 4px;
    width: 60px;
    height: 42px;

    &:hover {
      background: $charcoal-5;
    }

    svg {
      width: 24px;
      height: 24px;

      path {
        color: $charcoal-60;
      }
    }

    span {
      color: $charcoal-60;
      font-size: 15px;
      font-weight: bold;
      margin-left: 2px;
    }
  }

  &-interests {
    display: flex;
  }

  &-interest {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $charcoal-20;
    font-weight: bold;
    height: 42px;
    width: 42px;
    font-size: 13px;
    border-radius: 100%;
    margin-right: 6px;

    &:hover {
      background: $charcoal-5;
    }

    img {
      width: 22px;
      height: 22px;
    }
    &.match {
      border-color: $blue-80;
    }
  }

  &-actions {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-top: 1.25rem;

    button {
      padding: 0 !important;
      width: calc(50% - 0.5rem);
      height: 46px;
      font-size: 14px;
      margin: 0;

      &.btn-text {
        border: 1px solid $charcoal-10;
      }
    }
  }
  .top-card {
    position: absolute;
    top: 0.35rem;
    z-index: 1;
    &.right {
      right: 0.35rem;
      > div {
        margin-left: 10px;
      }
    }
    &.left {
      left: 10px;
      > div {
        margin-right: 10px;
      }
    }
  }
}
</style>
