// Book a tour flow ( also enabled for empty and instant lease homes )

export default [
  {
    path: 'book-a-tour',
    name: 'BookATour',
    component: () =>
      import(/* webpackChunkName: "book-a-tour" */ './containers/BookATourContainer.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'tour-type',
        name: 'BookATourType',
        meta: {
          requiresAuth: true,
          hideFooter: true,
        },
        component: () =>
          import(/* webpackChunkName: "book-a-tour" */ './containers/BookATourContainer.vue'),
      },
      {
        path: 'tour-schedule',
        name: 'BookATourSchedule',
        meta: {
          requiresAuth: true,
          hideFooter: true,
        },
        component: () =>
          import(/* webpackChunkName: "book-a-tour" */ './containers/BookATourContainer.vue'),
      },
      {
        path: 'confirm-identity',
        name: 'BookATourIdentity',
        meta: {
          requiresAuth: true,
          hideFooter: true,
        },
        component: () =>
          import(/* webpackChunkName: "book-a-tour" */ './containers/BookATourContainer.vue'),
      },
      {
        path: 'tour-confirmation',
        name: 'BookATourConfirmation',
        meta: {
          requiresAuth: true,
          hideFooter: true,
        },
        component: () =>
          import(/* webpackChunkName: "book-a-tour" */ './containers/BookATourContainer.vue'),
      },
    ],
  },
]
