import { getField, updateField } from 'vuex-map-fields'
import { cloneDeep, filter, find } from 'lodash-es'
import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const convertStatementLineToNumberAmount = (statementLines) =>
  statementLines.map((statementLine) => ({
    ...statementLine,
    amount: Number(statementLine.amount),
  }))

const getDefaultState = () => ({
  ...stateHelpers,
  residentBalance: {
    totalPrepaid: 0.0,
    totalLate: 0.0,
    totalDueImmediately: 0.0,
  },
  residentPaymentMethods: [],
  residentStatementLines: [],
  residentFutureStatementLines: {
    statementLines: [],
    netBalance: 0.0,
  },
  residentProductSettings: {
    autoPayEnabled: false,
  },
  residentOccupancy: {},
  residentFullOccupancy: [],
  residentPaymentEstimate: {
    amount: 0.0,
    feeDescription: '',
    feeAmount: 0.0,
    totalAmount: 0.0,
  },
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  getField,
  residentPaymentMethods: ({ residentPaymentMethods }) => residentPaymentMethods,
  defaultPaymentMethod: ({ residentPaymentMethods }) =>
    cloneDeep(residentPaymentMethods.find(({ isDefault }) => isDefault)),
  residentBalance: ({ residentBalance }) => residentBalance,
  residentHasOutstandingInitialPayment: ({ residentBalance: { totalDueImmediately } }) =>
    totalDueImmediately,
  residentHasUnverifiedBankAccount: (state, getters) => {
    return getters.defaultPaymentMethod && !getters.defaultPaymentMethod.verifiedAt
  },
  residentHasVerifiedBankAccount: (state, getters) => {
    return getters.defaultPaymentMethod && !!getters.defaultPaymentMethod.verifiedAt
  },
  residentStatementLines: ({ residentStatementLines }) => residentStatementLines,
  residentFutureStatementLines: ({ residentFutureStatementLines }) => residentFutureStatementLines,
  residentProductSettings: ({ residentProductSettings }) => residentProductSettings,
  residentOccupancy: ({ residentOccupancy }) => residentOccupancy,
  residentFullOccupancy: ({ residentFullOccupancy }) => residentFullOccupancy,
  residentCurrentOccupancy: ({ residentFullOccupancy }) => {
    return find(residentFullOccupancy, ['isCurrent', true])
  },
  residentUpcomingChargesBalance: ({ residentFutureStatementLines }) => {
    // get the credits from the future charges API endpoint
    const credits = filter(residentFutureStatementLines.statementLines, {
      type: 'credit',
    })
    // add them up
    const creditTotal = credits.reduce((a, b) => a + b.amount, 0)
    // remove the credits from the charges list
    const charges = filter(residentFutureStatementLines.statementLines, {
      type: 'charge',
    })
    // subtract the credits from the future charges that get returned
    return charges.reduce((a, b) => a + b.amount, 0) - creditTotal
  },
  residentPaymentEstimate: ({ residentPaymentEstimate }) => {
    return residentPaymentEstimate
  },
}

const actions = {
  /**
   * GET CALLS
   */
  async fetchAllResidentBillingInfo({ dispatch }) {
    await dispatch('fetchPaymentMethods')
    await dispatch('fetchResidentBalances')
    await dispatch('fetchResidentStatement')
    await dispatch('fetchResidentFutureStatementLines')
    await dispatch('fetchProductSettings')
    await dispatch('fetchResidentOccupancy')
    await dispatch('fetchResidentFullOccupancy')
  },
  async fetchResidentOccupancy({ commit }) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const {
        data: { occupancy },
      } = await this._vm.$http.get(apiPaths.resident.currentOccupancy)
      commit('setResidentOccupancy', occupancy)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
  async fetchResidentFullOccupancy({ commit }) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const { data } = await this._vm.$http.get(apiPaths.resident.fullOccupancy)
      commit('setResidentFullOccupancy', data.results)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
  async fetchProductSettings({ commit }) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const { data } = await this._vm.$http.get(apiPaths.resident.productSettings)
      commit('setResidentProductSettings', data)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
  async fetchPaymentMethods({ commit }) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const {
        data: { results },
      } = await this._vm.$http.get(apiPaths.resident.paymentMethods)
      const defaultOnly = results.filter(({ isDefault }) => isDefault)
      commit('setPaymentMethods', defaultOnly)
      commit('setError', false)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },

  async fetchResidentBalances({ commit }) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const { data } = await this._vm.$http.get(apiPaths.resident.balance)
      let formattedData = {}
      for (let [key, value] of Object.entries(data)) {
        formattedData[key] = Number(value) || 0.0
      }
      commit('setResidentBalance', formattedData)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },

  async fetchResidentStatement({ commit }) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const {
        data: { statementLines },
      } = await this._vm.$http.get(apiPaths.resident.statementLines)
      const formattedData = convertStatementLineToNumberAmount(statementLines)
      commit('setResidentStatementLines', formattedData)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },

  async fetchResidentFutureStatementLines({ commit }) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const { data } = await this._vm.$http.get(apiPaths.resident.futureStatementLines)
      commit('setResidentFutureStatementLines', data)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },

  /**
   * POST CALLS
   */
  /**
   *
   * payload needs to include plaid_public_token and plaid_account_id fields
   */
  async createPaymentMethod({ commit }, payload) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      await this._vm.$http.post(apiPaths.resident.createPaymentMethod, payload)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
  async createResidentPayment({ commit }, payload) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      await this._vm.$http.post(apiPaths.resident.createPayment, payload)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },

  async patchProductSettings({ commit, getters }) {
    try {
      commit('setUpdating', true)
      commit('setError', false)
      const { autoPayEnabled: auto_pay_enabled } = getters.residentProductSettings
      const payload = {
        auto_pay_enabled,
      }
      const productSettings = await this._vm.$http.patch(apiPaths.resident.productSettings, payload)
      commit('setResidentProductSettings', productSettings)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },

  async createManualResidentPaymentMethod({ commit }, payload) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      await this._vm.$http.post(apiPaths.resident.createManualPaymentMethod, payload)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },

  async verifyManualResidentPaymentMethod({ commit }, { id, payload }) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      await this._vm.$http.post(apiPaths.resident.verifyManualPaymentMethod(id), payload)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },

  async acceptResidentInvite({ commit }, payload) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      await this._vm.$http.put(apiPaths.resident.invite, payload)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },

  async createCreditPaymentMethod({ commit }, payload) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      await this._vm.$http.post(apiPaths.resident.createCreditPaymentMethod, payload)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },

  async fetchResidentPaymentEstimate({ commit }, payload) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const { data: residentPaymentEstimate } = await this._vm.$http.post(
        apiPaths.resident.getPaymentEstimate,
        payload
      )
      commit('setResidentPaymentEstimate', residentPaymentEstimate)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
}

const mutations = {
  ...mutationHelpers,
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setPaymentMethods(state, residentPaymentMethods) {
    state.residentPaymentMethods = residentPaymentMethods
  },
  setResidentBalance(state, residentBalance) {
    state.residentBalance = residentBalance
  },
  setResidentStatementLines(state, residentStatementLines) {
    state.residentStatementLines = residentStatementLines
  },
  setResidentFutureStatementLines(state, residentFutureStatementLines) {
    state.residentFutureStatementLines = residentFutureStatementLines
  },
  setResidentProductSettings(state, residentProductSettings) {
    state.residentProductSettings = residentProductSettings
  },
  setResidentOccupancy(state, residentOccupancy) {
    state.residentOccupancy = residentOccupancy
  },
  setResidentFullOccupancy(state, residentFullOccupancy) {
    state.residentFullOccupancy = residentFullOccupancy
  },
  setResidentPaymentEstimate(state, residentPaymentEstimate) {
    state.residentPaymentEstimate = residentPaymentEstimate
  },
  clearResidentPaymentEstimate(state) {
    state.residentPaymentEstimate = getDefaultState().residentPaymentEstimate
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
