import apiPaths from '@/constants/apiPaths'
import { omitBy, isNull, get } from 'lodash-es'
import { DEFAULT_AUTH_ERROR_MESSAGE, LOGIN_POPUP_CLOSED_MESSAGE } from '@/constants/auth.js'
import { getCookie } from '@/utils'

const state = {
  activeUser: null, // name, email, birthday, bio, phone
  userProfile: null, // pronoun, employment, education, social, interests
  loadingUser: false,
  applicantProfile: null, // flags, liviving situation, etc.
  userAnimals: null, // pets, ESA, etc.
  userFetched: false,
  authError: false,
  loginPending: false,
  favorites: [],
  avatarPlaceholder: 'https://assets.bungalow.com/avatars/pineapple-avatar-5.png',
}

const generateErrorMessageString = (error) => {
  try {
    const { nonFieldErrors } = error
    return nonFieldErrors.join(' ') || DEFAULT_AUTH_ERROR_MESSAGE
  } catch (e) {
    return DEFAULT_AUTH_ERROR_MESSAGE
  }
}

const actions = {
  fetchActiveUser({ commit, dispatch }, fetchApplications = true) {
    commit('setLoadingUser', true)
    return this._vm.$http
      .get(apiPaths.auth.me)
      .then(async ({ data }) => {
        const { id, email } = data

        commit('setActiveUser', data)
        commit('setLoadingUser', false)
        this._vm.$sentry.setUser({ id, email })

        // GET a few other APIs to fill out user data map
        await Promise.all([
          dispatch('fetchUserProfile'),
          dispatch('fetchUserAnimals'),
          dispatch('fetchApplicantProfile'),
          ...(fetchApplications
            ? [dispatch('applications/fetchFunnelApplication', null, { root: true })]
            : []),
        ]).then(() => {
          commit('setUserFetched', true)
          commit('setLoadingUser', false)
        })
      })
      .catch(this._vm.$sentry.captureException)
  },
  fetchUserProfile({ commit, getters }) {
    return this._vm.$http
      .get(apiPaths.profiles.profileByUserId(getters?.activeUser?.id))
      .then(({ data }) => commit('setUserProfile', data))
      .catch(this._vm.$sentry.captureException)
  },
  fetchApplicantProfile({ commit, getters, dispatch }) {
    return this._vm.$http
      .get(apiPaths.applications.applicantProfileByUserId(getters?.activeUser?.id))
      .then(({ data }) => commit('setApplicantProfile', data))
      .catch(async (e) => {
        try {
          // we didn't find a profile, so create one
          if (e?.code === 404) return await dispatch('updateOrCreateApplicantProfile', {})
          else {
            this._vm.$sentry.captureException(e)
          }
        } catch (e) {
          this._vm.$sentry.captureException(e)
        }
      })
  },
  fetchUserAnimals({ commit }) {
    return this._vm.$http
      .get(apiPaths.applications.animals)
      .then(({ data }) => commit('setUserAnimals', data.results))
      .catch(this._vm.$sentry.captureException)
  },
  updateUser({ commit }, payload) {
    return this._vm.$http
      .patch(apiPaths.auth.me, payload)
      .then(({ data }) => commit('setActiveUser', data))
      .catch(this._vm.$sentry.captureException)
  },
  updateOrCreateApplicantProfile({ commit }, payload) {
    payload = omitBy(payload, isNull)
    return this._vm.$http
      .post(apiPaths.applications.applicantProfile, payload)
      .then(({ data }) => commit('setApplicantProfile', data))
      .catch(this._vm.$sentry.captureException)
  },
  updateUserPronoun(store, { pronoun }) {
    return this._vm.$http
      .post(apiPaths.profiles.pronoun, { pronoun })
      .catch(this._vm.$sentry.captureException)
  },
  setSmsChatOptIn({ dispatch }, use_sms_for_meet_and_greet) {
    return dispatch('updateOrCreateApplicantProfile', { use_sms_for_meet_and_greet })
  },
  createOrUpdateUserEmployment({ getters }, { employer, title }) {
    // Dynamically create or update depending if we found an existing record(s)
    const { id } = get(getters, 'userProfile.employmentHistory[0]', {})
    const apiMethod = {
      path: id ? apiPaths.profile.employmentById(id) : apiPaths.profile.employment,
      verb: id ? 'put' : 'post',
    }

    return this._vm.$http[apiMethod.verb](apiMethod.path, { employer, title }).catch(
      this._vm.$sentry.captureException
    )
  },
  createOrUpdateUserEducation({ getters }, { education }) {
    // Dynamically create or update depending if we found an existing record(s)
    const { id } = get(getters, 'userProfile.educationHistory[0]', {})
    const apiMethod = {
      path: id ? apiPaths.profile.educationById(id) : apiPaths.profile.education,
      verb: id ? 'put' : 'post',
    }

    return this._vm.$http[apiMethod.verb](apiMethod.path, { name: education }).catch(
      this._vm.$sentry.captureException
    )
  },
  createOrUpdateUserSocial({ getters }, { handle, source }) {
    // The field can't be blank, so if not passed was probably optional
    if (!handle) return

    // Dynamically create or update depending if we found an existing record(s)
    const { id } = get(getters, 'userProfile.socialNetworks[0]', {})
    const apiMethod = {
      path: id ? apiPaths.profile.socialNetworkById(id) : apiPaths.profile.socialNetwork,
      verb: id ? 'put' : 'post',
    }

    return this._vm.$http[apiMethod.verb](apiMethod.path, { handle, source }).catch(
      this._vm.$sentry.captureException
    )
  },
  createOrUpdateUserAnimals(
    { commit, dispatch, getters },
    { animal_type, species, species_other }
  ) {
    // Dynamically create or update depending if we found an existing record(s)
    const { id } = get(getters, 'userAnimals[0]', {})

    // If we have an ID but empty data, delete instead:
    if (!animal_type && !species && !species_other) {
      if (id) return dispatch('deleteUserAnimals', id)
      return
    }

    const apiMethod = {
      path: id ? apiPaths.applications.animalsById(id) : apiPaths.applications.animals,
      verb: id ? 'put' : 'post',
    }

    return this._vm.$http[apiMethod.verb](apiMethod.path, {
      species,
      animal_type,
      ...(species_other && { species_other }),
    })
      .then(({ data }) => commit('setUserAnimals', [data]))
      .catch(this._vm.$sentry.captureException)
  },
  deleteUserAnimals({ commit }, animalId) {
    return this._vm.$http
      .delete(apiPaths.applications.animalsById(animalId))
      .then(commit('setUserAnimals', []))
      .catch(this._vm.$sentry.captureException)
  },
  fetchFavorites({ commit }) {
    return this._vm.$http
      .get(apiPaths.listings.favorites)
      .then(({ data }) => commit('setFavorites', data.results))
      .catch(this._vm.$sentry.captureException)
  },
  uploadProfilePhoto({ commit }, { purpose, file }) {
    // Build payload
    const formData = new FormData()
    formData.append('purpose', purpose)
    formData.append('file', file)

    return this._vm.$http
      .post(apiPaths.files.files, formData)
      .then(({ data }) => commit('setProfileImageUrl', data.url))
      .catch(this._vm.$sentry.captureException)
  },
  async authenticate({ commit, dispatch }, { provider, onLogin = () => {} }) {
    commit('setLoginPending', true)
    commit('setAuthError', false)
    // Important for access to Vue inside the the promise chain below:
    const vueApp = this._vm

    try {
      const user = await vueApp.$auth.authenticate(provider)
      let googleClickId = null
      try {
        googleClickId = getCookie('google_click_id') || getCookie('gclid')
      } catch (e) {}

      const { id, email, firstName, lastName } = user

      await dispatch('fetchActiveUser')

      // Sentry identification:
      this._vm.$sentry.setUser({ id, email })

      this._vm.$segment.track('User Authenticated', {
        id,
        email,
        firstName,
        lastName,
        provider,
        google_click_id: googleClickId,
      })

      window?.gtag('event', 'User Authenticated', {
        id,
        email,
        firstName,
        lastName,
        provider,
      })

      // Fire any login side-effects provided (redirect, etc.).
      onLogin()

      commit('setAuthError', false)
    } catch (error) {
      // When the login popup is closed, this message is returned, do nothing
      if (error?.error === LOGIN_POPUP_CLOSED_MESSAGE) return
      // capture error in sentry
      this._vm.$sentry.captureException(error)
      const errorMessage = generateErrorMessageString(error)
      this._vm.$segment.track('User Authentication Error', {
        error: errorMessage,
      })
      commit('setAuthError', errorMessage)
    } finally {
      commit('setLoginPending', false)
    }
  },
  logout({ commit, dispatch }) {
    // Destroy tokens/cookies:
    this._vm.$auth.logout()

    // Expunge user data:
    commit('setActiveUser', null)
    commit('setUserProfile', null)
    commit('setApplicantProfile', null)
    commit('setUserFetched', false)

    // Reset the Segment user:
    this._vm.$segment.reset()

    // Reset Mirage:
    if (process.env.VUE_APP_TARGET !== 'production')
      dispatch('mirage/resetMirage', null, { root: true })
  },
}

const mutations = {
  setLoadingUser: (state, payload) => (state.loadingUser = payload),
  setActiveUser: (state, payload) => (state.activeUser = payload),
  setUserFetched: (state, payload) => (state.userFetched = payload),
  setUserProfile: (state, payload) => (state.userProfile = payload),
  setUserAnimals: (state, payload) => (state.userAnimals = payload),
  setApplicantProfile: (state, payload) => (state.applicantProfile = payload),
  setAuthError: (state, payload) => (state.authError = payload),
  setLoginPending: (state, payload) => (state.loginPending = payload),
  setFavorites: (state, payload) => (state.favorites = payload),
  setProfileImageUrl: (state, payload) => {
    if (state.activeUser) state.activeUser.profileImageUrl = payload
  },
}

const getters = {
  activeUser: (state) => state.activeUser,
  // this flag can be user to check when we're loading the base user object
  loadingUser: (state) => !state.activeUser && state.loadingUser,
  userProfile: (state) => state.userProfile,
  userAnimals: (state) => state.userAnimals,
  userFullProfile: ({
    activeUser,
    userAnimals,
    applicantProfile,
    userProfile: { socialNetworks, employmentHistory, educationHistory, ...profile },
  }) => ({
    // Builds out a master user data-set using multiple API responses
    ...activeUser,
    ...profile,
    ...applicantProfile,
    // Conditionally spreads in these records if they exist...
    ...(userAnimals?.[0] && userAnimals[0]),
    ...(employmentHistory?.[0] && employmentHistory[0]),
    ...(socialNetworks?.[0] && socialNetworks[0]),
    ...(educationHistory?.[0] && { education: educationHistory[0]?.name }),
  }),
  applicantProfile: (state) => state.applicantProfile,
  // NOTE: It's important that a missing/unset property here is `null` not `false`
  isVettedApplicant: (state) => state.applicantProfile?.isVettedApplicant || null,
  userProfileMatchReady: (state) => !!state.userProfile?.age && !!state.userProfile?.pronoun,
  userFetched: (state) => state.userFetched,
  isLoggedIn: (state) => !!state.activeUser,
  authError: (state) => state.authError,
  loginPending: (state) => state.loginPending,
  favorites: (state) => state.favorites,
  activeUserIsHomeowner: (state) => get(state, 'activeUser.isHomeowner'),
  activeUserIsResident: (state) => get(state, 'activeUser.isResident'),
  isPersonaVerified: (state) => get(state, 'activeUser.isPersonaVerified'),
  activeUserIsStaff: (state) => get(state, 'activeUser.isStaff'),
  avatarPlaceholder: (state) => state.avatarPlaceholder,
  userProfileImage: (state) => state.activeUser?.profileImageUrl,
  smsMeetGreetEnabled: (state) => get(state, 'applicantProfile.useSmsForMeetAndGreet'),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
