import { getField, updateField } from 'vuex-map-fields'
import { stateHelpers, getterHelpers, mutationHelpers } from '@/store/helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  availableTourTypes: null,
  toursAvailability: null,
  toursAvailabilityFetched: false,
  openHouseTimeslots: null,
  openHouseTimesFetched: false,
  myTours: [],
  selectedTours: 'upcoming',
  currentTour: null,
  form: {
    // Basic Info
    // these fields are stamped in the webflow pages,
    // use the values or default to empty string
    first_name: localStorage.getItem('first_name') || '',
    last_name: localStorage.getItem('last_name') || '',
    email: localStorage.getItem('email') || '',
    phone: localStorage.getItem('phone') || '',
    // tour form information
    tour_type: '',
    tour_slot: '',
    tour_identity: '',
    is_identity_verified: false,
    phone_number: '',
  },
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  selectedTours: (state) => state.selectedTours,
  availableTourTypes: (state) => state.availableTourTypes,
  toursAvailability: (state) => state.toursAvailability,
  toursAvailabilityFetched: (state) => state.toursAvailabilityFetched,
  openHouseTimeslots: (state) => state.openHouseTimeslots,
  openHouseTimesFetched: (state) => state.openHouseTimesFetched,
  myTours: (state) => state.myTours,
  currentTour: (state) => state.currentTour,
  getField,
  submitPayload:
    ({ form }) =>
    (propertyId) => {
      let payload = { ...form }
      return {
        start_time: payload.tour_slot.startTime,
        end_time: payload.tour_slot.endTime,
        property_id: propertyId,
      }
    },
}

const actions = {
  async fetchMyTours({ commit }, pastTours) {
    try {
      commit('setLoading', true)
      const { data } = await this._vm.$http.get(`/listings/tours/${pastTours ? '?past=true' : ''}`)
      commit('setSelectedTours', pastTours ? 'past' : 'upcoming')
      commit('setMyTours', data.results)
    } catch (error) {
      console.error(error)
    } finally {
      commit('setLoading', false)
    }
  },

  async fetchCurrentTour({ commit }, tourID) {
    commit('setLoading', true)
    try {
      const { data } = await this._vm.$http.get(`/listings/tours/${tourID}`)
      commit('setCurrentTour', data)
    } catch (error) {
      console.error(error)
    } finally {
      commit('setLoading', false)
    }
  },

  async fetchToursAvailability({ commit }, { propertyId }) {
    commit('setToursAvailabilityFetched', false)
    try {
      const { data } = await this._vm.$http.get(apiPaths.tours.availability(propertyId))
      commit('setToursAvailability', data)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setToursAvailabilityFetched', true)
    }
  },
  async submitToursForm({ commit, getters }, payload) {
    try {
      commit('setLoading', true)
      commit('setError', false)
      const { data } = await this._vm.$http.post(
        apiPaths.tours.schedule,
        getters.submitPayload(payload.propertyId)
      )
      return data
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
  async cancelTourBooking({ commit }, tour = {}) {
    try {
      commit('setLoading', true)
      await this._vm.$http.patch(`listings/tours/${tour.id}`, {
        canceled: true,
      })
    } catch (error) {
      // NOTE: Temporary hack to handle 204 null body error
      // Context: https://github.com/mswjs/msw/issues/530
      if (error.code >= 300) {
        commit('setError', error)
        this._vm.$sentry.captureException(error)
      }
    } finally {
      commit('setLoading'), false
    }
  },

  fetchOpenHouseAvailability({ commit }, propertyId) {
    commit('setOpenHouseTimesFetched', false)

    return this._vm.$http
      .get(`/listings/open-house-showings/?property=${propertyId}`)
      .then(({ data }) => {
        commit('setOpenHouseTimeslots', data.results)
        commit('setOpenHouseTimesFetched', true)
      })
      .catch((error) => this._vm.$sentry.captureException(error))
  },
  createOpenHouseBooking({ commit }, payload) {
    return this._vm.$http
      .post('/listings/open-house-showings-bookings/', payload)
      .then(({ data }) => data)
      .catch((error) => this._vm.$sentry.captureException(error))
  },
  cancelOpenHouseBooking({ commit }, booking = {}) {
    return this._vm.$http
      .patch(`listings/open-house-showings-bookings/${booking.id}`, { canceled: true })
      .catch((error) => this._vm.$sentry.captureException(error))
  },
}

const mutations = {
  ...mutationHelpers,
  setSelectedTours: (state, selection) => {
    state.selectedTours = selection
  },
  setAvailableTourTypes: (state, payload) => {
    state.availableTourTypes = payload
  },
  setMyTours: (state, payload) => {
    state.myTours = payload
  },
  setCurrentTour: (state, payload) => {
    state.currentTour = payload
  },
  setToursAvailability: (state, payload) => {
    state.toursAvailability = payload
  },
  setToursAvailabilityFetched: (state, payload) => {
    state.toursAvailabilityFetched = payload
  },
  setOpenHouseTimeslots: (state, payload) => {
    state.openHouseTimeslots = payload
  },
  setOpenHouseTimesFetched: (state, payload) => {
    state.openHouseTimesFetched = payload
  },
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
