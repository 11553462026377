import { camelCase } from 'lodash-es'
import supply from './supply'

const requireModule = require.context('.', false, /\.js$/)
const modules = {
  supply,
}

// Dynamically imports store modules:
requireModule.keys().forEach((fileName) => {
  if (fileName === './index.js') return
  const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ''))
  modules[moduleName] = {
    namespaced: true,
    ...requireModule(fileName).default,
  }
})

export default modules
