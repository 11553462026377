/* Common getters/mutations required for generic tasks in store modules */
import { get } from 'lodash-es'
const stateHelpers = {
  loading: false,
  updating: false,
  error: false,
}

const getterHelpers = {
  loading: (state) => state.loading,
  updating: (state) => state.updating,
  error: (state) => state.error,
  // Remember to use v-html, as this outputs <br/> tags if multiple errors exist.
  errorMessage: (state, getters) => {
    console.error(state.error)
    // Get all errors from API
    // ..Field Errors
    const fieldErrors = Object.values(getters.apiFieldErrors)
    // ..Non-Field Errors
    const nonFieldErrors = getters.apiNonFieldErrors
    // Join all errors
    const allErrors = [...fieldErrors, ...nonFieldErrors].filter((x) => !!x)
    const output = allErrors.join('<br/><br/>')

    const defaultErrorMessage =
      'An error has occurred, please contact our support team for assistance.'

    return output || get(state, 'error.response.statusText') || defaultErrorMessage
  },
  apiFieldErrors: (state) => get(state, `error.response.data.error.field_errors`, {}),
  apiFieldError: (state) => (field) =>
    get(state, `error.response.data.error.field_errors.${field}`),
  apiNonFieldErrors: (state) => get(state, 'error.response.data.error.non_field_errors', []),
}

const mutationHelpers = {
  setLoading: (state, status) => (state.loading = status),
  setUpdating: (state, status) => (state.updating = status),
  setError: (state, status) => (state.error = status),
}

export { stateHelpers, getterHelpers, mutationHelpers }
