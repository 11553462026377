import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const state = () => ({
  ...stateHelpers,
  document: {
    url: '',
  },
})

const getters = {
  ...getterHelpers,
  document: ({ document }) => document,
}

const actions = {
  async fetchDocument({ commit }, id) {
    commit('setLoading', true)
    commit('setError', false)

    try {
      const { data } = await this._vm.$http.get(apiPaths.resident.document(id))
      commit('setDocument', data)
    } catch (error) {
      commit('setError', error)
    } finally {
      commit('setLoading', false)
    }
  },
}

const mutations = {
  ...mutationHelpers,
  setDocument: (state, payload) => (state.document = payload),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
