<template>
  <div id="context-wrapper" @contextmenu="contextMenuTriggeredHandler">
    <slot />
    <div
      v-if="showMenu"
      ref="staffContextOverlayRef"
      class="click-overlay"
      @click.self.stop.prevent="closeMenuHandler"
    >
      <div :style="overlayStyle" class="context-overlay-active br-8 bg-white p-3 shadow-box-heavy">
        <b-btn
          v-for="(item, i) in options"
          :key="i"
          @click.self.stop.prevent="itemOnClickHandler(item.onClick)"
          variant="text"
          size="sm"
        >
          {{ item.title }}
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
/**
 * Utility component to override right click context menu for when the logged in user is a staff member
 *
 * Uses slots to wrap thew content in a right click override
 */
export default {
  name: 'StaffContextMenuOverride',
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showMenu: false,
      overlayStyle: {},
    }
  },
  computed: {
    ...mapGetters('user', ['activeUserIsStaff']),
    options() {
      return [
        {
          title: 'View in Fulcrum',
          onClick: this.viewInFulcrum,
        },
        {
          title: 'View API Response',
          onClick: this.viewApiResponse,
        },
      ]
    },
  },
  methods: {
    ...mapActions('property', ['fetchPropertySFID']),
    contextMenuTriggeredHandler(e) {
      if (!this.activeUserIsStaff) return
      e.preventDefault()
      const { clientX, clientY } = e
      this.overlayStyle = {
        top: `${clientY}px`,
        left: `${clientX}px`,
      }
      this.showMenu = true
      this.$nextTick(() => disableBodyScroll(this.$refs.staffContextOverlayRef))
    },
    itemOnClickHandler(event) {
      event?.()
      this.closeMenuHandler()
    },
    closeMenuHandler() {
      this.showMenu = false
      this.overlayStyle = {}
      this.$nextTick(clearAllBodyScrollLocks)
    },
    viewInFulcrum() {
      window.open(
        `${process.env.VUE_APP_ATLAS_URL}/fulcrum/properties/${this.property.id}`,
        '_blank'
      )
    },
    viewApiResponse() {
      window.open(
        `${process.env.VUE_APP_BACKEND_URL}listings/properties/${this.property.slug}`,
        '_blank'
      )
    },
  },
}
</script>

<style lang="scss">
#context-wrapper {
  height: 100%;

  .click-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    .context-overlay-active {
      opacity: 0;
      animation: fadeAndScrollUp 0.3s forwards;
      background: white;
      position: fixed;
      width: 250px;
      z-index: 10000000;
    }
  }
}
</style>
