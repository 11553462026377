import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  issueComments: {
    results: [],
    count: 0,
    nextUrl: '',
    previousUrl: '',
  },
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  issueComments: ({ issueComments: { results } }) => results,
  count: ({ issueComments: { count } }) => count,
  nextUrl: ({ issueComments: { next } }) => next,
  previousUrl: ({ issueComments: { previous } }) => previous,
}

const actions = {
  async fetchIssueComments({ commit }, query) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(apiPaths.maintenance.issueComments(query))
      .then(({ data }) => commit('setIssueComments', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  async fetchNextIssueComments({ commit, getters }) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(getters.nextUrl, { prefixUrl: '' })
      .then(({ data }) => commit('setIssueComments', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  async fetchPreviousIssueComments({ commit, getters }) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(getters.previousUrl, { prefixUrl: '' })
      .then(({ data }) => commit('setIssueComments', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },

  resetIssueComments({ commit }) {
    commit('resetState')
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setIssueComments(state, payload) {
    state.issueComments = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
