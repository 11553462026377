import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  supportForm: undefined,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  supportForm: ({ supportForm }) => supportForm,
}

const actions = {
  fetchSupportForm({ commit }, supportFormId) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(apiPaths.support.submissionForms(supportFormId))
      .then(({ data }) => commit('setSupportForm', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setSupportForm(state, payload) {
    state.supportForm = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
