import {
  annotizeProperty,
  propertyIsMatchForUserProfile,
  getBedroomPriceMatrix,
  getEarliestAvailabilityDate,
} from '@/utils/properties.js'
import { AMENITY_ICONS } from '@/constants/amenities.js'
import { HOUSE_MATCH_KEY } from '@/constants/property.js'
import { getAvailableLeaseLengths } from '@livebungalow/toolbox/abacus'
import apiPaths from '@/constants/apiPaths.js'

const state = {
  property: null,
  propertyError: false,
  similarListings: [],
  placeholderImage: 'https://assets.bungalow.com/images/property-placeholder.jpg',
}

const getters = {
  // using the responsive root getters, add universal _isMatch flag
  // key is only added to the property IF
  /**
   * using the responsive root getters, add universal _isMatch flag
   * key is only added to the property IF
   * - Property exists
   * - Property is not a group home
   * - Property has roommates
   * - Users profile is match ready ( age and gender pronoun complete)
   */
  property: (state, getters, rootState, rootGetters) => {
    const userProfile = rootGetters['user/userProfile']
    const userProfileMatchReady = rootGetters['user/userProfileMatchReady']
    const { property } = state
    return property
      ? {
          ...property,
          ...(!property._isGroupHome &&
            property.roommates.length &&
            userProfileMatchReady && {
              [HOUSE_MATCH_KEY]: propertyIsMatchForUserProfile(state.property, userProfile),
            }),
        }
      : null
  },
  propertyError: (state) => {
    return state.propertyError
  },
  similarListings: (state) => {
    return state.similarListings
  },
  placeholderImage: (state) => {
    return state.placeholderImage
  },
  getAmenityIconByDisplayName: (state) => (displayName) => {
    if (displayName.includes('TV')) return 'tv.svg'

    return AMENITY_ICONS[displayName] || 'elipses.svg'
  },

  availableLeaseLengths: () => {
    return getAvailableLeaseLengths()
  },

  earliestAvailabilityDate: ({ property }) => {
    return getEarliestAvailabilityDate(property)
  },

  propertyUnavailable: ({ property }) => {
    const { availableRoomCount, earliestAvailableDate } = property

    return !availableRoomCount || !earliestAvailableDate
  },

  availableRooms({ property }, { availableLeaseLengths }, rootState, rootGetters) {
    return property.availableRooms.map((room) =>
      getBedroomPriceMatrix({
        room,
        property,
        availableLeaseLengths,
        pricingConstants: rootGetters['constants/allPricingConstants'],
      })
    )
  },
}

const actions = {
  fetchProperty({ commit, rootGetters, dispatch }, propertyIdOrSlug) {
    // Reset property data:
    commit('setProperty', null)
    commit('setPropertyError', false)

    return this._vm.$http
      .get(apiPaths.listings.detail(propertyIdOrSlug))
      .then(({ data }) => {
        // Enrich and update property data:
        const annotizedProperty = annotizeProperty(
          data,
          rootGetters['constants/allPricingConstants']
        )
        commit('setProperty', annotizedProperty)
        // save the market and home type to the persistent nav
        dispatch('nav/saveMarketSlug', annotizedProperty.market.slug, { root: true })
        dispatch('nav/saveHomeType', annotizedProperty.propertyMarketingType, {
          root: true,
        })
        // Return the fetched property for dynamic use cases:
        return annotizedProperty
      })
      .catch((error) => {
        const errorResponse = error.response || error
        commit('setPropertyError', errorResponse)
      })
  },
  fetchSimilarListings({ commit, rootGetters }, propertySlug) {
    commit('setSimilarListings', [])

    return this._vm.$http
      .get(`listings/similar_properties/${propertySlug}`)
      .then(({ data }) => {
        const annotizedProperties = data.results
          .map((property) =>
            annotizeProperty(property, rootGetters['constants/allPricingConstants'])
          )
          .filter((property) => !!property.availableRoomCount)
        commit('setSimilarListings', annotizedProperties)
      })
      .catch((error) => this._vm.$sentry.captureException(error))
  },
  fetchPropertySFID(store, propertySlug) {
    return this._vm.$http
      .get(`/listings/properties/${propertySlug}`, {
        searchParams: { sfid: true },
      })
      .then(({ data }) => data.sfid)
  },
}

const mutations = {
  setProperty(state, payload) {
    state.property = payload
  },
  setSimilarListings(state, payload) {
    state.similarListings = payload
  },
  setPropertyError(state, payload) {
    state.propertyError = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
