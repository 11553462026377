import { stateHelpers, getterHelpers, mutationHelpers } from '../helpers'
import apiPaths from '@/constants/apiPaths'

const getDefaultState = () => ({
  ...stateHelpers,
  applicationSource: null,
})

const state = getDefaultState()

const getters = {
  ...getterHelpers,
  applicationSource: ({ applicationSource }) => applicationSource,
}

const actions = {
  fetchApplicationSourceBySlug({ commit }, sourceSlug) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .get(apiPaths.applications.preview + sourceSlug)
      .then(({ data }) => commit('setApplicationSource', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
  createApplicationSource({ commit }, payload) {
    commit('setLoading', true)
    commit('setError', false)

    return this._vm.$http
      .post(apiPaths.applications.source, payload)
      .then(({ data }) => commit('setApplicationSource', data))
      .catch((error) => commit('setError', error))
      .finally(() => commit('setLoading', false))
  },
}

const mutations = {
  ...mutationHelpers,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setApplicationSource(state, payload) {
    state.applicationSource = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
