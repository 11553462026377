export const ROUTE_NAMES = {
  signIn: 'residentSignIn',
  // Billing
  billing: 'billing',
  initialPayment: 'firstTimePayment',
  autoPay: 'residentAutoPayPrompt',
  addBankAccountIntro: 'residentAddBankAccountIntro',
  addBankAccount: 'residentAddBankAccount',
  verifyBankAccount: 'residentVerifyBankAccount',
  addCreditAccount: 'residentAddCreditAccount',
  managePaymentMethods: 'residentManagePaymentMethods',
  confirmMicroDeposits: 'residentConfirmMicroDeposits',
  documents: 'residentDocuments',
  viewPastLeases: 'residentDocumentsPast',
  notPrimaryResident: 'notPrimaryResident',
  // Support
  supportIssueList: 'supportIssueList',
  supportIssueComments: 'supportIssueComments',
  supportIssueDetails: 'supportIssueDetails',
  supportIssueCreate: 'supportIssueCreate',
  supportClassificationTree: 'supportClassificationTree',
  supportMaintenance: 'supportMaintenance',
  // Renter's Insurance
  rentersInsurance: 'rentersInsurance',
}

export default [
  {
    path: '/resident/billing',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "payments" */ './containers/ResidentBillingContainer.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAMES.billing,
        component: () =>
          import(/* webpackChunkName: "payments" */ './views/Payments/ResidentBilling.vue'),
        meta: { routeClasses: 'cream--bg' },
      },
      {
        path: 'not-primary-resident-warning',
        name: ROUTE_NAMES.notPrimaryResident,
        component: () =>
          import(/* webpackChunkName: "payments" */ './views/Payments/ResidentNotPrimary.vue'),
        meta: { routeClasses: 'cream--bg' },
      },
      {
        // Pages within this container will be given a FullScreenModal header
        // Pass backPath and closePath params for extra control
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "payments" */ './containers/ResidentFullScreenModalContainer.vue'
          ),
        props: true,
        children: [
          {
            path: 'auto-pay-prompt',
            name: ROUTE_NAMES.autoPay,
            component: () =>
              import(
                /* webpackChunkName: "payments" */ './views/Payments/ResidentAutoPayPrompt.vue'
              ),
            meta: {
              hideNav: true,
              hideFooter: true,
            },
          },
          {
            path: 'manage-payment-methods',
            name: ROUTE_NAMES.managePaymentMethods,
            component: () =>
              import(
                /* webpackChunkName: "payments" */ './views/Payments/ResidentManagePaymentMethods.vue'
              ),
            meta: {
              hideNav: true,
              hideFooter: true,
            },
          },
          {
            path: 'add-bank-account-intro',
            name: ROUTE_NAMES.addBankAccountIntro,
            component: () =>
              import(
                /* webpackChunkName: "payments" */ './views/Payments/ResidentAddBankAccountIntro.vue'
              ),
            meta: {
              hideNav: true,
              hideFooter: true,
              routeClasses: 'cream--bg',
            },
          },
          {
            path: 'add-bank-account',
            name: ROUTE_NAMES.addBankAccount,
            component: () =>
              import(
                /* webpackChunkName: "payments" */ './views/Payments/ResidentAddBankAccount.vue'
              ),
            meta: {
              hideNav: true,
              hideFooter: true,
              routeClasses: 'cream--bg',
            },
          },
          {
            path: 'verify-bank-account',
            name: ROUTE_NAMES.verifyBankAccount,
            component: () =>
              import(
                /* webpackChunkName: "payments" */ './views/Payments/ResidentVerifyPaymentMethod.vue'
              ),
            meta: {
              hideNav: true,
              hideFooter: true,
              routeClasses: 'cream--bg',
            },
          },
          {
            path: 'confirm-micro-deposits',
            name: ROUTE_NAMES.confirmMicroDeposits,
            component: () =>
              import(
                /* webpackChunkName: "payments" */ './views/Payments/ResidentConfirmMicroDeposits.vue'
              ),
            meta: {
              hideNav: true,
              hideFooter: true,
            },
          },
          {
            path: 'add-credit-card',
            name: ROUTE_NAMES.addCreditAccount,
            component: () =>
              import(
                /* webpackChunkName: "payments" */ './views/Payments/ResidentAddCreditAccount.vue'
              ),
            meta: {
              hideNav: true,
              hideFooter: true,
              routeClasses: 'cream--bg',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/resident/billing',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "documents" */ './containers/ResidentBillingContainer.vue'),
    children: [
      {
        path: 'documents',
        name: ROUTE_NAMES.documents,
        component: () =>
          import(/* webpackChunkName: "documents" */ './views/Documents/ResidentDocuments.vue'),
        meta: { routeClasses: 'cream--bg' },
      },
      {
        path: 'view-past-leases',
        name: ROUTE_NAMES.viewPastLeases,
        component: () =>
          import(/* webpackChunkName: "documents" */ './views/Documents/ResidentDocumentsPast.vue'),
        meta: {
          hideNav: true,
          hideFooter: true,
          routeClasses: 'cream--bg',
        },
      },
    ],
  },
  {
    path: '/resident/support',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "support" */ './containers/ResidentSupportContainer.vue'),
    children: [
      {
        path: '',
        name: ROUTE_NAMES.supportIssueList,
        component: () =>
          import(/* webpackChunkName: "support" */ './views/Support/ResidentSupportIssueList.vue'),
        meta: { hideFooter: true, routeClasses: 'white--bg' },
        children: [
          {
            path: 'issue/:issueId',
            name: ROUTE_NAMES.supportIssueComments,
            component: () =>
              import(
                /* webpackChunkName: "support" */ './views/Support/ResidentSupportIssueListItem.vue'
              ),
            meta: { hideFooter: true, routeClasses: 'white--bg' },
          },
          {
            path: 'issue/:issueId/details',
            name: ROUTE_NAMES.supportIssueDetails,
            component: () =>
              import(
                /* webpackChunkName: "support" */ './views/Support/ResidentSupportIssueListItemDetails.vue'
              ),
            meta: { hideFooter: true, routeClasses: 'white--bg' },
          },
          {
            path: 'classification',
            name: ROUTE_NAMES.supportClassificationTree,
            component: () =>
              import(
                /* webpackChunkName: "support" */ './views/Support/ResidentSupportClassificationRoot.vue'
              ),
            meta: { hideFooter: true, routeClasses: 'white--bg' },
          },
          {
            path: 'classification/maintenance',
            name: ROUTE_NAMES.supportMaintenance,
            component: () =>
              import(
                /* webpackChunkName: "support" */ './views/Support/ResidentSupportMaintenance.vue'
              ),
            meta: { hideFooter: true, routeClasses: 'white--bg' },
          },
          {
            path: 'classification/:classificationId',
            name: ROUTE_NAMES.supportIssueCreate,
            component: () =>
              import(
                /* webpackChunkName: "support" */ './views/Support/ResidentSupportClassificationNode.vue'
              ),
            meta: { hideFooter: true, routeClasses: 'white--bg' },
          },
        ],
      },
    ],
  },
  {
    path: '/resident/insurance',
    meta: {
      requiresAuth: true,
      routeClasses: 'cream--bg',
    },
    component: () =>
      import(
        /* webpackChunkName: "rentersInsurance" */ './containers/ResidentRentersInsuranceContainer.vue'
      ),
    children: [
      {
        path: '',
        name: ROUTE_NAMES.rentersInsurance,
        component: () =>
          import(
            /* webpackChunkName: "rentersInsurance" */ './views/RentersInsurance/ResidentRentersInsuranceAppfolio.vue'
          ),
        meta: { routeClasses: 'cream--bg' },
      },
    ],
  },
]
