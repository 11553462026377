<template>
  <b-container fluid :class="`site-header-container ${creamNav}`">
    <b-row class="site-header d-flex w-100">
      <b-navbar type="light" class="w-100 px-4">
        <b-navbar-nav>
          <b-navbar-brand class="mr-3 pl-3" to="/">
            <img src="https://assets.bungalow.com/logos/bungalow_logo_dark.svg" alt="Bungalow" />
          </b-navbar-brand>
          <appear v-if="marketSelectorVisible && !isFocusedRoute">
            <MarketSelector
              class="ml-3"
              :location="marketSelectorLocations.HEADER"
              :data-cy="`${marketSelectorLocations.HEADER}__market-selector`"
            >
              <template slot="market-selector-search-btn" slot-scope="MarketSelector">
                <b-btn
                  variant="dark"
                  class="market-selector--search-cta d-flex align-items-center justify-content-center"
                  @click.stop="MarketSelector.viewFilteredListing"
                  :data-cy="`${marketSelectorLocations.HEADER}__market-selector-cta`"
                >
                  Search
                </b-btn>
              </template>
            </MarketSelector>
          </appear>
        </b-navbar-nav>
        <!-- Right Aligned -->
        <b-navbar-nav class="ml-auto d-flex align-items-center justify-content-end pr-2" right>
          <!-- Page Links -->
          <li v-if="!isFocusedRoute">
            <b-dropdown
              ref="renterDropdownRef"
              size="lg"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <span class="font-weight-bold"> Renters </span>
                <div style="width: 10px" class="ml-3">
                  <inline-svg
                    style="height: 10px; width: 10px"
                    :src="require('@/assets/icons/icon-caret-down.svg')"
                  />
                </div>
              </template>
              <b-dropdown-item class="mt-2 font-weight-bold" href="/renters-rooms">
                <span class="font-weight-bold"> Rooms </span>
              </b-dropdown-item>
              <b-dropdown-item class="my-2 font-weight-bold" href="/renters-homes">
                <span class="font-weight-bold"> Entire Homes </span>
              </b-dropdown-item>
            </b-dropdown>
          </li>
          <li v-for="link in rightNavLinks" :key="link.title">
            <router-link v-if="!isFocusedRoute" :to="link.to" class="p-3 br-4">
              {{ link.title }}
            </router-link>
          </li>
          <li class="d-flex align-items-center float-right nav-item pr-0">
            <VerifiedBadge :showBadge="isVettedApplicant">
              <HeaderBadge :showWelcomeMessage="isFocusedRoute" />
            </VerifiedBadge>
          </li>
        </b-navbar-nav>
      </b-navbar>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import HeaderBadge from '@/components/Auth/HeaderBadge.vue'
import VerifiedBadge from '@/components/Shared/VerifiedBadge.vue'
import MarketSelector from '@/components/Navigation/MarketSelector.vue'
import { marketSelectorLocations, focusedRoutes } from '@/constants/nav.js'

export default {
  name: 'HeaderDesktop',
  components: {
    HeaderBadge,
    VerifiedBadge,
    MarketSelector,
  },
  data() {
    return {
      marketSelectorVisible: false,
      rightNavLinks: [
        {
          title: 'Homeowners',
          to: '/homeowners',
        },
        {
          title: 'Investors',
          to: '/institutional-investor',
        },
        {
          title: 'About',
          to: '/about',
        },
      ],
      marketSelectorLocations,
    }
  },
  created() {
    // Listen for changes in the market selector visibility...
    this.$root.$on('toggleMarketSelectorHeaderVisibility', (visible) => {
      this.marketSelectorVisible = visible
    })
  },
  mounted() {
    if (window.location.pathname === '/') return
    if (!this.isFocusedRoute) this.$root.$emit('toggleMarketSelectorHeaderVisibility', true)
  },
  computed: {
    ...mapGetters('user', [
      'activeUser',
      'isLoggedIn',
      'activeUserIsHomeowner',
      'isVettedApplicant',
    ]),
    isFocusedRoute() {
      return this.$route.matched.some(({ name }) => focusedRoutes.includes(name))
    },
    creamNav() {
      return this.$route.meta.creamNav ? 'cream' : ''
    },
  },
}
</script>

<style lang="scss">
.site-header-container,
.site-header-container::before,
.site-header-container * {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@include desktop {
  body.body-scrolled {
    // TODO: Fix this shit...
    .site-header-container::before {
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
    }
  }
}

.site-header-container {
  position: fixed;
  top: 0;
  z-index: 1000;
  height: $default-navbar-height;
  display: flex;
  align-items: center;
  background: $white;

  .market-selector-cta {
    min-width: 200px;
  }
}

.site-header-container {
  &::before {
    transition: transform 0.25s ease;
    transform-origin: top;
    background: white;
    height: $default-navbar-height;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &.cream::before {
    background: $cream;
  }
}

.site-header {
  transition: transform 0.15s ease;
  flex: 1;
  .main-nav .nav-item {
    white-space: nowrap;
  }
  .navbar {
    font-family: $font-family-sans-serif-bold;
    font-weight: 700;
    box-shadow: none;
    .navbar-brand {
      display: flex;
      align-items: center;
      img {
        width: 160px;
      }
    }
    li button.dropdown-toggle {
      height: 47px;
      display: flex;
      align-items: center;
      line-height: 25.5px;
    }

    li a,
    li button.dropdown-toggle {
      transition: background 0.25s ease;
      &:hover,
      &:focus {
        background: $charcoal-5;
      }
    }
    li.active span,
    .nav-item a.active-market {
      color: $primary;
    }
    .nav-item,
    .dropdown-item,
    li button.dropdown-toggle {
      color: $charcoal-80;
      a,
      .nav-link,
      .nav-link {
        color: $charcoal-80;
        background: transparent;
        &:hover {
          cursor: pointer;
        }
        &.router-link-active {
          color: $salmon-60;
        }
        &:hover {
          color: $salmon-60;
        }
      }
      .dropdown-toggle::after {
        display: none;
      }
    }
    .nav-link {
      // margin-left: 18px;
    }
    .dropdown-menu {
      @include card;
    }
  }
}
</style>
